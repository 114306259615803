import { StatusEvents } from "./utils/status_events";
import { DataReference } from "./utils/data_reference";

export class AvailableBalanceInquiry {
    public countryIdentity: string = ""
    public phone: string = ""
    public userId: string = "";
    public available_balance_key: string = ""
    public document_number: string = ""
    public status: string = "";
    public status_events = Array<StatusEvents>;
    public webhook_type: string = "";
    public event_datetime: string = "";
    public key: string = "";
    public data = DataReference;
  }
  