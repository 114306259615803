import { ChangeDetectorRef, Component,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core'
import { AppSetting } from '../../entities/utils/appsettings';
import { SimulationModuleService } from 'src/app/services/simulation-module.service';
import { SnackBarMessageService } from 'src/app/services/snack-bar.service';
import { BanksList } from 'src/app/services/banksList.service';

@Component({
  selector: 'app-change-date-representation',
  templateUrl: './change-date-representation.component.html',
  styleUrls: ['./change-date-representation.component.css']
})
export class ChangeDateRepresentationComponent {
  constructor (
    private cdr: ChangeDetectorRef,
    private dateAdapter: DateAdapter<any>,
    public snackBarMessageService: SnackBarMessageService,
    public simulation_service: SimulationModuleService,
    public dialogRef: MatDialogRef<ChangeDateRepresentationComponent>,
    public bankList: BanksList,
    @Inject(MAT_DIALOG_DATA) public data: any,
   ) {
     this.dateAdapter.setLocale('pt-BR')
     console.log(this.data)
     }

    public appSetting = new AppSetting()

    bancos = this.bankList.bancos
    methodPayment: string = "TED"
    
    public bankCode = ""
    public branchNumber = ""
    public accountNumber = ""
    public accountDigit = ""
    
    public pixTypeKey = ""
    public pixKey = ""

    maskInput: null | string = ""

    MetodsPaymentPix = [
    { type: "CPF", mask: "000.000.000-00" },
    { type: "Email" },
    { type: "Celular", mask: "(00) 00000-0000" },
    { type: "Chave Aleatória" }
    ];

    ngOnInit() {
      console.log(this.data)
    }

    confirm() {
    let _body = {}

    if(this.methodPayment == 'TED') {
      _body = {
        "key": this.data.key,
        "bankCode": this.bankCode,
        "branchNumber": this.branchNumber,
        "accountNumber": this.accountNumber,
        "accountDigit": this.accountDigit
      }
    } else {
      _body = {
        "key": this.data.key,
        "pixTransferType": "key",
        "pixKey": this.pixKey 
      }
    }

    this.simulation_service.DisbursementBankAccount(_body).subscribe((scc) => {
      console.log(scc)
      this.snackBarMessageService.success("Dados Bancarios Alterados com sucesso !")
      this.dialogRef.close();
    },(err) => {
      console.log(err)
      this.snackBarMessageService.error("Erro na atualização dos Dados Bancarios")
    })
  }

  updateMask(selectedMethod: any) {
    this.pixTypeKey = selectedMethod?.type 
    console.log(this.pixKey);

    switch (selectedMethod?.type) {
      case 'CPF':
        this.pixKey = this.formatCPF(this.data.borrower.individual_document_number);
        this.maskInput = "000.000.000-00"
        break;
      case 'Celular':
        this.pixKey = this.formatarTelefone(`${this.data.borrower.phone.area_code}`, `${this.data.borrower.phone.number}`);
        this.maskInput = "(00) 00000-0000"     
        break;
      case 'Email':
        this.maskInput = null
        this.pixKey = '';
        break;
      case 'Chave Aleatória':
        this.maskInput = null
        this.pixKey = ""
        break;
    }
    this.cdr.detectChanges()
    console.log(this.pixKey);
  }

  formatarTelefone(areaCode, numero) {
    const numeroLimpo = numero.replace(/\D/g, '');
    const temNoveDigitos = numeroLimpo.length === 9;
    const codigoArea = `(${areaCode}) `;
    const parte1 = temNoveDigitos ? numeroLimpo.slice(0, 5) : numeroLimpo.slice(0, 4);
    const parte2 = temNoveDigitos ? numeroLimpo.slice(5) : numeroLimpo.slice(4);

    const telefoneFormatado = `${codigoArea}${parte1}-${parte2}`;

    return telefoneFormatado;
  }

  formatCPF(cpf: any) {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return cpf;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  }
