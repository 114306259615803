import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FgtsSimulationComponent } from '../fgts-simulation/fgts-simulation.component';
import { RegisterComponent } from '../register/register.component';
import { AppSetting } from '../entities/utils/appsettings';
import { SnackBarMessageService } from '../services/snack-bar.service';
import { CorService } from '../services/color.service';
import { ArchiveComponentComponent } from '../components/archive-component/archive-component.component';
import {Clipboard} from '@angular/cdk/clipboard';
import { SimulationModuleService } from '../services/simulation-module.service';
import { ProposalsList } from '../services/proposalsList.service';
import { ChangeStatusFilaComponent } from './change-status-fila/change-status-fila.component';
import { Format } from '../services/format.service';


@Component({
  selector: 'app-fila',
  templateUrl: './fila.component.html',
  styleUrls: ['./fila.component.css'],
  providers: [
    SnackBarMessageService
  ]
})

export class FilaComponent {
   
  @Input() _data: any = null
  @Input() typecode: any = null
  @Input()  pf: any = null
  public setStepIndex: number = 0
  constructor( 
    public format: Format,
    public dialog: MatDialog,
    public snackBarMessageService: SnackBarMessageService,
    public simulation_service: SimulationModuleService,
    private clipboard: Clipboard,
    private corService: CorService,
    ){}
    
  public appSetting = new AppSetting()
  
  colorTicket: string = ""
  iduser: any = localStorage.getItem("userId")
  objet: any = [];
  isCopying: boolean = false;
  mainFontColor = this.corService.mainFontColor
  document_number: string = ""
  spinner = true;
  public phone : any
  public phoneFormatted: any
  public phone_true: any

  ngOnInit(): void {
    if(this._data.phone != null) {
      this.phone_true = true
      this.phone = `${(this._data.phone.area_code)}${this._data.phone.number}`
      this.phoneFormatted = this.format.formatPhoneWithOneParameter(this.phone)
    }else{
      this.phone_true = false
      this.phone = null
    }

    this.colorTicketForStatus(this._data.statusFront)
    this.document_number = this.format.formatCPF(this._data.documentNumber)
    this.objet.push(this._data)

    this.spinner = false;
  } 

  openChangeStatusFila(key: string) {
    let body = {
      key: key,
      typecode: this.typecode
    }
    const dialogRef = this.dialog.open(ChangeStatusFilaComponent, {
      width: '700px',
      height: '240px',
      data: body
    });
  }

  openWhatsAppDirect() {
    const mensagem = `Ótima notícia! Você tem R$ ${this.format.formatValue(this._data.valueSimulation)} disponíveis para saque.\n\nA liberação é super rápida e acontece em apenas alguns minutos.\n\n*Gostaria de prosseguir com a contratação?*`;
    const url = `https://wa.me/${this.phone}/?text=${encodeURIComponent(mensagem)}`;
    console.log(url);
    window.open(url);
  }
  
  colorTicketForStatus(status: string) {
    switch (status) {
      case 'Consultando':
        this.colorTicket = "#C2B24A"
        break;
      
      case 'Saldo Disponível':
        this.colorTicket = '#43C466'
        break;
    
      case 'Simulação Enviada':
        this.colorTicket = '#00B78F'
        break;
      
      case 'Em Negociação':
        this.colorTicket = '#0090C8'
        break;

      case 'Contrato Fechado':
        this.colorTicket = '#2D5CB0'
        break;

      case 'Contrato Pago':
        this.colorTicket = '#2D5CB0'
        break;

      case 'Sem Retorno':
        this.colorTicket = '#C41F1F'
        break;

      case 'Sem Interesse':
        this.colorTicket = 'gray'
        break;

      case 'Processando Consulta':
        this.colorTicket = '#C2B24A'
        break;
      
        default:
        break;
    }
  }

  copyValue(value: any) {
    try{
      this.isCopying = true;
      this.clipboard.copy(value);
      console.log(value)
      this.isCopying = false
      this.snackBarMessageService.success("Valor copiado com sucesso !")
    }catch(e){
      this.isCopying = false
      this.snackBarMessageService.success("Erro ao copiar o valor!")
      console.log(e)
    }
  }

  copyScriptValueSimulation(value) {
    if(this.pf == true) {
        this.isCopying = true;
        this.clipboard.copy(`Ótima notícia! Você tem ${value} disponíveis para saque. 
        
A liberação é super rápida e acontece em apenas alguns minutos.

*Gostaria de prosseguir com a contratação?* 🚀`);
        console.log(value)
        this.isCopying = false
        this.snackBarMessageService.success("Valor copiado com sucesso !")
      }
    if(this.pf == false) {
        this.isCopying = true;
        this.clipboard.copy(value);
        console.log(value)
        this.isCopying = false
        this.snackBarMessageService.success("Valor copiado com sucesso !")
    }
  }

   openMenu(): void {
    let body = {
      "key": this._data.key,
      "typecode": this.typecode,
      "document_number": this._data.documentNumber,
      "status_events":  this._data.documentNumber ,
      "status":  this._data.status,
      "data": this._data
    };
    const dialogRef = this.dialog.open(ArchiveComponentComponent, {
      width: '50%',
      height: '35%',
      data: body
    });
  }

  openContractSimulation() {
    if(this._data.statusFront == "Contrato Pago") {
      return
    }
    
    if(this._data.status == "pending" || this._data.status == null){
      this.snackBarMessageService.error("Contrato Em Analise, Por Favor Aguarde!")
    }else {
        let body = {
          "key": "",
          "document_number": this._data.documentNumber,
          "status_events":  this._data.documentNumber ,
          "status":  this._data.status,
          "data": this._data
        };
        const dialogRef = this.dialog.open(RegisterComponent, {
          width: '100%',
          height: '85%',
          data: body
        });
      
        // dialogRef.componentInstance.setStepIndex = this.setStepIndex;
        
        // dialogRef.backdropClick().subscribe(scc => {
        //   console.log(dialogRef.componentInstance.stepper.selectedIndex)
        //   this.setStepIndex = dialogRef.componentInstance.stepper.selectedIndex

        // })

        // console.log(dialogRef)
    }
  }

}
