import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PersonDocument {
    public typeCode: number;
    public number: string;
    public category: string;
    public issuingDate: any;
    public expirationDate: string;
    public issuingEntity: string;
    public issuingState: string;
    public issuingCountry: string;
    public securityCode: string;
    public statusCode: number;
    public statusDate: any;
    public files: number;
  
    constructor() {
      this.typeCode = 1;
      this.number = '';
      this.category = '';
      this.issuingDate = '';
      this.expirationDate = '';
      this.issuingEntity = '';
      this.issuingState = '';
      this.issuingCountry = '';
      this.securityCode = '';
      this.statusCode = 0;
      this.files = 0;
    }
  
  }
  