<div class="container">
<mat-form-field floatLabel="always" style="width: 100%;">
    <mat-label>Tipo Documento</mat-label>
    <mat-select name="typeCode" required id="typeCode" [(ngModel)]="documentType" (selectionChange)="setType()">
      <mat-option [value]="-1"></mat-option>
      <mat-option *ngFor="let item of _documentList.document" [value]="item.code" >
        {{item.display}}
      </mat-option>
    </mat-select>
</mat-form-field>

<div *ngIf="documentType" class="containerFgtsConsult">
    <div class="example-container">
      <mat-form-field floatLabel="always"
        *ngIf="!hideNumber">
        <mat-label>{{appSetting.LG_PTBR.number}}</mat-label>
        <input matInput type="text" [(ngModel)]="personDocument.number" name="number">
      </mat-form-field>

      <mat-form-field floatLabel="always"
        *ngIf="!hideCategory" >
        <mat-label>{{appSetting.LG_PTBR.category}}</mat-label>
        <input matInput type="text" [(ngModel)]="personDocument.category" name="category" >
      </mat-form-field>

      <mat-form-field floatLabel="always"
        *ngIf="!hideIssuingDate ">
        <mat-label>{{appSetting.LG_PTBR.issuingDate}}</mat-label>
        
        <input matInput type="text" mask="d0/M0/0000"
            [(ngModel)]="personDocument.issuingDate" name="issuingDate"
          placeholder="dd/mm/aaaa">
      </mat-form-field>

      <mat-form-field floatLabel="always"
        *ngIf="!hideExpirationDate">
        <mat-label>{{appSetting.LG_PTBR.expirationDate}}</mat-label>
        <input matInput type="text" mask="d0/M0/0000" 
          [(ngModel)]="personDocument.expirationDate" name="expirationDate"
          placeholder="dd/mm/aaaa">
      </mat-form-field>

      <mat-form-field floatLabel="always"
        *ngIf="!hideIssuingEntity">
        <mat-label>{{appSetting.LG_PTBR.issuingEntity}}</mat-label>
        <input matInput type="text"
          [(ngModel)]="personDocument.issuingEntity" name="issuingEntity" >
      </mat-form-field>

      <mat-form-field floatLabel="always"
        *ngIf="!hideIssuingState">
        <mat-label>{{appSetting.LG_PTBR.issuingState}}</mat-label>
        <input matInput type="text"
          [(ngModel)]="personDocument.issuingState" name="issuingState" >
      </mat-form-field>

      <mat-form-field floatLabel="always"
        *ngIf="!hideIssuingCountry">
        <mat-label>{{appSetting.LG_PTBR.issuingCountry}}</mat-label>
        <input matInput type="text" [(ngModel)]="personDocument.issuingCountry" name="issuingCountry"
          >
      </mat-form-field>

      <mat-form-field floatLabel="always"
        *ngIf="!hideSecurityCode">
        <mat-label>{{appSetting.LG_PTBR.securityCode}}</mat-label>
        <input matInput type="text" [(ngModel)]="personDocument.securityCode" name="securityCode">
      </mat-form-field>
    </div>
  </div>   
</div>
