import { Component } from '@angular/core';
import { AppSetting } from 'src/app/entities/utils/appsettings';
import { PersonDocument } from 'src/app/entities/utils/person-Document';
import { DocumentTypeList } from 'src/app/entities/document_type';
import { Borrower } from 'src/app/entities/utils/borrower';

@Component({
  selector: 'app-document-type-input',
  templateUrl: './document-type-input.component.html',
  styleUrls: ['./document-type-input.component.css']
})

export class DocumentTypeInputComponent {
  
public personDocument = new PersonDocument();
public appSetting = new AppSetting();
public hideNumber: any = "";
public hideCategory: any = "";
public hideIssuingDate: any = "";
public hideExpirationDate: any = "";
public hideIssuingEntity: any = "";
public hideIssuingState: any = "";
public hideIssuingCountry: any = "";
public hideSecurityCode: any = "";

_documentList = new DocumentTypeList();
_Borrower = new Borrower();

public documentType: any = 0

ngOnInit(){
  console.log(this.documentType)
}

setType(): void {
  console.log(this.documentType)

  this.hideNumber = true;
  this.hideCategory = true;
  this.hideIssuingDate = true;
  this.hideExpirationDate = true;
  this.hideIssuingEntity = true;
  this.hideIssuingState = true;
  this.hideIssuingCountry = true;
  this.hideSecurityCode = true;

  if (this.documentType == 1) {
    this.hideNumber = false;
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
    this.hideIssuingState = false;
  }
  else if (this.documentType == 2) {
    this.hideNumber = false;
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
    this.hideIssuingState = false;
    this.hideIssuingCountry = false;
  }
  else if (this.documentType == 3) {
    this.hideNumber = false;
    this.hideCategory = false;
    this.hideIssuingDate = false;
    this.hideExpirationDate = false;
    this.hideIssuingEntity = false;
    this.hideIssuingState = false;
    this.hideIssuingCountry = false;
  }
  else if (this.documentType == 4 ) {
    this.hideNumber = false;
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
    this.hideIssuingState = false;
  }
  else if (this.documentType == 5 ) {
    this.hideNumber = false;
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
  }
  else if (this.documentType == 6 ) {
    this.hideIssuingDate = false;
  }
  else if (this.documentType == 7 ) {
    this.hideNumber = false;
    this.hideCategory = false;
    this.hideIssuingDate = false;
    this.hideExpirationDate = false;
    this.hideIssuingEntity = false;
    this.hideIssuingState = false;
    this.hideIssuingCountry = false;
    this.hideSecurityCode = false;
  }
  else if (this.documentType == 8 ) {
    this.hideNumber = false;
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
    this.hideIssuingState = false;
  }
  else if (this.documentType == 9 ) {
    this.hideNumber = false;
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
    this.hideIssuingState = false;
    this.hideIssuingCountry = false;
  }
  else if (this.documentType == 10) {
    this.hideNumber = false;
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
    this.hideIssuingState = false;
    this.hideIssuingCountry = false;
  }
  else if (this.documentType == 11) {
    this.hideNumber = false;
    this.hideCategory = false;
    this.hideIssuingDate = false;
    this.hideExpirationDate = false;
    this.hideIssuingEntity = false;
    this.hideIssuingState = false;
    this.hideIssuingCountry = false;
  }
  else if (this.documentType == 12) {
    this.hideNumber = false;
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
    this.hideIssuingState = false;
    this.hideIssuingCountry = false;
  }
  else if (this.documentType == 13) {
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
  }
  else if (this.documentType == 14) {
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
  }
  else if (this.documentType == 15) {
    this.hideIssuingDate = false;
    this.hideIssuingEntity = false;
  }
}

}
