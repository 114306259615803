import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppSetting } from 'src/app/entities/utils/appsettings';
import { SimulationModuleService } from 'src/app/services/simulation-module.service';
import { CorService } from '../../services/color.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { SnackBarMessageService } from 'src/app/services/snack-bar.service';
import { BanksList } from 'src/app/services/banksList.service';
import { Format } from 'src/app/services/format.service';
@Component({ 
  selector: 'app-monitoring-resume',
  templateUrl: './monitoring-resume.component.html',
  styleUrls: ['./monitoring-resume.component.css'],
  providers: [
    SnackBarMessageService
  ]
})
export class MonitoringResumeComponent {
  
  constructor(
    public format: Format,
    private clipboard: Clipboard,
    private corService: CorService,
    public simulationsvc: SimulationModuleService,
    public snackBarMessageService: SnackBarMessageService,
    public dialogRef: MatDialogRef<MonitoringResumeComponent>,
    public simulation_service: SimulationModuleService,
    public bankList: BanksList,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      this.colorPrimary = this.colorPrimary === '' ? '#4ECD72' : this.colorPrimary;
    }
    public appSetting = new AppSetting()
  colorPrimary: string = this.corService.colorPrimary 
  mainFontColor: string = this.corService.mainFontColor 
  dadosUser = this.data
  msgError: string = this.dadosUser.data.error_description_pt
  amountToReceive: any
  installments: string = ''
  principalAmortizationAmountSum= 0
  releasedValue= 0
  preFixedAmountSum= 0
  dataTransform: any
  telefone: string = this.dadosUser.borrower.phone.area_code + " " + this.format.formatPhoneWithOneParameter(this.dadosUser.borrower.phone.number)
  niverFormatted = this.format.formatAmericanDateForDatePTBR(this.dadosUser.borrower.birth_date)
  dateFormattedIssue: string = this.format.formatAmericanDateForDatePTBR(this.dadosUser.financial.issue_date)
  cpfFormatted: string = this.format.formatCPF(this.dadosUser.borrower.individual_document_number)
  contractOn: boolean = true
  amountToRecive = ""
  assineOn = this.dadosUser.status == "waiting_signature"
  pf: any
  iduser = localStorage.getItem("userId")
  dataTable = this.dadosUser.installments
  ispb = this.dadosUser.disbursement_bank_account.ispb_number
  bankInfos: any
  
  ngOnInit() {
    this.simulation_service.getUser(this.iduser).subscribe( (result: any) => {
      this.pf = result.salesPF
    })
    
    this.simulationsvc.GetAvaibleBalance(this.dadosUser.user_id,this.dadosUser.key).subscribe( (scc: any) => {
      this.amountToReceive = scc.amountToReceive
      this.installments = scc.installments.length
    this.findAllvalue(this.installments)
    })
    if(this.dadosUser.status == "canceled_permanently" || this.dadosUser.status == "canceled") {
      this.contractOn = false
    }
    this.amountToRecive = this.format.formatValue(this.dadosUser.amount_to_receive)
    this.bankInfos = this.bankList.bancos.find(banco => {
     return this.ispb === banco.bankIspb
    })
  }

  openContract() {
    if (this.dadosUser.data_result.contract.urls[0]) {
      window.open(this.dadosUser.data_result.contract.urls[0])
    } else {
      console.error('O URL do contrato não está definido.');
    }
  }

  openComprovant() {
    if (this.dadosUser.urlPaymentVoucher) {
      window.open(this.dadosUser.urlPaymentVoucher)
    } else {
      console.error('O URL do contrato não está definido.');
    }
  }

  StatusMatrimonialtraductor(status: string) {
    switch (status) {
        case "single":
            return "Solteiro";
        case "married":
            return "Casado";
        case "widower":
            return "Viúvo";
        case "divorced":
            return "Divorciado";
        default:
            return status;
      }
  }

  assignProposal() {
    if (this.dadosUser.formalizationResponse.subjects[0].providerUrl == "" || this.dadosUser.formalizationResponse.subjects[0].providerUrl == null) {
      window.open(this.dadosUser.formalizationResponse.subjects[0].signUrl)
    } else {
      window.open(this.dadosUser.formalizationResponse.subjects[0].providerUrl)
    }
  }

  copyLink(){
    if (this.dadosUser.formalizationResponse.subjects[0].providerUrl == "" || this.dadosUser.formalizationResponse.subjects[0].providerUrl == null) {
      this.clipboard.copy(this.dadosUser.formalizationResponse.subjects[0].signUrl)
      this.snackBarMessageService.success("Link Copiado Com Sucesso");
    } else {
      this.clipboard.copy(this.dadosUser.formalizationResponse.subjects[0].providerUrl);
      this.snackBarMessageService.success("Link Copiado Com Sucesso");
    }
  }

  copyValue(value: any) {
    try{
      this.clipboard.copy(value);
      this.snackBarMessageService.success("Valor copiado com sucesso !")
    }catch(e){
      this.snackBarMessageService.success("Erro ao copiar o valor!")
      console.log(e)
    }
  }

    findAllvalue(value: any){
      this.installments = value.length
      for (let i of value){
        this.releasedValue += i.duePrincipal;
        this.preFixedAmountSum += i.preFixedAmount;
        this.principalAmortizationAmountSum += i.totalAmount;
      }
    }
}
