export class Borrower {
    public name: string = '';
    public motherName: string = '';
    public birthDate: string = '';
    public profession: string = '';
    public nationality: string = '';
    public maritalStatus: string = '';
    public isPep: boolean = false;
    public individualDocumentNumber: string = '';
    public documentIdentificationNumber: string = '';
    public document_identification: string = '';
    public email: string = '';
    public phone = {
      countryCode: "55",
      areaCode: "",
      number: "",
    };
    public completePhone: string = ""
    public address = {
      street: "",
      state: "",
      city: "",
      neighborhood: "",
      number: "",
      postalCode: "",
      complement: ""
    };
};