import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-help',
  templateUrl: './modal-help.component.html',
  styleUrls: ['./modal-help.component.css']
})
export class ModalHelpComponent {

}
