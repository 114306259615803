import { Component, OnInit, HostListener } from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {FloatLabelType} from '@angular/material/form-field';
import { FgtsSimulationComponent } from '../fgts-simulation/fgts-simulation.component';
import { MatDialog } from '@angular/material/dialog';
import { DebtFgts } from '../entities/DebtFgts';
import { AvailableBalanceInquiry } from '../entities/Available-Balance-Inquiry';
import { DocumentTypeList } from '../entities/document_type';
import { RegisterComponent } from '../register/register.component';
import { SimulationModuleService } from '../services/simulation-module.service';
import { interval } from 'rxjs';
import { Router,ActivatedRoute  } from '@angular/router';
import { AppSetting } from '../entities/utils/appsettings';
import { SnackBarMessageService } from '../services/snack-bar.service';
import { CorService } from '../services/color.service';
import { ModalHelpComponent } from './modal-help/modal-help.component';
import { ArchiveComponentComponent } from '../components/archive-component/archive-component.component';
import {Clipboard} from '@angular/cdk/clipboard';
import { ChangeStatusFilaComponent } from '../fila/change-status-fila/change-status-fila.component';
import { ProposalStatus } from '../services/proposalStatus.service';
import { ProposalsList } from '../services/proposalsList.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { KanbanService } from '../components/kanban/kanban.service';
import { Format } from '../services/format.service';



@Component({
  selector: 'app-fgts-consult',
  templateUrl: './fgts-consult.component.html',
  styleUrls: ['./fgts-consult.component.css'],
  providers: [
    SnackBarMessageService
  ]
})

export class FgtsConsultComponent {
  
  iduser: any = localStorage.getItem("userId")
  pf: any
  public showAnotherTab = false
  constructor(
    public format: Format,
    public router: Router,
    public _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public simulation_service: SimulationModuleService,
    private activatedRoute: ActivatedRoute,
    public snackBarMessageService: SnackBarMessageService,
    private corService: CorService,
    private clipboard: Clipboard,
    private proposalsList: ProposalsList,
    public kanbanService: KanbanService,
    ){
      this.colorPrimary = this.colorPrimary === '' ? '#4ECD72' : this.colorPrimary;
      this.colorSecond = this.colorSecond === '' ? '#FFF' : this.colorSecond;
    }
   
    public appSetting = new AppSetting()
    
    id: any;
    colorPrimary: string = this.corService.colorPrimary
    colorSecond: string = this.corService.colorSecond
    mainFontColor = this.corService.mainFontColor
    colorTicket: string = ""
    color: string = "";
    userId: any = localStorage.getItem("userId")
    queue: Boolean = false
    queuu_data: any;
    list_change_value: any = []
    clientEntities = new AvailableBalanceInquiry();
    showBotton = true;
    debt = new DebtFgts;
    hideRequiredControl = new FormControl(false);
    floatLabelControl = new FormControl('auto' as FloatLabelType);
    token: string|undefined;
    hide = true;
    showConsultation: boolean = true;
    SimulationValue = "";
    PhoneNumber: Number = 0;
    submit_click = true;
    options = this._formBuilder.group({
      hideRequired: this.hideRequiredControl,
      floatLabel: this.floatLabelControl,
    });
    isMobile = false;
    selectedIndex: number | null = null;
    visibilityCpfContainer: boolean = true
    layout: number = 2
    showLayout: boolean = false
    isCopying: boolean = false;
    openCloseDivConsultArrow = true
    valueSearchStatusProposal: string = "Todos"
    statusChoicedForSearch = []
    ButtonAssistentFinantoPay: boolean = false
    homelog: boolean = false
    loadingButtonProposals = false

    customerProposalsLenght: any = 0
    manualProposalsLenght: any = 0
    opportunityProposalsLenght: any = 0

    proposalStatusName = ['Todos', 'Contrato Fechado', 'Em Negociação', 'Saldo Disponível', 
    'Simulação Enviada', 'Saldo Insuficiente', 'Sem Interesse', 'Contrato Pago', 'Sem Retorno', 'Processando Consulta']
    
  ngOnInit(){
    let userId: string | null = localStorage.getItem("userId")
    this.openInitialContract(userId!)
    this.checkUserId()
    this.checkIfMobile();
    window.addEventListener('resize', () => this.checkIfMobile());
  
    this.simulation_service.getUser(this.iduser).subscribe( (result: any) => {
      this.pf = result.salesPF
      if(this.pf == false || this.isMobile) {
        this.changeLayout(0)
      } else {
        this.changeLayout(2)
      }
      this.showLayout = true
    })
    var urlAtual = window.location.href;
    console.log(urlAtual);
    
    this.CountAvailableProposals()

    if (urlAtual == "https://app-homolog.finantopay.com.br/consult") {
      this.homelog = true
    } else {
      this.homelog = false
    }

  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  toggleView() {
    this.showConsultation = !this.showConsultation;
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.verificarTamanhoTela();
  }

  verificarTamanhoTela() {
    const larguraTela = window.innerWidth;
    if (larguraTela < 768) { 
      this.executarAcao();
    }
  }

  executarAcao() {
    console.log("Ação executada em uma tela de celular");
  }
  
  showAnother(typeCode: number) {
    this.showLayout = false
    let userId: string | null = localStorage.getItem("userId")
    this.proposalsList.typeCode = typeCode
    this.selectedIndex = typeCode;
    this.showAnotherTab = true
    this.checkUserId()
    this.isMobile = window.innerWidth <= 768; 
    this.showAnotherTab = false
    this.kanbanService.search = ""
    this.openAllContracts(userId!)
  }

  CountAvailableProposals() {
    this.simulation_service.CountAvailableBalance(this.iduser).subscribe((scc:any) => {
      console.log(scc, 'testttt')
      this.customerProposalsLenght = scc.customer
      this.manualProposalsLenght = scc.manual
      this.opportunityProposalsLenght = scc.opportunity
    },err => {
      console.log(err)
      this.queue = false
      this.submit_click = true
      this.showBotton = true
    })
  }

  updateProposals() {
    if (this.loadingButtonProposals) {
        return;
    }

    this.showLayout = false;
    this.loadingButtonProposals = true;
    this.openAllContracts(this.userId);
    this.proposalsList.reloadProposals(this.userId, this.proposalsList.typeCode);
    
    setTimeout(() => {
        this.loadingButtonProposals = false;
    }, 10000);
}

  changeLayout(numberLayout: number) {
    this.layout = numberLayout
  }

  activeButtonAssistentFinantoPay() {
    this.ButtonAssistentFinantoPay = !this.ButtonAssistentFinantoPay;
  }

  searchStatusProposal() {
    this.statusChoicedForSearch = this.proposalsList.listProposalsOriginal.filter(proposal => {
      return proposal.statusFront === this.valueSearchStatusProposal
    })
    if(this.valueSearchStatusProposal != "Todos") {
      this.proposalsList.listProposals = this.statusChoicedForSearch
      this.proposalsList.filterActived = false
    } else {
      this.proposalsList.listProposals = this.proposalsList.listProposalsOriginal
      this.proposalsList.filterActived = true
    }
  }

  normalizeInput(input: string): string {
    return input.replace(/[^a-zA-Z0-9]/g, '');
  }
  
  filterProposalsByDocumentNumber(inputValue: string) {
    const normalizedInput = this.normalizeInput(inputValue);
    this.kanbanService.search = normalizedInput;
  
    const filteredProposals = this.proposalsList.listProposalsOriginal.filter(proposal => {
      const documentNumberMatch = this.normalizeInput(proposal.documentNumber).includes(normalizedInput);
      const phoneMatch = proposal.phone && this.normalizeInput(`${proposal.phone.area_code}${proposal.phone.number}`).includes(normalizedInput);
      return documentNumberMatch || phoneMatch;
    });
  
    this.proposalsList.listProposals = filteredProposals;
  
    this.kanbanService.filterProposalForStatus();
  }
  

  copyPhone(AreaCode, number: any) {
    try{
      let value = `${AreaCode} ${number}`
      this.isCopying = true;
      this.clipboard.copy(value);
      console.log(value)
      this.isCopying = false
      this.snackBarMessageService.success("Valor copiado com sucesso !")
    }catch(e){
      this.isCopying = false
      this.snackBarMessageService.success("Erro ao copiar o valor!")
      console.log(e)
    }
  }

  openChangeStatusFila(key: string) {
    let body = {
      key: key,
      typecode: this.proposalsList.typeCode
    }
    const dialogRef = this.dialog.open(ChangeStatusFilaComponent, {
      width: '700px',
      height: '240px',
      data: body
    });
  }

  colorTicketForStatus(status: string): string {
    let color: string;
  
    switch (status) {
      case 'Consultando':
        color = "#C2B24A";
        break;
  
      case 'Saldo Disponível':
        color = '#43C466';
        break;
  
      case 'Simulação Enviada':
        color = '#00B78F';
        break;
  
      case 'Em Negociação':
        color = '#0090C8';
        break;
  
      case 'Contrato Fechado':
        color = '#2D5CB0';
        break;
  
      case 'Sem Retorno':
      case 'Sem Interesse':
        color = '#C41F1F';
        break;
  
      case 'Processando Consulta':
        color = '#C2B24A';
        break;
  
      default:
        color = 'black'; 
        break;
    }
  
    return color;
  }

  copyValue(value: any) {
    try{
      this.isCopying = true;
      this.clipboard.copy(value);
      console.log(value)
      this.isCopying = false
      this.snackBarMessageService.success("Valor copiado com sucesso !")
    }catch(e){
      this.isCopying = false
      this.snackBarMessageService.success("Erro ao copiar o valor!")
      console.log(e)
    }
  }

  openMenu(item: any): void {
    let body = {
      "key": item.key,
      "typecode":  this.proposalsList.typeCode,
      "document_number": item.documentNumber,
      "status_events":  item.documentNumber ,
      "status":  item.status,
      "data": item
    };
    
    const dialogRef = this.dialog.open(ArchiveComponentComponent, {
      width: '50%',
      height: '35%',
      data: body
    });
  }  

    getFloatLabelValue(): FloatLabelType {
      return this.floatLabelControl.value || 'auto';
    };

    modalHelp() {
      console.log('help')
      const dialogRef = this.dialog.open(ModalHelpComponent, {
        width: '1000px',
        height: '75%',
      });
    }

    openAllContracts(userId: string){
      if (this.kanbanService.search.length == 0 ) {
        if(this.proposalsList.filterActived) {
          this.checkUserId()
          this.simulation_service.FindAvaibleBalance(userId, this.proposalsList.typeCode).subscribe( (scc:any) => {
            this.proposalsList.listProposals = scc
            this.proposalsList.listProposalsOriginal = scc
            console.log(this.proposalsList.listProposals)
            this.list_change_value = scc
            this.queue = true
            this.submit_click = true
            this.showBotton = true
            this.kanbanService.filterProposalForStatus();
            this.searchStatusProposal()
            this.showLayout = true
          },err => {
            console.log(err)
            this.queue = false
            this.submit_click = true
            this.showBotton = true
          }
        )
      }
    }
  }

  openInitialContract(userId: string){
    if (this.kanbanService.search.length == 0 ) {
      if(this.proposalsList.filterActived) {
        this.checkUserId()
        this.simulation_service.FindAvaibleBalance(userId, this.proposalsList.typeCode).subscribe( (scc:any) => {
          this.proposalsList.listProposals = scc
          this.proposalsList.listProposalsOriginal = scc
          console.log(this.proposalsList.listProposals)
          this.list_change_value = scc
          this.queue = true
          this.submit_click = true
          this.showBotton = true
          this.kanbanService.filterProposalForStatus();
          this.searchStatusProposal()
        },err => {
          console.log(err)
          this.queue = false
          this.submit_click = true
          this.showBotton = true
        }
      )
    }
  }
}
    
    openRegisterComponent(){
      console.debug(`Token [${this.token}] generated`);
      const body = {
        "ammount": this.SimulationValue,
        "data": {}
      }
      const dialogRef = this.dialog.open(RegisterComponent, {
        width: '100%',
        height: '85%',
        data: body
      });
    }

  checkUserId() {
    if (!this.userId || this.userId == null) {
      this.router.navigate(['error']);
    }
  }

  extrairDescricao(jsonString: string): string | null {
    try {
      const parsedData = JSON.parse(jsonString);
      if (parsedData && parsedData.description) {
        return parsedData.description;
      }
    } catch (error) {
      console.error('Erro ao analisar JSON:', error);
    }
    return null;
  }

  converterData(dataISO: string) {
    const data = new Date(dataISO);
    const ano = data.getFullYear();
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const dia = data.getDate().toString().padStart(2, '0');
    const hora = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    const segundos = data.getSeconds().toString().padStart(2, '0');
    
    return `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;
  }

  removerCaracteresNaoNumericos(numero: string): string {
    let numeroNumerico = "";
    for (const caractere of numero) {
      if (!isNaN(Number(caractere))) {
        numeroNumerico += caractere;
      }
    }
    return numeroNumerico;
  }

  desabilitySearchCpf() {
    if(this.visibilityCpfContainer == true) {
      this.visibilityCpfContainer = false
      this.openCloseDivConsultArrow = false
    } else {
      this.visibilityCpfContainer = true
      this.openCloseDivConsultArrow = true
    }
  }

  getfgtsValue() {
      this.submit_click = false;
      this.checkUserId()
      let validadorCPF = /^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})$/
      if(!validadorCPF.test(this.clientEntities.countryIdentity)) {
        console.log('erro')
        this.snackBarMessageService.error("CPF inválido.");
      }  else {
          let __body = {
            "documentNumber": this.clientEntities.countryIdentity,
            "phone": this.clientEntities.phone,
            "userId": this.userId!
          }
          this.simulation_service.availableBalance(__body).subscribe( async (result: any) => {
            this.submit_click = true
            this.valueSearchStatusProposal = "Todos"
            this.queue = true
            this.proposalsList.listProposals = await this.simulation_service.FindAvaibleBalance(this.iduser, this.proposalsList.typeCode).toPromise();
            this.proposalsList.listProposalsOriginal = this.proposalsList.listProposals
            this.kanbanService.filterProposalForStatus();
            this.clientEntities.countryIdentity = ""
            this.clientEntities.phone = ""
          },err => {
            console.log(err)
            this.submit_click = true
            this.snackBarMessageService.error(err.error.messages[0]["text"])
            this.submit_click = true
            this.showBotton = true
          })
      }
    }

  copyScriptValueSimulation(value) {
    if(this.pf == true) {
        this.isCopying = true;
        this.clipboard.copy(`Ótima notícia! Você tem ${value} disponíveis para saque. 
        
        A liberação é super rápida e acontece em apenas alguns minutos.
        
        *Gostaria de prosseguir com a contratação?* 🚀`);
        console.log(value)
        this.isCopying = false
        this.snackBarMessageService.success("Valor copiado com sucesso !")
      }
    if(this.pf == false) {
        this.isCopying = true;
        this.clipboard.copy(value);
        console.log(value)
        this.isCopying = false
        this.snackBarMessageService.success("Valor copiado com sucesso !")
    }
  }

  openContractSimulation(item: any) {
      if(item.status == "pending" || item.status == null){
      this.snackBarMessageService.error("Contrato Em Analise, Por Favor Aguarde!")
    }else {
        let body = {
          "key": "",
          "document_number": item.documentNumber,
          "status_events":  item.documentNumber ,
          "status":  item.status,
          "data": item
        };
        const dialogRef = this.dialog.open(RegisterComponent, {
          width: '100%',
          height: '85%',
          data: body
        });
    }}

    formatarCPFOnPressKey(event: any) {
      let value = event.target.value.replace(/\D/g, '');
      if (value.length > 11) {
        value = value.slice(0, 11);
      }
      if (value.length >= 4 && value.length <= 6) {
        value = `${value.substr(0, 3)}.${value.substr(3)}`;
      } else if (value.length >= 7 && value.length <= 9) {
        value = `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6)}`;
      } else if (value.length >= 10) {
        value = `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}-${value.substr(9, 2)}`;
      }
      this.clientEntities.countryIdentity = value;
    }
}
