import { Component, OnInit } from '@angular/core';
import { SimulationModuleService } from '../services/simulation-module.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarMessageService } from '../services/snack-bar.service';

@Component({
  selector: 'app-validate-session',
  templateUrl: './validate-session.component.html',
  styleUrls: ['./validate-session.component.css'],
  providers: [SnackBarMessageService]
})
export class ValidateSessionComponent implements OnInit {

  userId: any;
  rota: any;

  constructor(
    public router: Router,
    public simulation_service: SimulationModuleService,
    public snackBarMessageService: SnackBarMessageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.userId = params['id'];
      this.rota = params['rota'];
      this.validateSession();
    });
  }

  validateSession() {
    if (this.userId && this.rota == null) {
      this.simulation_service.getUser(this.userId).subscribe(
        (result: any) => {
          console.log(result, 'resultado da getUser');
          if (result) {
            localStorage.setItem('userId', result.userId);
            this.router.navigate(['consult']);
          } else {
            this.snackBarMessageService.error("Acesso negado, usuário sem permissão.");
            this.router.navigate(['error']);
          }
        },
        (err) => {
          console.log(err);
          this.snackBarMessageService.error("Acesso negado, usuário sem permissão.");
          this.router.navigate(['error']);
        }
      );
    }

    if (this.userId && this.rota) {
      const routeExists = this.router.config.some(route => route.path === this.rota);
      if (routeExists) {
        this.simulation_service.getUser(this.userId).subscribe(
          (result: any) => {
            if (result) {
              localStorage.setItem('userId', result.userId);
              this.router.navigate([this.rota]);
            } else {
              this.snackBarMessageService.error("Acesso negado, usuário sem permissão.");
              this.router.navigate(['error']);
            }
          },
          (err) => {
            console.log(err);
            this.snackBarMessageService.error("Acesso negado, usuário sem permissão.");
            this.router.navigate(['error']);
          }
        );
      } else {
        this.snackBarMessageService.error("Rota não existe");
        this.router.navigate(['error']);
      }
    }
  }
}