import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog-component',
  templateUrl: './confirmation-dialog-component.component.html',
  styleUrls: ['./confirmation-dialog-component.component.css']
})
export class ConfirmationDialogComponentComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponentComponent>) {}

  onConfirm(): void {
    this.dialogRef.close(true);  // Retorna "true" se o usuário confirmar
  }

  onCancel(): void {
    this.dialogRef.close(false);  // Retorna "false" se o usuário cancelar
  }
}
