import { Component, Input, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ProposalsList } from 'src/app/services/proposalsList.service';
import { SimulationModuleService } from 'src/app/services/simulation-module.service';
import { KanbanService } from './kanban.service';

@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.css']
})
export class KanbanComponent {
  
  @Input() pf: any = null
  
  constructor(
    public kanbanService: KanbanService,
    public proposalsList: ProposalsList,
    private simulation_service: SimulationModuleService,  
  ) {}

  iduser: any = localStorage.getItem("userId")

  ngOnInit() {
    this.loadingProposals();
    this.kanbanService.consulting = ""
    this.kanbanService.balanceAvailable = ""
    this.kanbanService.simulationSent = ""
    this.kanbanService.inNegotiation = ""
    this.kanbanService.signedContract = ""
    this.kanbanService.noInteressing = ""
    this.kanbanService.error = ""
    this.kanbanService.filterProposalForStatus();
  }
  
  async loadingProposals() {
    if (this.kanbanService.search.length === 0) {
      if (this.proposalsList.filterActived) {
        try {
          this.proposalsList.listProposals = await this.simulation_service.FindAvaibleBalance(this.iduser, this.proposalsList.typeCode).toPromise();
          this.proposalsList.listProposalsOriginal = this.proposalsList.listProposals;
        } catch (err) {
          console.log(err);
        }
      }
    }
    this.kanbanService.filterProposalForStatus();
  }
  
  async drop(event: CdkDragDrop<string[]>, codeStatusForProposal?: number) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      let contentProposal: any = event.container.data[event.currentIndex];
      if (codeStatusForProposal) {
        await this.updateStatusProposal(contentProposal, codeStatusForProposal);
        this.kanbanService.search = "";
        this.proposalsList.listProposals = await this.simulation_service.FindAvaibleBalance(this.iduser, this.proposalsList.typeCode).toPromise();
      }
    }  
  }
  
  async updateStatusProposal(contentProposal: any, codeStatusForProposal: number) {
    let body = {
      "key": contentProposal.key,
      "userId": this.iduser,
      "statusCode": codeStatusForProposal
    };
    try {
      const result = await this.simulation_service.UpdateStatusAvailableBalance(body).toPromise();
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  }
  
    calcTotalValueOfColumn(data: any[]): string {
      if (!data || !Array.isArray(data)) return '0,00';
  
      const total = data.reduce((acc, obj) => {
        return acc + (obj.valueSimulation || 0);
      }, 0);
  
      return total.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    }
  }
