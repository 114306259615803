<div class="tableBox">
    <table>
        <thead>
            <tr>
                <th>{{ appSetting.LG_PTBR.installment }}</th>
                <th>{{ appSetting.LG_PTBR.maturity }}</th>
                <th>{{ appSetting.LG_PTBR.valuebirthday }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of dataTable" style="border-bottom: 1px solid #4ECD72;">
                <td>{{ data.installment_number ? data.installment_number : data.installmentNumber }}</td>
                <td>{{ data.due_date ? format.formatAmericanDateForDatePTBR(data.due_date) : format.formatAmericanDateForDatePTBR(data.dueDate) }}</td>
                <td>{{ data.total_amount ? (data.total_amount | currency:'BRL' ) : (data.totalAmount | currency:'BRL')  }}</td>
            </tr>
            <tr>
                <td style="background: #2E964B; color: white;"><strong>{{ appSetting.LG_PTBR.total }}</strong></td>
                <td style="background: #2E964B; color: white"><strong>{{ dataTable[dataTable.length - 1].installmentNumber }} {{dataTable[dataTable.length - 1].installmentNumber == 1 ? 'Ano' : 'Anos'}}</strong></td>
                <td style="background: #2E964B; color: white"><strong>{{ totalAmount | currency:'BRL'}}</strong></td>
            </tr>
        </tbody>
    </table>
</div>