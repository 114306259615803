<div class="container">
    <h2>{{ appSetting.LG_PTBR.selectDate }}</h2>

    <div style="display: flex; gap: 10px; margin-bottom: 10px;">
      <label><input type="radio" name="opcao" value="TED" [(ngModel)]="methodPayment">Conta Bancaria</label>
      <label><input type="radio" name="opcao" value="PIX" [(ngModel)]="methodPayment">PIX</label>
    </div>

    <div>
        <div *ngIf="methodPayment == 'TED'" class="form-group" style="padding: 11px 71px; ">
          <mat-form-field class="personStyleForm" style="height: 87px;">
            <mat-label>Escolha o banco</mat-label>
            <mat-select [(ngModel)]="bankCode">
              <mat-option *ngFor="let banco of bancos" [value]="banco.valor">
                {{banco.nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="personStyleForm" style="margin-left: 10px;">
          <input matInput [(ngModel)]="branchNumber" maxlength="4" mask="0000" placeholder="Número da Agência" />
          </mat-form-field>
          <mat-form-field class="personStyleForm">
          <input matInput [(ngModel)]="accountNumber" placeholder="Número da Conta" />
          </mat-form-field>
          <mat-form-field class="personStyleForm" style="margin-left: 10px;">
          <input matInput [(ngModel)]="accountDigit" mask="0" placeholder="Dígito da Conta" />
          </mat-form-field>
      </div>


      <div *ngIf="methodPayment == 'PIX'">
        <mat-form-field class="personStyleForm" style="height: 87px;">
          <mat-label>{{ pixTypeKey }}</mat-label>
          <mat-select [(ngModel)]="pixTypeKey" (ngModelChange)="updateMask($event)">
            <mat-option *ngFor="let paymentType of MetodsPaymentPix" [value]="paymentType">
              {{ paymentType.type }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="personStyleForm" style="margin-left: 10px;">
        <input matInput [(ngModel)]="pixKey" [mask]="masks" placeholder="Chave" />
        </mat-form-field>
      </div>
    <div class="containerButtons" style="margin-left: 20%;">
        <button class="button red" (click)="onNoClick()">{{ appSetting.LG_PTBR.cancel }}</button>
        <button class="button green" (click)="confirm()">{{ appSetting.LG_PTBR.confirm }}</button>
    </div>
</div>
