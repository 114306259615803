import { Component } from '@angular/core';
import { SimulationModuleService } from '../services/simulation-module.service';
import { AppSetting } from '../entities/utils/appsettings';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponentComponent } from './confirmation-dialog-component/confirmation-dialog-component.component';
@Component({
  selector: 'app-customer-indication',
  templateUrl: './customer-indication.component.html',
  styleUrls: ['./customer-indication.component.css']
})
export class CustomerIndicationComponent {

  constructor(
    public simulation_service: SimulationModuleService,
    private dialog: MatDialog,
  ){

  }
  public appSetting = new AppSetting()
  public userId: string | null = localStorage.getItem("userId")
  public iduser: any;
  public pf: any;
  public customerIndication = [];

  ngOnInit(){
    this.iduser = localStorage.getItem('userId')!
    this.simulation_service.getUser(this.iduser).subscribe( (result: any) => {
      this.pf = result.salesPF
    })
    this.simulation_service.getCustomerIndicationDisbursedPendencies().subscribe( (result: any) => {
      console.log(result)
      this.customerIndication = result
    })
  }

  updatePaymentStatus(countryIdentity: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent,{width: '800px', height: '150px'});

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.simulation_service.updateCustomerIndicationDisbursedPendencies(countryIdentity).subscribe(
          (response: any) => {
            console.log(response);
            window.location.reload()
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        console.log('Ação cancelada');
      }
    });
  }
}
