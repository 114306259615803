import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class Format {

    formatValue(valor: any) {
        valor = parseFloat(valor).toFixed(2);
        valor = valor.replace(".", ",").replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        valor = "R$ " + valor;
        return valor;
      }

      
    formatCPF(cpf: string): string {
        cpf = cpf.replace(/\D/g, '');
    
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    
    formatPhone(areaCode, numero) {
        const numeroLimpo = numero.replace(/\D/g, '');
        const temNoveDigitos = numeroLimpo.length === 9;
        const codigoArea = `(${areaCode}) `;
        const parte1 = temNoveDigitos ? numeroLimpo.slice(0, 5) : numeroLimpo.slice(0, 4);
        const parte2 = temNoveDigitos ? numeroLimpo.slice(5) : numeroLimpo.slice(4);
        const telefoneFormatado = `${codigoArea}${parte1}-${parte2}`;
        
        return telefoneFormatado;
    }

    formatAmericanDateForDatePTBR(AmericanDate: string): string {
        var partes = AmericanDate.split('-');
        var dataFormatada = partes[2] + '/' + partes[1] + '/' + partes[0];
        
        return dataFormatada;
    }

    convertDateIsoInDateAndHours(dataISO: string) {
        const data = new Date(dataISO);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const dia = data.getDate().toString().padStart(2, '0');
        const hora = data.getHours().toString().padStart(2, '0');
        const minutos = data.getMinutes().toString().padStart(2, '0');
        const segundos = data.getSeconds().toString().padStart(2, '0');
        
        return `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;
      }

    convertDateIsoInDate(dataISO: string) {
        const data = new Date(dataISO);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const dia = data.getDate().toString().padStart(2, '0');
        const hora = data.getHours().toString().padStart(2, '0');
        const minutos = data.getMinutes().toString().padStart(2, '0');
        const segundos = data.getSeconds().toString().padStart(2, '0');
        
        return `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;
    }
    
    formatPhoneWithOneParameter(numero: any) {
        let numeroString = numero.toString();

        
        if (numeroString.length !== 11) {
            console.error("O número deve conter 11 dígitos.");
            return numeroString;
        }
        
        let numeroFormatado = `(${numeroString.slice(0, 2)}) ${numeroString.slice(2, 7)}-${numeroString.slice(7, 11)}`;
        return numeroFormatado;
    }

    formatDateToISO(dateString: string): string {
        const [year, month, day] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);
    
        return date.toISOString();
    }
}