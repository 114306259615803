<div class="container">
  <div class="section">
    <h1>Dados Pessoais</h1>
    <div class="input-container">
      <div class="inputComplete">
        <label for="">CPF</label>
        <input type="text" placeholder="CPF..." [(ngModel)]="borrower.individualDocumentNumber" mask="000.000.000-00||00.000.000/0000-00" maxlength="14" disabled>
      </div>
      <div class="inputComplete">
        <label for="">Nome</label>
        <input type="text" placeholder="Nome Completo*" [(ngModel)]="borrower.name" (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label for="">Data de nascimento</label>
        <input type="text" placeholder="Data de Nascimento..." mask="00/00/0000" (input)="updateBirthDate($event)" [(ngModel)]="borrower.birthDate" (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label>Nacionalidade</label>
        <select [(ngModel)]="borrower.nationality" placeholder="Nacionalidade">
          <option *ngFor="let nationality of nationalityOpitions" [value]="nationality">
            {{ nationality }}
          </option>
        </select>
      </div>
      <div class="inputComplete">
        <label for="">Estado Civil</label>
        <select [(ngModel)]="borrower.maritalStatus" placeholder="Estado civil">
          <option *ngFor="let item of marital_status" [value]="item.value">
            {{ item.pt }}
          </option>
        </select>
      </div>
      <div class="inputComplete">
        <label for="">Profissão</label>
        <input type="text" placeholder="Profissão" [(ngModel)]="borrower.profession" (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label>Nome da Mãe</label>
        <input type="text" placeholder="Nome Da Mãe*" [(ngModel)]="borrower.motherName" (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label for="">Telefone</label>
        <input type="text" placeholder="Telefone" [(ngModel)]="borrower.completePhone" maxlength="15" mask="(00)00000-0000" (input)="onPhoneNumberInput()" (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label for="">Email</label>
        <input type="text" placeholder="Email" [(ngModel)]="borrower.email" (keyup.enter)="addNewItem()">
      </div>
    </div>
  </div>
  <div class="section">
    <h1>Endereço</h1>
    <div class="input-container">
      <div class="inputComplete">
        <label for="">CEP</label>
        <input type="text" [(ngModel)]="borrower.address.postalCode" placeholder="CEP*" mask="00000-000" (focusout)="loadZipCode()" (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label for="">Rua</label>
        <input type="text" placeholder="Rua..." [(ngModel)]="borrower.address.street" placeholder="Rua*" (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label for="">Número</label>
        <input type="text" [(ngModel)]="borrower.address.number" placeholder="Número*" #numeroInput (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label for="">Complemento</label>
        <input type="text" placeholder="Complemento..." [(ngModel)]="borrower.address.complement" placeholder="Complemento" (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label for="">Bairro</label>
        <input type="text" placeholder="Bairro..." [(ngModel)]="borrower.address.neighborhood" placeholder="Bairro*" (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label for="">Cidade</label>
        <input type="text" placeholder="Cidade..." [(ngModel)]="borrower.address.city" placeholder="Cidade*" (keyup.enter)="addNewItem()">
      </div>
      <div class="inputComplete">
        <label for="">Estado</label>
        <input type="text" placeholder="Estado..." [(ngModel)]="borrower.address.state" placeholder="Estado*" (keyup.enter)="addNewItem()">
      </div>
    </div>
  </div>
  <div class="section" *ngIf="pf == true">
    <h1>Dados Bancários</h1>
    <div style="display: flex; gap: 10px; margin-bottom: 10px;">
      <label><input type="radio" name="opcao" value="TED" [(ngModel)]="opitionsProposal.changeMetodPayment">Conta Bancaria</label>
      <label><input type="radio" name="opcao" value="PIX" [(ngModel)]="opitionsProposal.changeMetodPayment">PIX</label>
    </div>
    <div class="input-container" *ngIf="opitionsProposal.changeMetodPayment == 'TED'">
      <div class="inputComplete">
        <label for="">Banco</label>
        <select [(ngModel)]="disbursementBankAccount.bankCode">
          <option *ngFor="let banco of bancos" [value]="banco.valor">
            {{ banco.nome }}
          </option>
        </select>
      </div>
      <div class="inputComplete">
        <label>Número da Agência</label>
        <input type="text" [(ngModel)]="disbursementBankAccount.branchNumber" maxlength="4" mask="0000" placeholder="Número da Agência" (keyup.enter)="addNewBankInformationInItem()">
      </div>
      <div class="inputComplete">
        <label for="">Número da Conta</label>
        <input type="text" [(ngModel)]="disbursementBankAccount.accountNumber" placeholder="Número da Conta" (keyup.enter)="addNewBankInformationInItem()">
      </div>
      <div class="inputComplete">
        <label for="">Dígito da Conta</label>
        <input type="text" [(ngModel)]="disbursementBankAccount.accountDigit" mask="0" placeholder="Dígito da Conta" (keyup.enter)="addNewBankInformationInItem()">
      </div>
    </div>
    <div class="input-container" *ngIf="opitionsProposal.changeMetodPayment == 'PIX'">
      <div class="inputComplete">
        <label for="">Tipo de chave</label>
        <select [(ngModel)]="selectedMetod" (change)="updateMask(selectedMetod)" (keyup.enter)="addNewBankInformationInItem()">
          <option *ngFor="let Metod of MetodsPaymentPix" [ngValue]="Metod">
            {{Metod.type}}
          </option>
        </select>
      </div>
      
      <div class="inputComplete">
        <label for="">Chave pix</label>
        <ng-container *ngIf="selectedMetod?.mask">
          <input type="text" [(ngModel)]="disbursementBankAccount.pixKey" mask="{{ selectedMetod?.mask }}" [disabled]="selectedMetod?.type === 'CPF'">
        </ng-container>
        <ng-container *ngIf="!selectedMetod?.mask">
          <input type="text" [(ngModel)]="disbursementBankAccount.pixKey" [disabled]="selectedMetod?.type === 'CPF'">
        </ng-container>
      </div>
    </div>
  </div>
  <div class="section" *ngIf="pf == false">
    <h1>{{ appSetting.LG_PTBR.aditionalData }}</h1>
    <div class="input-container">
      <input matInput [(ngModel)]="aditionalData.externalId" placeholder="Identificador Externo" (keyup.enter)="addNewItem()" />
    </div>
  </div>
</div>