import { Component,Inject,OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimulationModuleService } from '../services/simulation-module.service';
import { AvailableBalanceInquiry } from '../entities/Available-Balance-Inquiry';

import { MatDialog } from '@angular/material/dialog';
import { RegisterComponent } from '../register/register.component';
import { fgts_simulation_guess } from '../simluation-json/fgts_simulation_guess';
import { AppSetting } from '../entities/utils/appsettings';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { SnackBarMessageService } from '../services/snack-bar.service';
import { CorService } from '../services/color.service';

@Component({
  selector: 'app-fgts-simulation',
  templateUrl: './fgts-simulation.component.html',
  styleUrls: ['./fgts-simulation.component.css'],
  providers: [
    SnackBarMessageService
  ]
})

export class FgtsSimulationComponent {
  public form_post = {};
  public callbackError: string = "";
  clientEntities = new AvailableBalanceInquiry();
  
  constructor(
    public dialogRef: MatDialogRef<FgtsSimulationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public simulation_svc: SimulationModuleService,
    public dialog: MatDialog,
    public snackBarMessageService: SnackBarMessageService,
    private corService: CorService
    ){}
    
    public appSetting = new AppSetting()
    durationInSeconds = 5;
    simulation_value: number = 0
    AllowRegistry = false;
    __data = fgts_simulation_guess
    openTable = false
    sum: any = null
    totalAmount = 0;
    rate = 2.04
    simulatioinColor: string = this.corService.simulatioinColor
    
    ngOnInit() {  
      if(this.data.data.periods.length > 0) {
        for (const period of this.data.data.periods) {
            this.totalAmount += period.amount;
        }
      }
      this.totalAmount = (this.totalAmount * (this.rate * 0.01))
      this.simulation_value = 0
    }

    formatDateTimeToBR(dateTime: Date): string {
      const day = dateTime.getDate().toString().padStart(2, '0');
      const month = (dateTime.getMonth() + 1).toString().padStart(2, '0'); 
      const year = dateTime.getFullYear();
      return `${day}/${month}/${year}`;
    }

    findValue(){
      if(this.valideValue(this.simulation_value)){
        this.AllowRegistry = true;
        this.openTable = true
      }
    }

    valideValue(value: any) {
      if(value <= this.totalAmount) {
        return true
      }else{
        this.snackBarMessageService.error("Valor não disponivel para consulta");
        return false
      }
    }

    registryValue(){
      const dialogRef = this.dialog.open(RegisterComponent, {
        width: '100%',
        height: '75%',
        data: this.data
      });
    }

    formatarData(data: number): string {
      const parsedDate = new Date(data.toString());
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
      const formatter = new Intl.DateTimeFormat('pt-BR', options);
      const formattedDate = formatter.format(parsedDate);
      return formattedDate.substring(0, 10) + ' ' + formattedDate.substring(11);
    }

    formatarPreco(preco: number | null): string {
      if (!preco) {
        return '';
      }
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(preco);
    }
}
