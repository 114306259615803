<div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
  <div class="container" *ngIf="this.data.data.errorActionClient">
    <h2>{{ appSetting.LG_PTBR.problemDescription }}</h2>
    <div class="input-container">
      <div style="width: max-content;" >
        <p><span class="info-label"><b>{{ appSetting.LG_PTBR.problemDescription }}:</b> </span> <br><span class="info-value">{{ this.data.data.errorDescription}} </span></p>
        <p><span class="info-label"><b>{{ appSetting.LG_PTBR.clientActions }}:  </b></span> <br><span class="info-value">{{ this.data.data.errorActionClient }} </span></p>
    </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="!this.data.data.errorActionClient">
  <h2>{{ appSetting.LG_PTBR.consultValues }}</h2>
  <div class="input-container">
    <div class="form-group" style="display: contents;">
      <mat-form-field floatLabel="always">
        <mat-label>{{ appSetting.LG_PTBR.valueToBeConsulted }}</mat-label>
        <input matInput type="number" [(ngModel)]="simulation_value"  class="example-right-align" placeholder="0" required />
        <span matTextPrefix>R$&nbsp;</span>
        <span matTextSuffix>.00</span>
    </mat-form-field>
  </div>
    <button mat-raised-button color="primary" (click)="findValue()">{{ appSetting.LG_PTBR.find }}</button>
    <button mat-raised-button color="alert" *ngIf="AllowRegistry" (click)="registryValue()">{{ appSetting.LG_PTBR.registerCad }}</button>
  </div>
  <span class="info-label"><b> Valor Disponivel para Simulação:</b> </span> <br><span class="info-value">{{ this.totalAmount | currency:'BRL'}} </span> <br>
  <table class="app-table" *ngIf="openTable" style="width: 100%;">
    <thead style="width: 100%;" >     
        <tr [style.color]="simulatioinColor">
            <th>{{ appSetting.LG_PTBR.birthDate }}</th>
            <th>{{ appSetting.LG_PTBR.balanceAvailable }}</th>
            <th>{{ appSetting.LG_PTBR.anticipatedValue }}</th>
            <th>{{ appSetting.LG_PTBR.fees }}</th>
            <th>{{ appSetting.LG_PTBR.DCDU }}</th>
        </tr>
    </thead>
    <tbody style="width: 100%;" *ngFor="let i of __data.data.installments; let indexOfelement=index;">
        <tr>
            <td> {{i.due_date}} </td>
            <td>25</td>
            <td>{{i.due_principal | currency:'BRL'}}</td>
            <td>São Paulo</td>
            <td>{{i.tax_amount | currency:'BRL'}}</td>
        </tr>
    </tbody>
  </table>
</div>