import { Component } from '@angular/core';
import { SimulationModuleService } from '../services/simulation-module.service';
import { Format } from '../services/format.service';
import { ProposalStatus } from '../services/proposalStatus.service';
import { SnackBarMessageService } from '../services/snack-bar.service';

interface HistoricItem {
  creationDateTime: string;
  typeCode: number;
  typeName: string;
  status: string;
  nameQuery: string;
  iconQuery: string;
  selfQuery: boolean;
  valueSimulation: number;
  amountToReceive: number;
  signatureFinished: string | null;
  table: string | null;
}

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.css']
})
export class HistoricComponent {

  iduser: any = localStorage.getItem("userId");

  filters = {
    userId: this.iduser,
    individualDocumentNumber: '',
    dateBegin: this.getOneMonthAgoDate(),
    dateEnd: this.getTodayDate(),
    simulation: true,
    proposal: true
  };

  data: any;
  historic: HistoricItem[] = [];
  groupedHistoric: { [monthYear: string]: HistoricItem[] } = {};
  monthNames: string[] = [
    'JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO',
    'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO'
  ];

  constructor(
    private simulationService: SimulationModuleService,
    private format: Format,
    private proposalStatus: ProposalStatus,
    public snackBarMessageService: SnackBarMessageService
  ) {}

  private getOneMonthAgoDate(): string {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toISOString().split('T')[0];
  }

  private getTodayDate(): string {
    const date = new Date();
    return date.toISOString().split('T')[0];
  }

  filter() {
    const dateBeginISO = this.filters.dateBegin ? this.format.formatDateToISO(this.filters.dateBegin) : '';
    const dateEndISO = this.filters.dateEnd ? this.formatDateEndToISO(this.filters.dateEnd) : '';
  
    if (this.filters.individualDocumentNumber && this.filters.dateBegin && (this.filters.simulation || this.filters.proposal)) {
  
      if (dateBeginISO && dateEndISO && new Date(dateBeginISO) > new Date(dateEndISO)) {
        this.snackBarMessageService.error("A data de início não pode ser posterior à data de fim.");
        return; 
      }
  
      this.simulationService.Historic(
        this.filters.userId,
        this.filters.individualDocumentNumber,
        dateBeginISO,
        dateEndISO,
        this.filters.simulation,
        this.filters.proposal
      ).subscribe(
        (result: any) => {
          this.data = result;
          console.log(this.data);
          this.historic = [...result.simulations, ...result.proposals]
            .sort((a: any, b: any) => 
              new Date(a.creationDateTime).getTime() - new Date(b.creationDateTime).getTime()
            );
          console.log(this.historic);
          this.groupedHistoric = {};
          this.groupByMonth();
  
          if (!this.data.simulations.length && !this.data.proposals.length) {
            this.snackBarMessageService.error("Nenhum resultado encontrado.");
          }
        },
        err => {
          console.error(err);
          this.snackBarMessageService.error("Ocorreu um erro ao buscar os dados. Por favor, tente novamente.");
        }
      );
    } else {
      this.snackBarMessageService.error("Por favor, preencha todos os campos obrigatórios antes de filtrar.");
      return; 
    }
  }

  groupByMonth() {
    this.historic.forEach(item => {
      const date = new Date(item.creationDateTime);
      const yearMonth = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}`;
      
      if (!this.groupedHistoric[yearMonth]) {
        this.groupedHistoric[yearMonth] = [];
      }

      this.groupedHistoric[yearMonth].push(item);
    });

    this.groupedHistoric = Object.keys(this.groupedHistoric)
      .sort((a, b) => parseInt(b) - parseInt(a))
      .reduce((acc, key) => {
        acc[key] = this.groupedHistoric[key];
        return acc;
      }, {});
  }

  formatMonthYear(yearMonth: string): string {
    const year = yearMonth.slice(0, 4);
    const month = parseInt(yearMonth.slice(4), 10) - 1;
    return `${this.monthNames[month]} / ${year}`;
  }

  StatusMatrimonialtraductor(status: string) {
    switch (status) {
        case "single":
            return "Solteiro";
        case "married":
            return "Casado";
        case "widower":
            return "Viúvo";
        case "divorced":
            return "Divorciado";
        default:
            return status;
      }
  }

  formatDateEndToISO(dateString: string): string {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day, 23, 59, 59);
    return date.toISOString();
}
}