<mat-toolbar [style.background]="colorSecond" style="border-bottom: 1px solid #B5B5B5; padding: 2.5rem 0;">
    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;" >
      <div style="display: flex;" >
        <div class="image-container" style="display: flex;">
          <span [class.rotate-90]="rotateImage" [class.rotate-90-neg]="!rotateImage" [matMenuTriggerFor]="menu" (click)="toggleRotation()" style=" color: #4ECD72; margin-left: 27px; cursor: pointer;" class="material-symbols-outlined">
            <mat-icon style="font-size: 30px;">menu</mat-icon>
          </span>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="consult()">{{ appSetting.LG_PTBR.consultFgts }}</button>
          <button mat-menu-item (click)="followUp()">{{ appSetting.LG_PTBR.sideDishes }}</button>
        </mat-menu>
        <img  (click)="ReturnMenu()" alt="Logo Finanto pay" src="../../assets/finantoPayLogo.svg">
        <span class="example-spacer"></span>
      </div>
      <img *ngIf="LogoCompany !== ''" style="cursor: pointer;" style="cursor: pointer; width: 10rem" (click)="ReturnMenu()" alt="Logo Finanto pay"  [src]="LogoCompany">
    </div>
</mat-toolbar>