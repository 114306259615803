import { Financial } from "./utils/financial";

export class DisbursedAmount {
    public borrower: any = null;
    public target_disbursed_amount: number = 0;
    public financial: any = null;

    constructor(){
        this.financial = Financial
    }
}