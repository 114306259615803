import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { MatIconModule} from '@angular/material/icon';
import { MatButtonModule} from '@angular/material/button';
import { MatToolbarModule} from '@angular/material/toolbar';
import { FgtsConsultComponent } from './fgts-consult/fgts-consult.component';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FloatLabelType, MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule} from '@angular/material/select';
import { MatInputModule} from '@angular/material/input';
import { MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatMenuModule} from '@angular/material/menu';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from './environment/environment';
import { FgtsSimulationComponent } from './fgts-simulation/fgts-simulation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HttpClient, provideHttpClient } from '@angular/common/http';
import { FgtsMonitoringComponent } from './fgts-monitoring/fgts-monitoring.component';
import { MatTableModule} from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DocumentTypeComponent } from './components/document-type/document-type.component';
import { DocumentTypeInputComponent } from './components/document-type/document-type-input/document-type-input.component';
import { DocumentComponent } from './register/document/document.component';
import { PersonalInformationComponent } from './register/personal-information/personal-information.component';
import { BankaccountComponent } from './register/bankaccount/bankaccount.component';
import { RegisterComponent } from './register/register.component';
import { FilaComponent } from './fila/fila.component';
import {MAT_DATE_FORMATS, MatNativeDateModule} from '@angular/material/core'
import { SnackBarMessageService } from './services/snack-bar.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';import {Component} from '@angular/core';
import { Validators} from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper';
import { ResumeComponent } from './register/resume/resume.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MonitoringResumeComponent } from './fgts-monitoring/monitoring-resume/monitoring-resume.component';
import { ErrorComponent } from './error/error.component';
import { AreYourWantToDeleteComponent } from './fgts-monitoring/are-your-want-to-delete/are-your-want-to-delete.component';
import { ChangeDateRepresentationComponent } from './fgts-monitoring/change-date-representation/change-date-representation.component';
import { ValidateSessionComponent } from './validate-session/validate-session.component';
import { registerLocaleData } from '@angular/common';
import localePtBr from '@angular/common/locales/pt';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SnackbarComponent } from './services/snackbar/snackbar.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { ModalHelpComponent } from './fgts-consult/modal-help/modal-help.component';
import { ArchiveComponentComponent } from './components/archive-component/archive-component.component';
import { NgIconsModule } from '@ng-icons/core';
import { featherAirplay } from '@ng-icons/feather-icons';
import { bootstrapLink45deg } from '@ng-icons/bootstrap-icons';
import { ChangeStatusFilaComponent } from './fila/change-status-fila/change-status-fila.component';
import { TableInstallmentsComponent } from './components/table-installments/table-installments.component';
import { KanbanComponent } from './components/kanban/kanban.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HistoricComponent } from './historic/historic.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CustomerIndicationComponent } from './customer-indication/customer-indication.component';
import { ConfirmationDialogComponentComponent } from './customer-indication/confirmation-dialog-component/confirmation-dialog-component.component';

registerLocaleData(localePtBr);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FgtsConsultComponent,
    FgtsSimulationComponent,
    FgtsMonitoringComponent,
    DocumentTypeComponent,
    DocumentTypeInputComponent,
    RegisterComponent,
    DocumentComponent,
    PersonalInformationComponent,
    BankaccountComponent,
    FilaComponent,
    ResumeComponent,
    MonitoringResumeComponent,
    ErrorComponent,
    AreYourWantToDeleteComponent,
    ChangeDateRepresentationComponent,
    ValidateSessionComponent,
    SnackbarComponent,
    ModalHelpComponent,
    ArchiveComponentComponent,
    ChangeStatusFilaComponent,
    TableInstallmentsComponent,
    KanbanComponent,
    HistoricComponent,
    CustomerIndicationComponent,
    ConfirmationDialogComponentComponent
  ],
  imports: [
    NgxMaskDirective,
    NgxMaskPipe,
    MatProgressSpinnerModule,
    ClipboardModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatStepperModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatTableModule,
    HttpClientModule,
    NgIconsModule.withIcons({ featherAirplay, bootstrapLink45deg }),
    MatDialogModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatSlideToggleModule,
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
     MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    DragDropModule
  ],
  providers: [
    HttpClientModule,
    SnackBarMessageService,
    provideNgxMask(),
    provideAnimations(),
    provideHttpClient(),
    importProvidersFrom(MatNativeDateModule),
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
