<div class="containerComponente">
    <div class="containerHeader">
        <h1 class="heading">Selecionar Status</h1>
    </div>
    <div class="container">
        <select [(ngModel)]="valueStatus" class="filterInput">
            <option *ngFor="let status of statusOperator" [ngValue]="status">
                {{ status.statusName }}
            </option>
        </select>
        <div class="button-container">
            <button class="button" (click)="changeStatusProposal()">Confirmar</button>
        </div> 
    </div>
</div>