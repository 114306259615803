import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SimulationModuleService } from 'src/app/services/simulation-module.service';
import { AppSetting } from '../../entities/utils/appsettings';
import { Location } from '@angular/common'

@Component({
  selector: 'app-are-your-want-to-delete',
  templateUrl: './are-your-want-to-delete.component.html',
  styleUrls: ['./are-your-want-to-delete.component.css']
})
export class AreYourWantToDeleteComponent {

  constructor(
    public simulation_service: SimulationModuleService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AreYourWantToDeleteComponent>,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public key: any,
  ) {}


  public appSetting = new AppSetting()

  cancelProposalLoading: boolean = false

  handleCancelProposal() {
    console.log(this.key)
    let body = {
      "debtKey": this.key
    }

    this.cancelProposalLoading = true

    this.simulation_service.cancelProposal(body).subscribe( (scc:any) => {
      console.log(scc)
      this.reloadPage()
      this.cancelProposalLoading = false
    },err => {
      console.log(err)
      this.cancelProposalLoading = false
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  reloadPage() {
    window.location.reload();
  }
}
