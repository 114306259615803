import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FgtsConsultComponent } from './fgts-consult/fgts-consult.component';
import { FgtsMonitoringComponent } from './fgts-monitoring/fgts-monitoring.component';
import { ErrorComponent } from './error/error.component';
import { ValidateSessionComponent } from './validate-session/validate-session.component';
import { HistoricComponent } from './historic/historic.component';
import { CustomerIndicationComponent } from './customer-indication/customer-indication.component';

const routes: Routes = [
  {
    path: 'historic',
    component: HistoricComponent
  },
  {
    path: 'finantoPayFgtsCommission',
    component: CustomerIndicationComponent
  },
  {
    path: 'monitoring',
    component: FgtsMonitoringComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'consult',
    component: FgtsConsultComponent
  },
  {
    path: ':id/:rota',
    component: ValidateSessionComponent
  },
  {
    path: ':id',
    component: ValidateSessionComponent
  },
  {
    path: '',
    redirectTo: '/consult', // Redireciona para 'consult' por padrão
    pathMatch: 'full'
  },
  {
    path: '**',
    component: ValidateSessionComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
