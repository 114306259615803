<div class="container">
  <div class="header">
    <form (ngSubmit)="filter()" #filtersForm="ngForm" class="filters-container">
      <div class="form-group">
        <label for="cpf">CPF:</label>
        <input
          type="text"
          id="cpf"
          name="cpf"
          mask="000.000.000-00"
          [(ngModel)]="filters.individualDocumentNumber"
          required
        />
      </div>
      <div class="form-group">
        <label for="dataInicio">Data Inícial:</label>
        <input
          type="date"
          id="dataInicio"
          name="dataInicio"
          [(ngModel)]="filters.dateBegin"
          required
        />
      </div>
      <div class="form-group">
        <label for="dataFim">Data Final:</label>
        <input
          type="date"
          id="dataFim"
          name="dataFim"
          [(ngModel)]="filters.dateEnd"
          required
        />
      </div>
      <div class="form-group" style="flex: 0">
        <label class="labelCheck">
          <input
            type="checkbox"
            name="consults"
            [(ngModel)]="filters.simulation"
          />
          <span>Consultas</span>
        </label>
      </div>
      <div class="form-group" style="flex: 0">
        <label class="labelCheck">
          <input
            type="checkbox"
            name="propostas"
            [(ngModel)]="filters.proposal"
          />
          <span>Propostas</span>
        </label>
      </div>
      <button class="buttonSearch" type="submit"  
        title="Buscar">
        <i class="material-icons">search</i>
      </button>
    </form>
  </div>
  <div class="section">
    <mat-accordion *ngIf="data">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Dados Cadastrais </mat-panel-title>
          <mat-panel-description *ngIf="data && data.customer == false" style="color: red;">Não é um cliente</mat-panel-description>
        </mat-expansion-panel-header>

        <div class="panel-content" *ngIf="data && data.customer == true">
          <p>
            <strong>CPF:</strong>
            {{
              data.dataCustomer?.individual_document_number
                ? format.formatCPF(
                    data.dataCustomer?.individual_document_number
                  )
                : "Não informado"
            }}
          </p>
          <p>
            <strong>Nome:</strong>
            {{ data.dataCustomer?.name ?? "Não informado" }}
          </p>
          <p>
            <strong>Nome da Mãe:</strong>
            {{ data.dataCustomer?.mother_name ?? "Não informado" }}
          </p>
          <p>
            <strong>Data de Nascimento:</strong>
            {{
              data.dataCustomer?.birth_date
                ? format.formatAmericanDateForDatePTBR(
                    data.dataCustomer?.birth_date
                  )
                : "Não informado"
            }}
          </p>
          <p>
            <strong>Profissão:</strong>
            {{ data.dataCustomer?.profession ?? "Não informado" }}
          </p>
          <p>
            <strong>Nacionalidade:</strong>
            {{ data.dataCustomer?.nationality ?? "Não informado" }}
          </p>
          <p>
            <strong>Estado Civil:</strong>
            {{
              data.dataCustomer?.marital_status
                ? StatusMatrimonialtraductor(data.dataCustomer?.marital_status)
                : "Não informado"
            }}
          </p>
          <p>
            <strong>Email:</strong>
            {{ data.dataCustomer?.email ?? "Não informado" }}
          </p>
          <p>
            <strong>Telefone:</strong> +{{
              data.dataCustomer?.phone?.country_code ?? "N/A"
            }}
            ({{ data.dataCustomer?.phone?.area_code ?? "N/A" }})
            {{ data.dataCustomer?.phone?.number ?? "Não informado" }}
          </p>
          <p>
            <strong>Endereço:</strong>
            {{ data.dataCustomer?.address?.street ?? "N/A" }},
            {{ data.dataCustomer?.address?.number ?? "N/A" }},
            {{ data.dataCustomer?.address?.neighborhood ?? "N/A" }},
            {{ data.dataCustomer?.address?.city ?? "N/A" }} -
            {{ data.dataCustomer?.address?.state ?? "N/A" }},
            {{ data.dataCustomer?.address?.postal_code ?? "N/A" }}
          </p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion *ngIf="groupedHistoric" class="accordion-container" [multi]="true">
      <mat-expansion-panel *ngFor="let monthYear of groupedHistoric | keyvalue">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ formatMonthYear(monthYear.key) }}
          </mat-panel-title>
        </mat-expansion-panel-header>
    
        <div class="card-row">
          <div class="card" *ngFor="let item of monthYear.value">
            <div class="CardContainer">
              <div class="CardHeader" 
                [ngStyle]="{'background-color': item.iconQuery === 'search' ? '#4ECD72' : '#2D5CB0'}">
                <mat-icon [matTooltip]="item.iconQuery === 'search' ? 'Consulta' : 'Proposta'">
                  {{ item.iconQuery }}
                </mat-icon>
                <strong>{{
                  item.valueSimulation
                    | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR"
                }}</strong>
                <ng-container *ngIf="item.selfQuery; else languageIcon">
                  <span
                    [matTooltip]="item.iconQuery === 'search' ? 'Minha consulta' : 'Minha proposta'"
                    class="material-symbols-outlined"
                    >person</span
                  >
                </ng-container>
                <ng-template #languageIcon>
                  <span
                    [matTooltip]="item.iconQuery === 'search' ? 'Consulta de outro vendedor' : 'Proposta de outro vendedor'"
                    class="material-symbols-outlined"
                    >language</span
                  >
                </ng-template>
              </div>
              <div class="CardInfos">
                <p>
                  <strong>Data:</strong>
                  {{ format.convertDateIsoInDateAndHours(item.creationDateTime) }}
                </p>
                <p>
                  <strong>Status:</strong>
                  {{ proposalStatus.traduzirStatus(item.status) }}
                </p>
                <p>
                  <strong>Tipo:</strong>
                  {{ item.typeName ? item.typeName : '---' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
