<div class="container">
  <h1>Indicações de Clientes</h1>

  <div *ngIf="customerIndication.length === 0" class="no-content">
    <p>Não há Indicações para Serem Pagas.</p>
  </div>

  <div *ngFor="let customer of customerIndication" class="card">
    <h2>{{ customer.name }}</h2>
    <p><strong>CPF:</strong> {{ customer.documentNumber }}</p>
    <p><strong>Valor a receber:</strong> R$ {{ customer.amountToReceive }}</p>

    <ng-container *ngIf="customer.isPix; else bankAccount">
      <p><strong>Chave PIX:</strong> {{ customer.disbursementBankAccount.pix_key }}</p>
    </ng-container>

    <ng-template #bankAccount>
      <p><strong>Conta Bancária:</strong> {{ customer.disbursementBankAccount.account_number }}-{{ customer.disbursementBankAccount.account_digit }} (Agência: {{ customer.disbursementBankAccount.branch_number }})</p>
    </ng-template>

    <button class="approve-btn" (click)="updatePaymentStatus(customer.documentNumber)">Aprovar Pagamento</button>
  </div>
</div>
