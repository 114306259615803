<div class="container">
    <div class="box">
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.cpf }}:</p></strong>
            <p>{{ format.formatCPF(borrower.individualDocumentNumber) }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.name }}:</p></strong>
            <p>{{borrower.name}}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.birthDate }}:</p></strong>
            <p>{{ showDate }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.nationality }}:</p></strong>
            <p>{{ borrower.nationality }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.maritalStatus }}:</p></strong>
            <p>{{ StatusMatrimonialtraductor(borrower.maritalStatus) }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.profession }}:</p></strong>
            <p>{{ borrower.profession }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.motherName }}:</p></strong>
            <p>{{borrower.motherName}}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.phone }}:</p></strong>
            <div style="display: flex;">
                <p>{{format.formatPhoneWithOneParameter(borrower.completePhone)}}</p>
            </div>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.email }}:</p></strong>
            <p>{{borrower.email}}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.addressZipCode }}:</p></strong>
            <p>{{ borrower.address.postalCode }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.street }}</p></strong>
            <p>{{ borrower.address.street }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.number }}:</p></strong>
            <p>{{ borrower.address.number }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.addressComplement }}:</p></strong>
            <p>{{ borrower.address.complement }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.neighborhood }}</p></strong>
            <p>{{ borrower.address.neighborhood }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.city }}:</p></strong>
            <p>{{ borrower.address.city }}</p>
        </div>
        <div class="boxInfo">
            <strong><p>{{ appSetting.LG_PTBR.state }}:</p></strong>
            <p>{{ borrower.address.state }}</p>
        </div>
    </div>
    <div class="box">
        <div class="boxInfo">
            <p><strong>{{ appSetting.LG_PTBR.AmountToBeReceived }}:</strong></p>
            <p>{{_data.value | currency:'BRL'}}</p>
        </div>
        <div class="boxInfo">
            <p><strong>{{ appSetting.LG_PTBR.installments }}:</strong></p>
            <p>{{installments }}</p>
        </div>
        <div class="boxInfo">
            <p><strong>{{ appSetting.LG_PTBR.issuingDate }}:</strong></p>
            <p>{{ format.convertDateIsoInDate(issuing) }}</p>
        </div>
        <div class="boxInfo">
            <p><strong>{{ appSetting.LG_PTBR.valueofRate }}:</strong></p>
            <p>{{ feeAmount | currency:'BRL'}} </p>
        </div>
        <div class="boxInfo">
            <p><strong>{{ appSetting.LG_PTBR.totalValueFinanced }}:</strong></p>
            <p>{{principalAmortizationAmountSum | currency:'BRL'}}</p>
        </div>
        <div class="boxInfo">
            <p><strong>Identificador Externo:</strong></p>
            <p>{{ aditionalData.externalId }} </p>
        </div>
        <div class="boxInfo" *ngIf="pf == true && disbursementBankAccount.bankCode">
            <p><strong>{{ appSetting.LG_PTBR.bank }}:</strong></p>
            <p>{{ bankInformations.nome }} </p>
        </div>
        <div class="boxInfo" *ngIf="pf == true && disbursementBankAccount.branchNumber">
            <p><strong>{{ appSetting.LG_PTBR.branchNumber }}:</strong></p>
            <p>{{ disbursementBankAccount.branchNumber }} </p>
        </div>
        <div class="boxInfo" *ngIf="pf == true && disbursementBankAccount.accountNumber">
            <p><strong>{{ appSetting.LG_PTBR.accountNumber }}:</strong></p>
            <p>{{ disbursementBankAccount.accountNumber }} </p>
        </div>
        <div class="boxInfo" *ngIf="pf == true && disbursementBankAccount.accountDigit">
            <p><strong>{{ appSetting.LG_PTBR.AccountDigit }}:</strong></p>
            <p>{{ disbursementBankAccount.accountDigit }} </p>
        </div>
        <div class="boxInfo" *ngIf="pf == true && disbursementBankAccount.pixKey">
            <p><strong>{{ appSetting.LG_PTBR.pixKey }}:</strong></p>
            <p>{{ disbursementBankAccount.pixKey }} </p>
        </div>
    </div>
    <div class="divTable">
        <app-table-installments [dataTable]="installmentsValue"></app-table-installments>
    </div>
</div>