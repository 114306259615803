<div style="width: 100%; height: 100%; display: flex; align-items: center; align-items: center; justify-content: center;"
    *ngIf="this.data.status == 'failed'">
    <div class="container"
        style="display: flex; align-items: center; justify-content: start; flex-direction: column; gap: 30px;">
        <h2 style="margin: 2%">{{ appSetting.LG_PTBR.problemDescription }}</h2>
        <div class="input-container">

            <div class="problemText" style="width: 100%;">
                <p><span class="info-label"><b> {{ appSetting.LG_PTBR.problemDescription }}:</b> </span> <br><span
                        class="info-value">{{ this.data.data.errorDescription}} </span></p>
                <p><span class="info-label"><b>{{ appSetting.LG_PTBR.clientActions }}: </b></span> <br><span
                        class="info-value">{{ this.data.data.errorActionClient }} </span></p>
            </div>
        </div>
    </div>
</div>

<mat-stepper [class.align]="align" class="conatinerRegister" [selectedIndex]="setStepIndex"
    [stepControl]="firstFormGroup" [linear]="isLinear" *ngIf="this.data.status != 'failed'" #stepper>
    <mat-step [stepControl]="firstFormGroup" label="Simulação" class="stepperFirst">
        <div class="containerSimulation">
            <div>
                <div class="containerSearch">
                    <div class="cardSearch">
                        <div class="inputContainer">
                            <p>R$</p>
                            <input type="text" class="inputSearch" placeholder="Digite o valor a ser simulado..."
                                [(ngModel)]="simulation_value" [disabled]="data.data.valueSimulation == 0.0" required>
                        </div>
                        <button class="buttonConsut" (click)="findValue()" *ngIf="data.data.valueSimulation != 0.0">{{
                            appSetting.LG_PTBR.simulate }}</button>
                    </div>
                    <div class="cardSearch cardSearchwidthMax">
                        <p>{{ appSetting.LG_PTBR.TotalSimulatedValue }}:</p>
                        <p>{{ valueTotalSimuleted }}</p>
                        <div class="divValueSimulation">
                            <p [style.color]="data.data.valueSimulation != 0.0 ? 'black' : 'red'"> {{
                                data.data.valueSimulation != 0.0 ? format.formatValue(valueTotalSimulated) : "Saldo
                                Insuficiente" }}</p>
                            <div title="Simular com o valor total" *ngIf="data.data.valueSimulation != 0.0"
                                (click)="this.simulation_value = formatarValorOnlyValue(valueTotalSimulated)">
                                <span class="info-value">
                                    <mat-icon>library_books</mat-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="containerTableCardsSimulation" *ngIf="!confirmationFirst && pf == true && !isMobileView">
                    <table class="tableCardSimulation">
                        <thead>
                            <tr>

                              <th>
                                <div class="containerNameTable" *ngIf="productCodeUltimate.length >= 1">
                                    <div>
                                        <p style="font-weight: normal;">Tabela <span
                                                style="font-weight: bolder; color: rgb(26, 0, 128);">Ultimate</span></p>

                                        <!-- <p class="PSeguro" *ngIf="data.data.acceptTac == false">
                                            <span style="font-weight: normal;">seguro</span>
                                            {{productCodeUltimate[0].seguro}}%</p>

                                        <p class="PSeguro" *ngIf="data.data.acceptTac == true"><span
                                                style="font-weight: normal;"><strong>TAC</strong></span>
                                            {{productCodeUltimate[0].seguro}}%</p> -->
                                    </div>
                                </div>
                              </th>
                              <th>
                                <div class="containerNameTable" *ngIf="productCodePremium.length >= 1">
                                    <div>
                                        <p style="font-weight: normal;">Tabela <span
                                                style="font-weight: bolder; color: rgb(26, 0, 128);">Premium</span></p>

                                        <!-- <p class="PSeguro" *ngIf="data.data.acceptTac == false">
                                            <span style="font-weight: normal;">seguro</span>
                                            {{productCodePremium[0].seguro}}%</p>

                                        <p class="PSeguro" *ngIf="data.data.acceptTac == true"><span
                                                style="font-weight: normal;"><strong>TAC</strong></span>
                                            {{productCodePremium[0].seguro}}%</p> -->
                                    </div>
                                </div>
                            </th>
                            <th>
                              <div class="containerNameTable" *ngIf="productCodeUltra.length >= 1">
                                  <div>
                                      <p style="font-weight: normal;">Tabela <span
                                              style="font-weight: bolder; color: rgb(26, 0, 128);">Ultra</span></p>

                                      <!-- <p class="PSeguro" *ngIf="data.data.acceptTac == false">
                                          <span style="font-weight: normal;">seguro</span>
                                          {{productCodeUltra[0].seguro}}%</p>

                                      <p class="PSeguro" *ngIf="data.data.acceptTac == true"><span
                                              style="font-weight: normal;"><strong>TAC</strong></span>
                                          {{productCodeUltra[0].seguro}}%</p> -->
                                  </div>
                              </div>
                          </th>
                          <th>
                            <div class="containerNameTable" *ngIf="productCodeEssential.length >= 1">
                                <div>
                                    <p style="font-weight: normal;">Tabela <span
                                            style="font-weight: bolder; color: rgb(26, 0, 128);">Essential</span></p>

                                    <!-- <p class="PSeguro" *ngIf="data.data.acceptTac == false">
                                        <span style="font-weight: normal;">seguro</span>
                                        {{productCodeEssential[0].seguro}}%</p>

                                    <p class="PSeguro" *ngIf="data.data.acceptTac == true"><span
                                            style="font-weight: normal;"><strong>TAC</strong></span>
                                        {{productCodeEssential[0].seguro}}%</p> -->
                                </div>
                            </div>
                          </th>

                          <th>
                            <div class="containerNameTable" *ngIf="_basicProducts.length >= 1">
                                <div>
                                    <p style="font-weight: normal;">Tabela <span
                                            style="font-weight: bolder; color: rgb(26, 0, 128);">Basic</span></p>

                                    <!-- <p class="PSeguro" *ngIf="data.data.acceptTac == false">
                                        <span style="font-weight: normal;">seguro</span>
                                        {{_basicProducts[0].seguro}}%</p>

                                    <p class="PSeguro" *ngIf="data.data.acceptTac == true"><span
                                            style="font-weight: normal;"><strong>TAC</strong></span>
                                        {{_basicProducts[0].seguro}}%</p> -->
                                </div>
                            </div>
                          </th>
                                <!-- novo -->
                                <th>
                                    <div class="containerNameTable" *ngIf="safeProducts.length >= 1">
                                        <div>
                                            <p style="font-weight: normal;">Tabela <span
                                                    style="font-weight: bolder; color: rgb(26, 0, 128);">Master</span></p>

                                            <!-- <p class="PSeguro" *ngIf="data.data.acceptTac == false">
                                                <span style="font-weight: normal;">seguro</span>
                                                {{safeProducts[0].seguro}}%</p>

                                            <p class="PSeguro" *ngIf="data.data.acceptTac == true"><span
                                                    style="font-weight: normal;"><strong>TAC</strong></span>
                                                {{safeProducts[0].seguro}}%</p> -->
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div class="containerNameTable" *ngIf="basicProducts.length >= 1">
                                        <div>
                                            <p style="font-weight: normal;">Tabela <span
                                                    style="font-weight: bolder; color: rgb(0, 157, 255);">Black</span>
                                            </p>

                                            <!-- <p class="PSeguro" *ngIf="data.data.acceptTac == false"><span
                                                    style="font-weight: normal;">seguro</span>
                                                {{basicProducts[0].seguro}}%</p>
                                            <p class="PSeguro" *ngIf="data.data.acceptTac == true"><span
                                                    style="font-weight: normal;"><strong>TAC</strong></span>
                                                {{basicProducts[0].seguro}}%</p> -->

                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div class="containerNameTable" *ngIf="smartProducts.length >= 1">
                                        <div>
                                            <p style="font-weight: normal;">Tabela <span
                                                    style="font-weight: bolder; color: rgba(187, 132, 3, 0.612)">Gold</span>
                                            </p>

                                            <!-- <p class="PSeguro" *ngIf="data.data.acceptTac == false"><span
                                                    style="font-weight: normal;">seguro</span>
                                                {{smartProducts[0].seguro}}%</p>
                                            <p class="PSeguro" *ngIf="data.data.acceptTac == true"><span
                                                    style="font-weight: normal;"><strong>TAC</strong></span>
                                                {{smartProducts[0].seguro}}%</p> -->

                                        </div>
                                    </div>
                                </th>

                                <th>
                                    <div class="containerNameTable" *ngIf="greenProducts.length >= 1">
                                        <div>
                                            <p style="font-weight: normal;">Tabela <span
                                                    style="font-weight: bolder; color: green">Green</span></p>

                                            <!-- <p class="PSeguro" *ngIf="data.data.acceptTac == false"><span
                                                    style="font-weight: normal;">seguro</span>
                                                {{greenProducts[0].seguro}}%</p>
                                            <p class="PSeguro" *ngIf="data.data.acceptTac == true"><span
                                                    style="font-weight: normal;"><strong>TAC</strong></span>
                                                {{greenProducts[0].seguro}}%</p> -->

                                        </div>
                                    </div>
                                </th>

                                <th>
                                    <div class="containerNameTable" *ngIf="liteProducts.length >= 1">
                                        <div>
                                            <p style="font-weight: normal;">Tabela <span
                                                    style="font-weight: bolder; color: black">Lite</span></p>

                                            <!-- <p class="PSeguro" *ngIf="data.data.acceptTac == false"><span
                                                    style="font-weight: normal;">seguro</span>
                                                {{liteProducts[0].seguro}}%</p>
                                            <p class="PSeguro" *ngIf="data.data.acceptTac == true"><span
                                                    style="font-weight: normal;"><strong>TAC</strong></span>
                                                {{liteProducts[0].seguro}}%</p> -->

                                        </div>
                                    </div>
                                </th>


                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td [ngStyle]="{'display': group.type === 'Lite' ? 'block' : 'table-cell'}"
                                    *ngFor="let group of productGroups">
                                    <div *ngFor="let product of group.products" class="containerCardSimulation">
                                        <div class="containerCardSimulation"
                                            [ngStyle]="{'opacity': product.enable ? '1' : '0.3'}">
                                            <div class="cardSimulationName">
                                                <input type="checkbox" class="checkBoxCardSimulation"
                                                    [checked]="product.name === valueCardSimulationChoiced"
                                                    (change)="changeCardSimulationValue(product.name)"
                                                    [disabled]="product.enable == false">
                                                <p style="font-weight: bolder; font-size: 14px;">{{ product.name }}</p>
                                                <span style="display: flex">
                                                    <p style="font-size: 8px;">TX:</p>
                                                    <strong>
                                                        <p style="font-size: 8px;">{{ product.rate * 100 |
                                                            number:'1.2-2' }}%</p>
                                                    </strong>
                                                </span>
                                            </div>
                                            <div class="cardSimulationValue">
                                                <p>R$ {{ product.valueSimulation | number:'1.2-2' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="containerTableCardsSimulation" *ngIf="!confirmationFirst && pf == true && isMobileView">
                    <div class="containerCardsGrid">
                        <div *ngFor="let group of productGroups">
                            <div *ngFor="let product of group.products" class="cardSimulationItem"
                                [ngStyle]="{'opacity': product.enable ? '1' : '0.3'}">
                                <input type="radio" id="{{product.name}}" name="product"
                                    [checked]="product.name === valueCardSimulationChoiced"
                                    (change)="changeCardSimulationValue(product.name)"
                                    [disabled]="product.enable == false">
                                <label for="{{product.name}}">
                                    <div class="cardSimulationContent">
                                        <p class="cardSimulationTitle">{{ product.name }}</p>
                                        <p class="cardSimulationRate">TX: <strong>{{ product.rate * 100 | number:'1.2-2'
                                                }}%</strong></p>
                                        <p class="cardSimulationValue">R$ {{ product.valueSimulation | number:'1.2-2' }}
                                        </p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-progress-bar *ngIf="submit_click" mode="indeterminate"></mat-progress-bar>
                <div>
                    <div *ngIf="confirmationFirst">
                        <div class="fgtsValuesContainer">
                            <div class="valuesFgts">
                                <div class="fgtsAttributeValue">
                                    <p>{{ appSetting.LG_PTBR.valueReceived }}:</p>
                                    <p>{{ amountToReceive | currency:'BRL'}}</p>
                                </div>
                                <div class="fgtsAttributeValue">
                                    <p>{{ appSetting.LG_PTBR.installments }}:</p>
                                    <p>{{installments}}</p>
                                </div>
                                <div class="fgtsAttributeValue">
                                    <p>{{ appSetting.LG_PTBR.issuing }}:</p>
                                    <p>{{ format.convertDateIsoInDate(issuing) }}</p>
                                </div>
                                <div class="fgtsAttributeValue">
                                    <p>{{ appSetting.LG_PTBR.tarifesAndTax }}:</p>
                                    <p>{{ feeAmount | currency:'BRL'}}</p>
                                </div>
                                <div *ngIf="data.data.acceptTac == false">
                                    <div class="fgtsAttributeValue" *ngIf="product">
                                        <p>{{ appSetting.LG_PTBR.securityPrestamist }}:</p>
                                        <p>{{ principalAmortizationAmountSum * (product.seguro / 100) | currency:'BRL'
                                            }}</p>
                                    </div>
                                </div>
                                <div class="fgtsAttributeValue">
                                    <p>{{ appSetting.LG_PTBR.totalValueFinanced }}:</p>
                                    <p>{{ principalAmortizationAmountSum | currency:'BRL'}}</p>
                                </div>
                            </div>
                            <div class="divTable">
                                <app-table-installments [dataTable]="return_list"></app-table-installments>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="divButtonNext2step" *ngIf="confirmationFirst">
                    <button class="buttonNext2step" mat-button (click)="confirmationFirstFalse()">{{
                        appSetting.LG_PTBR.back }}</button>
                    <button class="buttonNext2step" mat-button matStepperNext>{{ appSetting.LG_PTBR.next }}</button>
                </div>
            </div>
        </div>
    </mat-step>

    <form [formGroup]="secondFormGroup">
        <mat-step [stepControl]="secondFormGroup" label="Cadastro" *ngIf="confirmationFirst">
            <div class="containerForm">
                <div class="FormAmount">
                    <app-personal-information [_data]="data.data" [adicionData]="adictionData" [borrower]="borrower"
                        [aditionalData]="aditionalData" [opitionsProposal]="opitionsProposal"
                        [disbursementBankAccount]="disbursementBankAccount"
                        [openTable]="openTable"></app-personal-information>
                    <div class="AmountButtons">
                        <button mat-button matStepperPrevious
                            style="display: flex; align-items: center; justify-content: center;">{{
                            appSetting.LG_PTBR.goBack }}</button>
                        <button mat-button matStepperNext (click)="callFormGruop()">{{ appSetting.LG_PTBR.next
                            }}</button>
                    </div>
                </div>
            </div>
        </mat-step>
    </form>
    <mat-step label="Confirmação" *ngIf="confirmationSecond">
        <div class="containerResume">
            <app-resume [borrower]="borrower" [aditionalData]="aditionalData"
                [disbursementBankAccount]="disbursementBankAccount" class="resumesScreen"
                [_data]="{'data': data.data, 'resume': resume, 'value': amountToReceive, 'feeAmount': feeAmount}"></app-resume>
            <div class="buttons">
                <button mat-button matStepperPrevious>{{ appSetting.LG_PTBR.cancel }}</button>
                <button class="buttonConsut" (click)="createProposal()"
                    *ngIf="!loadingProposal && (data.data.valueSimulation != 0.0 || loadingProposal == false)">
                    Finalizar
                </button>
                <mat-spinner style="width: 20px;" *ngIf="loadingProposal"></mat-spinner>
            </div>
        </div>
    </mat-step>
</mat-stepper>
