import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProposalStatus {
  status = [
    { 
      valueEnglish: 'All', 
      valuePortugues: 'Todos' 
    },
    { 
        valueEnglish: 'waiting_signature', 
        valuePortugues: 'Aguardando Assinatura' 
    },
    { 
        valueEnglish: 'signature_finished', 
        valuePortugues: 'Assinatura Concluída' 
    },
    { 
        valueEnglish: 'signature_received', 
        valuePortugues: 'Assinatura recebida' 
    },
    { 
        valueEnglish: 'canceled', 
        valuePortugues: 'Cancelado' 
    },
    { 
        valueEnglish: 'canceled_permanently', 
        valuePortugues: 'Cancelado Permanentemente' 
    },
    { 
        valueEnglish: 'invalid_account', 
        valuePortugues: 'Conta inválida' 
    },
    { 
      valueEnglish: 'failed', 
      valuePortugues: 'Falhou' 
    },
    { 
      valueEnglish: 'disbursed', 
      valuePortugues: 'Proposta paga' 
    },
    { 
      valueEnglish: 'processing_payment', 
      valuePortugues: 'Processando pagamento' 
    },
  ]

    traduzirStatus(status: string) {
        switch (status) {
          case "All":
            return "Todos";

          case "failed":
            return "Falhou";
    
          case "signature_received":
            return "Assinatura recebida";
      
          case "success":
            return "Sucesso";
      
          case "waiting_signature":
            return "Aguardando Assinatura";
      
          case "signature_finished":
            return "Assinatura Concluída";
      
          case "processing_payment":
            return "Processando pagamento";

          case "settled":
            return "Quitado";
    
          case "disbursed":
            return "Proposta paga";
      
          case "canceled":
            return "Cancelado";
      
          case "invalid_account":
            return "Conta inválida";
      
          case "canceled_permanently":
            return "Cancelado Permanentemente";
      
          case "NA":
            return "Não Aplicável";
      
          default:
            return status;
        }
      }
}