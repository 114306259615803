import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KanbanService } from 'src/app/components/kanban/kanban.service';
import { ProposalsList } from 'src/app/services/proposalsList.service';
import { SimulationModuleService } from 'src/app/services/simulation-module.service';

@Component({
  selector: 'app-change-status-fila',
  templateUrl: './change-status-fila.component.html',
  styleUrls: ['./change-status-fila.component.css']
})
export class ChangeStatusFilaComponent {

  userId: string | null = localStorage.getItem("userId")
  valueStatus: any 
  statusOperator: any 

  constructor(
    private proposalsList: ProposalsList,
    private kanbanService: KanbanService,
    public simulation_service: SimulationModuleService,
    public dialogRef: MatDialogRef<ChangeStatusFilaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

  ngOnInit(){
    this.simulation_service.FindOperator().subscribe( (result: any) => {
        this.statusOperator = result
      })
  }

  changeStatusProposal() {
    let body = {
      "key": this.data.key,
      "userId": this.userId,
      "statusCode": this.valueStatus.statusCode,
    }

    console.log(body)

    this.simulation_service.UpdateStatusAvailableBalance(body).subscribe(async (result: any) => {
      console.log(result)
      this.dialogRef.close()
      await this.proposalsList.reloadProposals(this.userId, this.data.typecode)
      this.kanbanService.filterProposalForStatus()
    })
  }
}
