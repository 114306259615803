import { Component,Injectable,Inject, ViewChild, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms'
import { SimulationModuleService } from '../services/simulation-module.service';
import { Financial } from '../entities/utils/financial';
import { Borrower } from '../entities/utils/borrower';
import { AditionalData } from '../entities/utils/aditionalData';
import { AppSetting } from '../entities/utils/appsettings';
import { SnackBarMessageService } from '../services/snack-bar.service';
import { MatStepper } from '@angular/material/stepper';
import { CorService } from '../services/color.service';
import { disbursementBankAccount } from '../entities/utils/disbursementBankAccount';
import * as moment from 'moment';
import { opitionsProposal } from '../entities/utils/opitionsProposal';
import { Format } from '../services/format.service';


@Component({
selector: 'app-register',
templateUrl: './register.component.html',
styleUrls: ['./register.component.css'],
providers: [
  SnackBarMessageService
  ]
})
export class RegisterComponent {
product: any;

constructor(
  public format: Format,
  public simulation_service: SimulationModuleService,
  private _formBuilder: FormBuilder,
  public simulationsvc: SimulationModuleService,
  public dialogRef: MatDialogRef<RegisterComponent>,
  public snackBarMessageService: SnackBarMessageService,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private corService: CorService
  ){
    this.resume = null
    this.colorPrimary = this.colorPrimary === '' ? '#4ECD72' : this.colorPrimary;
    this.colorSecond = this.colorSecond === '' ? '#FFF' : this.colorSecond;
  }

  public appSetting = new AppSetting()
  public financial = new Financial();
  public adictionData: any
  textinput: boolean = false
  confirmationFirst = false
  confirmationSecond = false
  releasedValue = 0
  installments = 0
  feeAmount = 0
  userId: string | null = localStorage.getItem("userId")
  items: any
  key: string = ""
  preFixedAmountSum = 0
  iduser: any;
  pf: any;

  public borrower = new Borrower();
  public aditionalData = new AditionalData()
  public disbursementBankAccount = new disbursementBankAccount();
  public opitionsProposal = new opitionsProposal();

  safeProducts: any[] = []
  basicProducts: any[] = []
  smartProducts: any[] = []
  liteProducts: any[] = []
  productGroups: any[] = []

  valueCardSimulationChoiced: string | null = null;
  valueTotalSimulated: any

  tablesSimulationProduct = this.data.data.tables


  amountToReceive = 0
  issuing: any = new Date()

  @ViewChild('stepper') stepper!: MatStepper;

  ngOnInit() {
    console.log(this.data)
    console.log(this.tablesSimulationProduct)
    this.checkScreenWidth();
    this.iduser = localStorage.getItem('userId')!
    this.simulation_service.getUser(this.iduser).subscribe( (result: any) => {
      this.pf = result.salesPF
    })
    this.key = this.data.data.key
    this.totalAmountSimulation = this.data.data.assignmentAmount
    this.valueTotalSimulated = this.data.data.valueSimulation
    this.valueSimulation = this.data.data.valueSimulation
    console.log(this.valueSimulation, 'aquii')

    if(this.tablesSimulationProduct) {
      this.organizedCardSimulation()
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }


  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });

  isLinear = false;
  isMobileView: boolean = false;
  simulation_value: any;
  AllowRegistry: any;
  colorPrimary = this.corService.colorPrimary
  colorSecond = this.corService.colorSecond
  mainFontColor = this.corService.mainFontColor
  simulatioinColor = this.corService.simulatioinColor
  openTable: any;
  totalAmount= 0;
  totalAmountSimulation = 0;
  return_list: any;
  newItemEvent: any;
  submit_click = false;
  installmentList = []
  valueSimulation = 0
  principalAmortizationAmountSum = 0
  resume: any
  confirmationModule = true;
  amountToReceiveLoadin = false;
  controllerInformationTransferCheck = true
  align: boolean = false
  contconthe: boolean = false
  dataPersonche: boolean = false
  loadingProposal: boolean = false

  checkScreenWidth() {
    this.isMobileView = window.innerWidth <= 768;
  }

  findValue() {
    console.log(this.simulation_value)
    if( this.valideValue(this.simulation_value) == true){
      this.align = false
      this.contconthe = false
      this.dataPersonche = false

      this.key = this.data.data.key
      this.simulation(this.userId!,this.data.data.key,this.simulation_value)
    }else{
      console.log("valor nao disponivel")
    }
  }

  organizedCardSimulation() {
    this.safeProducts = this.tablesSimulationProduct.filter((p: any) => p.name.includes('SAFE'));
    this.basicProducts = this.tablesSimulationProduct.filter((p: any) => p.name.includes('BASIC'));
    this.smartProducts = this.tablesSimulationProduct.filter((p: any) => p.name.includes('SMART'));
    this.liteProducts = this.tablesSimulationProduct.filter((p: any) => p.name.includes('LITE'));

    this.productGroups = [
      { type: 'Safe', products: this.safeProducts },
      { type: 'Basic', products: this.basicProducts },
      { type: 'Smart', products: this.smartProducts },
      { type: 'Lite', products: this.liteProducts }
    ];

  console.log(this.safeProducts)

  this.product = this.tablesSimulationProduct.find(p => p.selected === true);
  if (this.product) {
      this.valueCardSimulationChoiced = this.product.name;
      this.valueTotalSimulated = this.product.valueSimulation;
      this.simulation_value = this.product.valueSimulation;
    }
  }

  callFormGruop() {
    if (
        this.borrower.individualDocumentNumber &&
        this.borrower.name &&
        this.borrower.motherName &&
        this.borrower.completePhone &&
        this.borrower.maritalStatus &&
        this.borrower.address.city &&
        this.borrower.birthDate &&
        this.borrower.address.postalCode &&
        this.borrower.address.state &&
        this.borrower.address.street &&
        this.borrower.address.neighborhood &&
        this.borrower.address.number
    ) {
      if (this.pf == true) {
        this.verifyDataBankTransition()
      } else {
        this.confirmationSecond = true;
          setTimeout(() => {
            this.stepper.next();
          }, 300);
      }
      } else {
        this.snackBarMessageService.error("Verifique se você preencheu todas as informações corretamente");
        this.confirmationSecond = false;
      }

  }

  verifyDataBankTransition() {
    if (this.opitionsProposal.changeMetodPayment == "PIX") {
      this.disbursementBankAccount.accountDigit = undefined
      this.disbursementBankAccount.accountNumber = undefined
      this.disbursementBankAccount.bankCode = undefined
      this.disbursementBankAccount.branchNumber = undefined
      this.disbursementBankAccount.pixTransferType = "key"
        if(
          this.disbursementBankAccount.pixTransferType &&
          this.disbursementBankAccount.pixKey
        ) {
          this.confirmationSecond = true;
            setTimeout(() => {
              this.stepper.next();
            }, 300);
        } else {
        this.snackBarMessageService.error("Verifique se você preencheu todas as informações do pix corretamente");
        }
    }
    if (this.opitionsProposal.changeMetodPayment == "TED") {
      this.disbursementBankAccount.pixTransferType = undefined
      this.disbursementBankAccount.pixKey = undefined
      if(
        this.disbursementBankAccount.accountDigit &&
        this.disbursementBankAccount.accountNumber &&
        this.disbursementBankAccount.bankCode &&
        this.disbursementBankAccount.branchNumber
      ) {
        this.confirmationSecond = true;
          setTimeout(() => {
            this.stepper.next();
          }, 300);
      } else {
      this.snackBarMessageService.error("Verifique se você preencheu todas as informações bancarias corretamente");
      }
    }
  }


  simulation(userId:string, key:string, value: any){
    this.openTable = false
    this.submit_click = true
    this.releasedValue = 0
    this.totalAmount = 0
    this.preFixedAmountSum = 0
    this.principalAmortizationAmountSum = 0
    var formattedValue
    if (typeof value === 'string') {
     formattedValue = value.includes(',') ? value.split(',').join('.') : value;
    } else {
      formattedValue = value.toString();
    }
    const numeroFormatado = parseFloat(formattedValue);

    let body = {
      "userId": userId,
      "key": key,
      "value": numeroFormatado,
      "table": this.product ? this.product : undefined
    }

    console.log(body)

    this.amountToReceive = 0
    this.simulationsvc.SimulationDesiredValue(body).subscribe( (scc: any)=> {
      this.amountToReceiveLoadin = true
      this.simulationsvc.GetAvaibleBalance(this.userId,this.data.data.key).subscribe( (scc: any) => {
        this.amountToReceive = scc.amountToReceive;
        this.amountToReceiveLoadin = false;
      },(err) => {
        console.log(err)
      })
      this.adictionData = scc
        this.AllowRegistry = true;
        this.openTable = true;
        this.return_list = scc.installments;
        this.confirmationFirst = true;
        this.installments = scc.installments.length;
        this.feeAmount = scc.feeAmount;
        this.installmentList = scc.installments;
        this.submit_click = false;

        if(scc.installments.length > 0){
          for (const period of scc.installments) {
            this.resume = scc.installments
            this.releasedValue += period.duePrincipal;
            this.totalAmount += period.totalAmount;
            this.preFixedAmountSum += period.preFixedAmount;
            this.principalAmortizationAmountSum += period.principalAmortizationAmount;
          }
        }
      this.align = true ;
      this.contconthe = true;
      this.dataPersonche = true;
        console.log(scc)
      }, (err) => {
        this.snackBarMessageService.error(err.error.messages[0].text);
        this.openTable = false
        this.submit_click = false
        console.log(err)
      })
    }

    changeCardSimulationValue(name: string) {
      this.valueCardSimulationChoiced = this.valueCardSimulationChoiced === name ? null : name;
      console.log(this.valueCardSimulationChoiced)

      const allProducts = [...this.safeProducts, ...this.basicProducts, ...this.smartProducts, ...this.liteProducts];
      this.product = allProducts.find(product => product.name === name);
      this.valueTotalSimulated = this.product.valueSimulation
      this.simulation_value = this.valueTotalSimulated
      console.log(this.valueTotalSimulated)
  }

  addItem(newItem: any) {
    this.items.push(newItem);
  }

  converterDataForSubmit(data: any) {
    var dataConvertida = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
    return dataConvertida;
  }

  convertWithNormal(data: any){
      var dataConvertida = moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
      return dataConvertida;
    }

  createProposal(){
    this.loadingProposal = true
    // if (this.borrower.completePhone.length == 0) {
      //   return this.snackBarMessageService.error("verifique se o telefone foi cadastrado corretamente")
    // }

    this.borrower.phone.areaCode = this.borrower.completePhone.substring(0, 2);
    this.borrower.phone.number = this.borrower.completePhone.substring(2);

    this.borrower.birthDate = this.converterDataForSubmit(this.borrower.birthDate)

    this.formatDisbursementBankAccount()

    if (this.controllerInformationTransferCheck == true) {
      let body = {
        "userId": this.userId,
        "key": this.key,
        "externalId": this.aditionalData.externalId,
        "borrower": this.borrower,
        "desiredInstallments":  this.installmentList,
        "disbursementBankAccount":  this.disbursementBankAccount,
        "table": this.product ? this.product : undefined
      }

      try{
        this.simulationsvc.createProposal(body).subscribe((scc) => {
          this.snackBarMessageService.success("Proposta Criada Com Sucesso");
          console.log(scc)
          this.dialogRef.close()
        }, (err) => {
          this.borrower.birthDate = this.convertWithNormal(this.borrower.birthDate)
          for(let i of err.error.messages){
            this.snackBarMessageService.error(i.text)
            this.confirmationModule = true
          }
          console.log(err)
          }).add(() => {
            this.loadingProposal = false;
        });
      }catch(e){
        this.borrower.birthDate = this.convertWithNormal(this.borrower.birthDate)
        console.log(e)
        }
    }
    }

  formatDisbursementBankAccount() {
    for (var atribute in this.disbursementBankAccount) {
      if (this.disbursementBankAccount[atribute] === null) {
        this.disbursementBankAccount[atribute] = undefined;
      }
    }

    if(this.opitionsProposal.pixKeyType && this.disbursementBankAccount.pixKey) {
        if (this.opitionsProposal.pixKeyType.length > 0 && this.disbursementBankAccount.pixKey.length > 0) {
          if (this.opitionsProposal.pixKeyType === "Celular") {
            if(!this.disbursementBankAccount.pixKey.includes('+55')) {
              this.disbursementBankAccount.pixKey = `+55${this.disbursementBankAccount.pixKey}`
            }
            this.controllerInformationTransferCheck = true
          }

          if (this.opitionsProposal.pixKeyType === "CPF") {
            this.disbursementBankAccount.pixKey = this.disbursementBankAccount.pixKey.replace(/\D/g, '');
            this.controllerInformationTransferCheck = true
          }

          if (this.opitionsProposal.pixKeyType === "Email") {
            if (this.disbursementBankAccount.pixKey.includes('@')) {
                this.controllerInformationTransferCheck = true
                this.disbursementBankAccount.pixKey;
            } else {
              this.controllerInformationTransferCheck = false
              this.snackBarMessageService.error("A chave pix Email não contém um e-mail válido.");
            }
          }

          if (this.opitionsProposal.pixKeyType === "Chave Aleatória") {
            this.controllerInformationTransferCheck = true
          }
      }
    }
  }

  confirmationFirstFalse() {
    this.confirmationFirst = false
  }

  formatarValorOnlyValue(numero: any) {
    const numeroFormatado = numero.toString().replace('.', ',');
    return numeroFormatado
  }

  valideValue(value: any) {
    console.log(value)
    let formattedValue;
    if (typeof value === 'string') {
      formattedValue = value.includes(',') ? value.split(',').join('.') : value;
    } else {
      formattedValue = value.toString();
    }
    const numeroFormatado = parseFloat(formattedValue);
    if (numeroFormatado <= this.valueTotalSimulated) {
      return true;
    } else {
      this.snackBarMessageService.error("Valor não disponível para consulta");
      return false;
    }
}
}
