import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';


@Injectable({
  providedIn:'root'
})
export class SnackBarMessageService {
  constructor(public snackBar: MatSnackBar) {
  }

  success(message: any = null, verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'end', duration: number = 10000): void {
    this.snackBar.open(message, 'Ok', {
      duration: duration,
      panelClass: ['app-snackbar-success'],
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition
    });
  }

  error(message: any = null, verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'end'): void {
    this.snackBar.open(message, 'Ok', {
      duration: 10000,
      panelClass: ['app-snackbar-error'],
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition
    });
  }
}


