import { Injectable } from '@angular/core';
import { SimulationModuleService } from './simulation-module.service';

@Injectable({
  providedIn:'root'
})

export class ProposalsList {

  constructor(
      public simulation_service: SimulationModuleService,
  ) {}
  
  typeCode: number = 0
  listProposals: any
  listProposalsOriginal: any

  filterActived: boolean = true

  reloadProposals(userId: string | null, typeCode: any): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(typeCode, 'reloadProposalsService')
        this.simulation_service.FindAvaibleBalance(userId, typeCode).subscribe(
            (scc: any) => {
                console.log(scc);
                this.listProposals = scc;
                this.listProposalsOriginal = scc;
                resolve(scc);
            },
            (err) => {
                console.log(err);
                reject(err);
            }
        );
    });
  }
}