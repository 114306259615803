import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CorService {
  colorPrimary: string = '#4ECD72';
  colorSecond: string = '#FFF';
  mainFontColor: string = 'black'
  simulatioinColor: string = 'white'
  logoURL: string = '';
}