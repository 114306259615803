import { Component,OnInit } from '@angular/core';
import { SimulationModuleService } from 'src/app/services/simulation-module.service';

@Component({
  selector: 'app-bankaccount',
  templateUrl: './bankaccount.component.html',
  styleUrls: ['./bankaccount.component.css']
})

export class BankaccountComponent {
constructor(public simulationsvc: SimulationModuleService){}
  bank_name: string = "";
  bank_list: any;
  bank_agency: string = "";
  bank_account: any;
  account_type: Array<any> = [
    {
      "name": "Conta Corrente"
    },
    {
      "name": "Conta Poupança"
    }
  ];

  account_type_select: string = "";
  ngOnInit(){
    this.simulationsvc.bankList().subscribe(scc => {
      this.bank_list = (scc);
    })
  }
}
