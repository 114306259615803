import { DesiredInstallments } from "./desired_installments";

export class Financial {
    desired_installments = Array<DesiredInstallments>
    interest_type: string = "";
    disbursement_date: string = "";
    fine_configuration = {
        monthly_rate: "",
        interest_base: "",
        contract_fine_rate: "",
    }
    businessDueDate: any
    calendarDays: any
    dueDate: any
    duePrincipal: any
    hasInterest: any
    installmentNumber: any
    postFixedAmount: any
    preFixedAmount: any
    principalAmortizationAmount: any
    taxAmount: any
    totalAmount: any
    workdays: any
    annual_interest_rate: number = 0
    credit_operation_type: string = ""
    interest_grace_period: number = 0
    number_of_installments: number = 0
    principal_grace_period: number = 0
}