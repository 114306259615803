export class DocumentTypeList {
    public document: Array<any> = [
        {
            "id": "4a627a23-6b93-4937-a799-c5958b86391a",
            "code": 1,
            "label": null,
            "display": "Carteira de Identidade - RG",
            "name": "Carteira de Identidade - RG",
            "identity": null,
            "color": null,
            "featureCode": 0,
            "featureName": null,
            "typeCode": 5,
            "typeName": "Tipo de Documento",
            "statusCode": 0,
            "statusName": null,
            "statusDate": null,
            "statusColor": null,
            "note": null
        },
        {
            "id": "370c312c-dbf3-4d57-bdd1-92ca68cd0b1b",
            "code": 2,
            "label": null,
            "display": "Cadastro de Pessoa Física - CPF",
            "name": "Cadastro de Pessoa Física - CPF",
            "identity": null,
            "color": null,
            "featureCode": 0,
            "featureName": null,
            "typeCode": 5,
            "typeName": "Tipo de Documento",
            "statusCode": 0,
            "statusName": null,
            "statusDate": null,
            "statusColor": null,
            "note": null
        }
    ]
    constructor(){
        
    }
}