<div class="container">
  <div style="display: flex; flex-direction: column; height: 100%; width: auto; background: #EDEDED;">

    <div class="backSpace">
  
      <div class="example-container">
        <div class="headerFila" style="background: #C2B24A;">
          <h2 style="margin: 0;">Consultando</h2>
          <p style="margin: 0;">R$ {{calcTotalValueOfColumn(kanbanService.consulting)}} - {{ kanbanService.consulting.length }} cards</p>
        </div>
        <div
          cdkDropList
          #consultingList="cdkDropList"
          [cdkDropListData]="kanbanService.consulting"
          class="example-list"
          style="background: #fffadc;"
        >
          <div class="example-box" *ngFor="let proposal of kanbanService.consulting; let i = index;" cdkDrag>
            <app-fila [_data]="proposal" [typecode]="proposalsList.typeCode" [pf]="pf"></app-fila>
          </div>
        </div>
      </div>
      
      <div class="example-container">
        <div class="headerFila" style="background: #43C466">
          <h2 style="margin: 0;">Atendimento</h2>
          <p style="margin: 0;">R$ {{calcTotalValueOfColumn(kanbanService.balanceAvailable)}} - {{ kanbanService.balanceAvailable.length }} cards</p>
        </div>
        <div
        cdkDropList
        #balanceAvailableList="cdkDropList"
        [cdkDropListData]="kanbanService.balanceAvailable"
        [cdkDropListConnectedTo]="[simulationSentList, inNegotiationList, signedContractList, noInteressing]"
        class="example-list"
        style="background: #dfede3;"
        (cdkDropListDropped)="drop($event)"
        >
          <div class="example-box" *ngFor="let proposal of kanbanService.balanceAvailable; let i = index;" cdkDrag>
            <app-fila [_data]="proposal" [typecode]="proposalsList.typeCode" [pf]="pf"></app-fila>
          </div>
        </div>
      </div>
    
      <div class="example-container">
        <div class="headerFila" style="background: #00B78f;">
          <h2 style="margin: 0;">Simulação enviada</h2>
          <p style="margin: 0;">R$ {{calcTotalValueOfColumn(kanbanService.simulationSent)}} - {{ kanbanService.simulationSent.length }} cards</p>
        </div>
        <div
          cdkDropList
          #simulationSentList="cdkDropList"
          [cdkDropListData]="kanbanService.simulationSent"
          [cdkDropListConnectedTo]="[inNegotiationList, signedContractList, noInteressing]"
          class="example-list"
          style="background: #dbf0ec;"
          (cdkDropListDropped)="drop($event, 6)"
        >
          <div class="example-box" *ngFor="let proposal of kanbanService.simulationSent; let i = index;" cdkDrag>
            <app-fila [_data]="proposal" [typecode]="proposalsList.typeCode" [pf]="pf"></app-fila>
          </div>
        </div>
      </div>
    
      <div class="example-container">
        <div class="headerFila" style="background: #0090C8;">
          <h2 style="margin: 0;">Em negociação</h2>
          <p style="margin: 0;">R$ {{calcTotalValueOfColumn(kanbanService.inNegotiation)}} - {{ kanbanService.inNegotiation.length }} cards</p>
        </div>
        <div
          cdkDropList
          #inNegotiationList="cdkDropList"
          [cdkDropListData]="kanbanService.inNegotiation"
          [cdkDropListConnectedTo]="[simulationSentList, signedContractList, noInteressing]"
          class="example-list"
          style="background: #b1c2c9"
          (cdkDropListDropped)="drop($event, 7)"
        >
          <div class="example-box" *ngFor="let proposal of kanbanService.inNegotiation; let i = index;" cdkDrag>
            <app-fila [_data]="proposal" [typecode]="proposalsList.typeCode" [pf]="pf"></app-fila>
          </div>
        </div>
      </div>
    
      <div class="example-container">
        <div class="headerFila" style="background: #2D5CB0;"> 
          <h2 style="margin: 0;">Contrato Fechado</h2>
          <p style="margin: 0;">R$ {{calcTotalValueOfColumn(kanbanService.signedContract)}} - {{ kanbanService.signedContract.length }} cards</p>
        </div>
        <div
          cdkDropList
          #signedContractList="cdkDropList"
          [cdkDropListData]="kanbanService.signedContract"
          [cdkDropListConnectedTo]="[simulationSentList, inNegotiationList, noInteressing]"
          class="example-list"
          style="background: #d9dfe9;"
          (cdkDropListDropped)="drop($event, 2)"
        >
          <div class="example-box" *ngFor="let proposal of kanbanService.signedContract; let i = index;" cdkDrag>
            <app-fila [_data]="proposal" [typecode]="proposalsList.typeCode" [pf]="pf"></app-fila>
          </div>
        </div>
      </div>

      <div class="example-container">
        <div class="headerFila" style="background: gray;"> 
          <h2 style="margin: 0;">Sem Interesse</h2>
          <p style="margin: 0;">R$ {{calcTotalValueOfColumn(kanbanService.noInteressing)}} - {{ kanbanService.noInteressing.length }} cards</p>
        </div>
        <div
          cdkDropList
          #noInteressing="cdkDropList"
          [cdkDropListData]="kanbanService.noInteressing"
          [cdkDropListConnectedTo]="[simulationSentList, inNegotiationList, signedContractList]"
          class="example-list"
          style="background: #d9dfe9;"
          (cdkDropListDropped)="drop($event, 8)"
        >
          <div class="example-box" *ngFor="let proposal of kanbanService.noInteressing; let i = index;" cdkDrag>
            <app-fila [_data]="proposal" [typecode]="proposalsList.typeCode" [pf]="pf"></app-fila>
          </div>
        </div>
      </div>
    
      <div class="example-container">
        <div class="headerFila" style="background: #C41F1F;">
          <h2 style="margin: 0;">Sem Retorno</h2>
          <p style="margin: 0;">R$ {{calcTotalValueOfColumn(kanbanService.error)}} - {{ kanbanService.error.length }} cards</p>
        </div>
        <div
          cdkDropList
          #errorList="cdkDropList"
          [cdkDropListData]="kanbanService.error"
          class="example-list"
          style="background: #f7e4e4;"
          >
          <div class="example-box" *ngFor="let proposal of kanbanService.error; let i = index;" cdkDrag>
            <app-fila [_data]="proposal" [typecode]="proposalsList.typeCode" [pf]="pf"></app-fila>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>