import { Component, Output,EventEmitter,Input, ElementRef, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { ZipCodeServiceService } from '../zip-code-service.service';
import { AppSetting } from 'src/app/entities/utils/appsettings';
import { SimulationModuleService } from 'src/app/services/simulation-module.service';
import { BanksList } from 'src/app/services/banksList.service';
import { Format } from 'src/app/services/format.service';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.css'],
})

export class PersonalInformationComponent {
  
  public appSetting = new AppSetting()
  selectedMetod: { type: string; mask?: undefined; } | { type: string; mask: string; } | undefined;

  constructor(
    public format: Format,
    public zipCodeService: ZipCodeServiceService,
    public simulation_service: SimulationModuleService,
    private cdr: ChangeDetectorRef,
    public banksList: BanksList
  ){}
  
    @Input() borrower: any
    @Input() aditionalData: any
    @Input() disbursementBankAccount: any
    @Input() opitionsProposal: any
    @Input() openTable: any
    @Input() _data: any
    @Input() adicionData: any
    @Output() newItemEvent = new EventEmitter<any>();
    @ViewChild('numeroInput') numeroInput!: ElementRef<HTMLInputElement>;

    document_number: string = ""
    formattedDate: any = ""
    numero: any
    pf: any
    iduser = ""
    invalidPhoneNumber = false;
    nationalityOpitions = ["Brasileira", "Estrangeira"]
    birthdate: string = '';
    label: string = 'Data de Nascimento';
    maskpix: string = ""
    bancos = this.banksList.bancos
    
    onPhoneNumberInput() {
      const phone = this.borrower.completePhone.replace(/\D/g, '');
      this.invalidPhoneNumber = !(phone.length === 11); 
    }
    
    MetodsPaymentPix = [
      { type: "CPF" },
      { type: "Email" },
      { type: "Celular", mask: "(00) 00000-0000" },
      { type: "Chave Aleatória" }
    ];
  
    obterValorPorIspb(bankIspb) {
      const bancoEncontrado = this.bancos.find(banco => banco.bankIspb === bankIspb);
      
      if (bancoEncontrado) {
        return bancoEncontrado.valor;
      } else {
        return null; 
      }
    }
    
    ngOnInit(){
      this.borrower.nationality = this.nationalityOpitions[0]
      this.borrower.maritalStatus = this.marital_status[0].value

      this.iduser = localStorage.getItem('userId')!
      this.simulation_service.getUser(this.iduser).subscribe( (result: any) => {
        this.pf = result.salesPF
      })

      this.borrower.individualDocumentNumber = this._data.documentNumber
      
      if (this._data.phone) {        
        this.borrower.completePhone = `${this._data.phone.area_code}${this._data.phone.number}`
      }

      this.AdicionDataInInformations()

      this.document_number = this.format.formatCPF(this._data.documentNumber)
    }

    updateMask(selectedMetod: any) {
      if (selectedMetod?.type === 'CPF') {
        this.opitionsProposal.pixKeyType = selectedMetod.type;
        this.disbursementBankAccount.pixKey = this.format.formatCPF(this.borrower.individualDocumentNumber);
      } else if (selectedMetod?.type === 'Celular') {
        this.opitionsProposal.pixKeyType = selectedMetod.type;
        this.disbursementBankAccount.pixKey = `${this.adicionData.borrower.phone.area_code}${this.adicionData.borrower.phone.number}`;
      } else {
        this.opitionsProposal.pixKeyType = '';
        this.disbursementBankAccount.pixKey = '';
      }
    }
    
  AdicionDataInInformations() {
    if(this.adicionData.borrower != null){
      var dia = this.adicionData.borrower.birth_date.substr(8, 2);
      var mes = this.adicionData.borrower.birth_date.substr(5, 2);
      var ano = this.adicionData.borrower.birth_date.substr(0, 4);
      var dataFormatada = dia + '/' + mes + '/' + ano;
      
     if (!this._data.phone) {
       this.borrower.completePhone = `${this.adicionData.borrower.phone.area_code}${this.adicionData.borrower.phone.number}`
     }

      this.borrower.name = this.adicionData.borrower.name;
      this.borrower.motherName = this.adicionData.borrower.mother_name;
      this.borrower.nationality = this.adicionData.borrower.nationality;
      this.borrower.birthDate = dataFormatada;
      this.borrower.email = this.adicionData.borrower.email;
      this.borrower.maritalStatus = this.adicionData.borrower.marital_status;
      this.borrower.profession = this.adicionData.borrower.profession;
      this.borrower.address.postalCode = this.adicionData.borrower.address.postal_code;
      this.borrower.address.street = this.adicionData.borrower.address.street;
      this.borrower.address.number = this.adicionData.borrower.address.number;
      this.borrower.address.complement = this.adicionData.borrower.address.complement;
      this.borrower.address.neighborhood = this.adicionData.borrower.address.neighborhood;
      this.borrower.address.city = this.adicionData.borrower.address.city;
      this.borrower.address.state = this.adicionData.borrower.address.state;
      this.disbursementBankAccount.bankCode = this.obterValorPorIspb(this.adicionData.disbursement_bank_account.ispb_number);
      this.disbursementBankAccount.branchNumber = this.adicionData.disbursement_bank_account.branch_number;
      this.disbursementBankAccount.accountNumber = this.adicionData.disbursement_bank_account.account_number;
      this.disbursementBankAccount.accountDigit = this.adicionData.disbursement_bank_account.account_digit;
   
      const pixKey = this.adicionData.disbursement_bank_account?.pix_key;

      if (pixKey === undefined || pixKey === null) {
        console.log('chave indefinida');
      } else {
        this.opitionsProposal.changeMetodPayment = 'PIX';
    
        switch (true) {
          case (pixKey === this._data.documentNumber):
            console.log('cpf');
            this.selectedMetod = this.getMethodByType('CPF');
            this.disbursementBankAccount.pixKey = this.format.formatCPF(this.adicionData.disbursement_bank_account?.pix_key)
            break;
    
            case (pixKey.length === 14 && pixKey !== this._data.documentNumber):
              console.log('Telefone');
              let CelValue = pixKey;
          
              this.selectedMetod = this.getMethodByType('Celular');
          
              if (this.adicionData.disbursement_bank_account?.pix_key.startsWith('+55')) {
                  CelValue = this.adicionData.disbursement_bank_account?.pix_key.slice(3);
              } else {
                  CelValue = this.adicionData.disbursement_bank_account?.pix_key;
              }
          
              this.disbursementBankAccount.pixKey = CelValue;
              break;
    
          case (pixKey.includes("@")):
            this.selectedMetod = this.getMethodByType('Email');
            this.disbursementBankAccount.pixKey = this.adicionData.disbursement_bank_account?.pix_key
            break;
    
          default:
            this.selectedMetod = this.getMethodByType('Chave Aleatória');
            this.disbursementBankAccount.pixKey = this.adicionData.disbursement_bank_account?.pix_key
            break;
        }
      }
    }
  }
    getMethodByType(type: string) {
      return this.MetodsPaymentPix.find((Metod) => Metod.type === type);
    }

  addNewItem() {
      this.newItemEvent.emit(this.borrower);   
  }

  addNewBankInformationInItem()  {
    this.newItemEvent.emit(this.disbursementBankAccount);
  }

  addNewAditionalData()  {
    this.newItemEvent.emit(this.aditionalData);
  }  
  
  addNewopitionsProposal()  {
    this.newItemEvent.emit(this.opitionsProposal);
  }

  updateBirthDate(event: any) {
    this.borrower.birthDate = event.target.value;
  }

  loadZipCode() {
    this.zipCodeService.setZipCode(this.borrower.address.postalCode).subscribe(
      result => {
        this.borrower.address.street = result.street;
        this.borrower.address.city = result.city;
        this.borrower.address.state = result.state;
        this.borrower.address.neighborhood = result.district;
        this.focusInputNumberAddress();
      },
      error => {
        console.error('Error loading zip code:', error);
      }
    );
  }

  focusInputNumberAddress() {
    if (this.numeroInput) {
      this.numeroInput.nativeElement.focus();
    }
  }

  marital_status = [
    {
      "value": "single",
      "pt": "Solteiro"
    },
    {
      "value": "married",
      "pt": "Casado"
    },
    {
      "value": "widower",
      "pt": "Viúvo"
    },
    {
      "value": "divorced",
      "pt": "Divorciado"
    }
  ]


  sexos = {
    "sexo": [
      {
        "id": 1,
        "genero": "masculino"
      },
      {
        "id": 2,
        "genero": "feminino"
      }
    ]
  }

}

