export const fgts_simulation_guess = 
{
    "data": {
        "annual_cet": 0.3655007620895273,
        "assignment_amount": 4070.94,
        "cet": 0.0263,
        "contract_fee_amount": 24.43,
        "contract_fees": [
            {
                "amount": 0.6,
                "amount_type": "percentage",
                "fee_amount": 24.43,
                "fee_type": "tac"
            }
        ],
        "credit_operation_type": "ccb",
        "disbursed_issue_amount": 4000.0,
        "disbursement_date": "2023-06-30",
        "disbursement_options": [
            {
                "annual_cet": 0.3655007620895273,
                "assignment_amount": 4070.94,
                "cet": 0.0263,
                "contract_fee_amount": 24.43,
                "contract_fees": [
                    {
                        "amount": 0.6,
                        "amount_type": "percentage",
                        "fee_amount": 24.43,
                        "fee_type": "tac"
                    }
                ],
                "disbursed_issue_amount": 4000.0,
                "disbursement_date": "2023-06-30",
                "external_contract_fee_amount": 0.0,
                "external_contract_fees": [
                    {
                        "amount": 0.0,
                        "amount_released": 0,
                        "amount_type": "absolute",
                        "cofins_amount": 0,
                        "csll_amount": 0,
                        "description": null,
                        "fee_amount": 0.0,
                        "fee_type": "tac",
                        "irrf_amount": 0,
                        "net_fee_amount": 0.0,
                        "pis_amount": 0,
                        "tax_amount": 0.0
                    },
                    {
                        "amount": 0.0,
                        "amount_released": 0,
                        "amount_type": "absolute",
                        "cofins_amount": 0,
                        "csll_amount": 0,
                        "description": null,
                        "fee_amount": 0.0,
                        "fee_type": "spread",
                        "irrf_amount": 0,
                        "net_fee_amount": 0.0,
                        "pis_amount": 0,
                        "tax_amount": 0.0
                    }
                ],
                "first_due_date": "2023-10-01",
                "installments": [
                    {
                        "business_due_date": "2023-10-02",
                        "calendar_days": 93,
                        "due_date": "2023-10-01",
                        "due_principal": 4070.94,
                        "has_interest": true,
                        "installment_number": 1,
                        "post_fixed_amount": null,
                        "pre_fixed_amount": 259.2870755335,
                        "principal_amortization_amount": 4070.9429244665,
                        "tax_amount": 31.045010741981528,
                        "total_amount": 4330.23,
                        "workdays": 64.0
                    }
                ],
                "iof_amount": 46.51,
                "issue_amount": 4070.94,
                "net_external_contract_fee_amount": 0.0,
                "prefixed_interest_rate": {
                    "annual_rate": 0.27422288,
                    "daily_rate": 0.00066416,
                    "interest_base": "calendar_days_365",
                    "monthly_rate": 0.0204
                },
                "total_pre_fixed_amount": 259.2870755335
            }
        ],
        "external_contract_fee_amount": 0.0,
        "external_contract_fees": [
            {
                "amount": 0.0,
                "amount_released": 0,
                "amount_type": "absolute",
                "cofins_amount": 0,
                "csll_amount": 0,
                "description": null,
                "fee_amount": 0.0,
                "fee_type": "tac",
                "irrf_amount": 0,
                "net_fee_amount": 0.0,
                "pis_amount": 0,
                "tax_amount": 0.0
            },
            {
                "amount": 0.0,
                "amount_released": 0,
                "amount_type": "absolute",
                "cofins_amount": 0,
                "csll_amount": 0,
                "description": null,
                "fee_amount": 0.0,
                "fee_type": "spread",
                "irrf_amount": 0,
                "net_fee_amount": 0.0,
                "pis_amount": 0,
                "tax_amount": 0.0
            }
        ],
        "final_disbursement_amount": 4000.0,
        "installments": [
            {
                "business_due_date": "2023-10-02",
                "calendar_days": 93,
                "due_date": "2023-10-01",
                "due_principal": 4070.94,
                "has_interest": true,
                "installment_number": 1,
                "post_fixed_amount": null,
                "pre_fixed_amount": 259.2870755335,
                "principal_amortization_amount": 4070.9429244665,
                "tax_amount": 31.045010741981528,
                "total_amount": 4330.23,
                "workdays": 64.0
            }
        ],
        "interest_grace_period": 0,
        "interest_payment_month_period": 1,
        "interest_type": "pre_price_days",
        "iof_amount": 46.51,
        "issue_amount": 4070.94,
        "issue_date": "2023-06-30",
        "net_external_contract_fee_amount": 0.0,
        "number_of_installments": 1,
        "operation_type": "structured_operation",
        "post_fixed_interest_base": "workdays",
        "post_fixed_interest_rate": null,
        "prefixed_interest_rate": {
            "annual_rate": 0.27422288,
            "daily_rate": 0.00066416,
            "interest_base": "calendar_days_365",
            "monthly_rate": 0.0204
        },
        "principal_amortization_month_period": 1,
        "principal_grace_period": 0,
        "requester_key": "a5c043d3-dec3-4f8d-aa14-01dc9ba85783",
        "total_pre_fixed_amount": 259.2870755335
    },
    "event_datetime": "2023-06-28 14:11:15",
    "key": "aa0d581b-4332-40e1-adad-0c7724dc880a",
    "status": "finished",
    "type": "debt"
}