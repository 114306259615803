import { Component } from '@angular/core';
import { AppSetting } from '../entities/utils/appsettings';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {
  
  public appSetting = new AppSetting();
}
