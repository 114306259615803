import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ZipCodeServiceService {

  constructor(public http: HttpClient, public snackBar: MatSnackBar) { }
  
  setZipCode(zipCode: string): Observable<any> {
    return new Observable(observer => {
      if (!zipCode) {
        return;
      }

      zipCode = zipCode.replace(/\D+/g, '');
      if (!zipCode) {
        return;
      }

      const url = 'https://viacep.com.br/ws/' + zipCode + '/json';
      this.http.get(url).subscribe(
        result => {
          if (result && (result as any).erro) {
            this.snackBar.open('CEP não encontrado.', 'Ok', {
              duration: 2000
            });
            return;
          }

          const json = result as any;
          observer.next({
            street: json.logradouro,
            district: json.bairro,
            city: json.localidade,
            state: json.uf
          });
        },
        error => {
          this.snackBar.open('CEP não encontrado.', 'Ok', {
            duration: 2000,
          });

          observer.error({
            street: '',
            district: '',
            city: '',
            state: ''
          });
        });
    });
  }
}
