import { Component,OnInit } from '@angular/core';
import { DocumentTypeList } from 'src/app/entities/document_type';
import { Borrower } from 'src/app/entities/utils/borrower';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
@Component({
  
  selector: 'app-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.css'],

})

export class DocumentTypeComponent {

  _documentList = new DocumentTypeList();
  _Borrower = new Borrower();
  
  public documentType: any = 0

  OnInit(){
    console.log(this._documentList)
  }
  
  setDocumentInput(code: string){
    this.documentType = code
    console.log(code)
  }

}
