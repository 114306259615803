import { Injectable } from '@angular/core';
import { ProposalsList } from 'src/app/services/proposalsList.service';
import { SimulationModuleService } from 'src/app/services/simulation-module.service';

@Injectable({
  providedIn:'root'
})

export class KanbanService {
    
    constructor(
        public proposalsList: ProposalsList,
        public simulation_service: SimulationModuleService,
    ) {}

    search: string = ""
    consulting: any;
    balanceAvailable: any;
    simulationSent: any;
    inNegotiation: any;
    signedContract: any;
    noInteressing: any;
    error: any;

  filterProposalForStatus() {
    this.consulting = this.proposalsList.listProposals.filter(proposal => {
      return proposal.statusFront == "Processando Consulta"
    })

    this.balanceAvailable = this.proposalsList.listProposals.filter(proposal => {
      return proposal.statusFront == "Saldo Disponível" || proposal.statusFront == "Saldo Insuficiente"
    })
    
    this.simulationSent = this.proposalsList.listProposals.filter(proposal => {
      return proposal.statusFront == "Simulação Enviada"
    })
    
    this.inNegotiation = this.proposalsList.listProposals.filter(proposal => {
      return proposal.statusFront == "Em Negociação"
    })
    
    this.signedContract = this.proposalsList.listProposals.filter(proposal => {
      return proposal.statusFront == "Contrato Fechado" || proposal.statusFront == "Contrato Pago";
    });
    
    this.error = this.proposalsList.listProposals.filter(proposal => {
      return proposal.statusFront == "Sem Retorno" 
    })
    
    this.noInteressing = this.proposalsList.listProposals.filter(proposal => {
      return proposal.statusFront == "Sem Interesse" 
    })    
    
    console.log(this.noInteressing, ' sem interesse')
  }
  
}
