import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BanksList {
    bancos = [
        {nome: "001 - BANCO DO BRASIL S.A.", valor: "001", bankIspb: "00000000"},
        {nome: "003 - BANCO DA AMAZONIA S.A.", valor: "003", bankIspb: "04902979"},
        {nome: "004 - BANCO DO NORDESTE DO BRASIL S.A.", valor: "004", bankIspb: "07237373"},
        {nome: "021 - BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO", valor: "021", bankIspb: "28127603"},
        {nome: "033 - BANCO SANTANDER (BRASIL) S.A.", valor: "033", bankIspb: "90400888"},
        {nome: "037 - BANCO DO ESTADO DO PARÁ S.A.", valor: "037", bankIspb: "04913711"},
        {nome: "041 - BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.", valor: "041", bankIspb: "92702067"},
        {nome: "047 - BANCO DO ESTADO DE SERGIPE S.A.", valor: "047", bankIspb: "13009717"},
        {nome: "070 - BRB - BANCO DE BRASILIA S.A.", valor: "070", bankIspb: "00000208"},
        {nome: "077 - BANCO INTER  S.A.", valor: "077", bankIspb: "00416968"},
        {nome: "085 - COOPERATIVA CENTRAL DE CRÉDITO AILOS", valor: "085", bankIspb: "05463212"},
        {nome: "104 - CAIXA ECONOMICA FEDERAL", valor: "104", bankIspb: "00360305"},
        {nome: "208 - BANCO BTG PACTUAL S.A.", valor: "208", bankIspb: "30306294"},
        {nome: "212 - BANCO ORIGINAL S.A.", valor: "212", bankIspb: "92894922"},
        {nome: "237 - BANCO BRADESCO S.A.", valor: "237", bankIspb: "60746948"},
        {nome: "260 - NU PAGAMENTOS S.A.", valor: "260", bankIspb: "18236120"},
        {nome: "318 - BANCO BMG S.A.", valor: "318", bankIspb: "61186680"},
        {nome: "336 - BANCO C6 S.A.", valor: "336", bankIspb: "31872495"},
        {nome: "341 - BANCO ITAÚ S.A.", valor: "341", bankIspb: "60701190"},
        {nome: "348 - BANCO XP S.A.", valor: "348", bankIspb: "33264668"},
        {nome: "389 - BANCO MERCANTIL DO BRASIL S.A.", valor: "389", bankIspb: "17184037"},
        {nome: "422 - BANCO SAFRA S.A.", valor: "422", bankIspb: "58160789"},
        {nome: "623 - BANCO PAN S.A.", valor: "623", bankIspb: "59285411"},
        {nome: "748 - BANCO COOPERATIVO SICREDI S.A.", valor: "748", bankIspb: "01181521"},
        {nome: "756 - BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB", valor: "756", bankIspb: "02038232"}
    ]
}