import { Borrower } from "./utils/borrower"
import { Colatteral } from "./utils/colatteral"
import { Financial } from "./utils/financial"
import { DisbursedAmount } from "./Disbursed-Amount"

export class DebtFgts {
    public borrower: any = new Borrower;
    public additional_data: any;
    public collaterals = new Array<Colatteral>;
    public financial: any = new Financial;
    public disbursement_bank_account: any =  new DisbursedAmount;

    constructor(){
    }

}