import { Component, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { AppSetting } from 'src/app/entities/utils/appsettings';
import { Format } from 'src/app/services/format.service';

@Component({
  selector: 'app-table-installments',
  templateUrl: './table-installments.component.html',
  styleUrls: ['./table-installments.component.css']
})
export class TableInstallmentsComponent {
  public appSetting = new AppSetting()
  @Input() dataTable: any 

  constructor
  (
    public format: Format
  ) 
  {}

  totalAmount=0

  ngOnInit() {
  this.reCounterDataTable
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['dataTable'];
    if (change) {
      this.reCounterDataTable();
    }
  }

  reCounterDataTable() {
    this.totalAmount = 0
    for(let i = 0; i < this.dataTable.length; i++) {
      if (this.dataTable[i].total_amount) {
        this.totalAmount += this.dataTable[i].total_amount;
      }
      else if (this.dataTable[i].totalAmount) {
        this.totalAmount += this.dataTable[i].totalAmount;
      }
    }
  }
}
