
<div class="containerModal">
    <div class="containerHeader">
        <img src="../../../assets/FdaFinanto.svg" style="width: 60px;" />
        <h1>Resumo do contrato</h1> 
    </div>
    <div class="containerBody">
        <section *ngIf="msgError">
            <p style="margin: 0; text-align: center; color: red;">{{ data.data.error_description_pt }}</p>
        </section>
        <div class="containerGray">
            <div style="height: auto; background: #EDEDED;" class="ContainerSections">
                <section>
                    <div>
                        <h2>Dados Pessoais</h2>
                    </div>
                    <div class="ContainerInfosBox">
                        <div class="infosBox">
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.name }}:</p>
                                <p>{{ dadosUser.borrower.name }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.cpf }}:</p>
                                <p>{{ cpfFormatted }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.email }}:</p>
                                <p>{{ dadosUser.borrower.email }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.phone }}:</p>
                                <p>{{ telefone }}</p>
                            </div>
                        </div>
                        <div class="infosBox">
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.motherName }}:</p>
                                <p>{{ dadosUser.borrower.mother_name }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.birthDate }}:</p>
                                <p>{{ niverFormatted }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.nationality }}:</p>
                                <p>{{ dadosUser.borrower.nationality }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.profession }}:</p>
                                <p>{{ dadosUser.borrower.profession }}</p>
                            </div>
                        </div>
                        <div class="infosBox">
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.maritalStatus }}:</p>
                                <p>{{ StatusMatrimonialtraductor(dadosUser.borrower.marital_status) }}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div>
                        <h2>Dados do endereço</h2>
                    </div>
                    <div class="ContainerInfosBox">
                        <div class="infosBox">
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.addressZipCode }}:</p>
                                <p>{{ dadosUser.borrower.address.postal_code }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.street }}:</p>
                                <p>{{ dadosUser.borrower.address.street }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.number }}:</p>
                                <p>{{ dadosUser.borrower.address.number }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.neighborhood }}:</p>
                                <p>{{ dadosUser.borrower.address.neighborhood }}</p>
                            </div>
                        </div>
                        <div class="infosBox">
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.addressComplement }}:</p>
                                <p>{{ dadosUser.borrower.address.complement }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.city }}:</p>
                                <p>{{ dadosUser.borrower.address.city }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.addressState }}:</p>
                                <p>{{ dadosUser.borrower.address.state }}</p>
                            </div>
                            <div class="infos">
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div>
                        <h2>Resumo da operação</h2>
                    </div>
                    <div class="ContainerInfosBox">
                        <div class="infosBox">
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.emissionValue }}:</p>
                                <p>{{ format.formatValue(dadosUser.data_result.disbursement_options[0].issue_amount) }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.ParcelNumber }}:</p>
                                <p>{{ dadosUser.financial.number_of_installments }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.DesembolsoDate }}:</p>
                                <p>{{ format.convertDateIsoInDateAndHours(dadosUser.data_result.disbursement_options[0].disbursement_date) }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.issuingDate }}:</p>
                                <p>{{ dateFormattedIssue }}</p>
                            </div>
                        </div>
                        <div class="infosBox">
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.FeesTax }}:</p>
                                <p>{{ (dadosUser.financial.monthly_interest_rate * 100).toFixed(2) }}%</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.contractNumber }}:</p>
                                <p>{{ dadosUser.data_result.contract.number }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.KeyPixType }}:</p>
                                <p>{{ dadosUser.disbursement_bank_account.pix_transfer_type }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.KeyPix }}:</p>
                                <p>{{ dadosUser.disbursement_bank_account.pix_key }}</p>
                            </div>
                        </div>
                        <div class="infosBox">
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.bank }}:</p>
                                <p *ngIf="bankInfos">{{ bankInfos.nome }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.agency }}:</p>
                                <p>{{ dadosUser.disbursement_bank_account.branch_number }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.account }}:</p>
                                <p>{{ dadosUser.disbursement_bank_account.account_number }}</p>
                            </div>
                            <div class="infos">
                                <p>{{ appSetting.LG_PTBR.externalId }}:</p>
                                <p>{{ dadosUser.externalId }}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div>
                        <h2>Fluxo de pagamento</h2>
                    </div>
                        <app-table-installments [dataTable]="dataTable"></app-table-installments>
                </section>
            </div>
        </div>
        <div class="footer"> 
            <span class="material-symbols-outlined" (click)="copyValue(dadosUser.key)" style="cursor: pointer; color: #4ECD72;">
                vpn_key
            </span>
            <div class="buttonsContainer">
                <button class="buttonAction" (click)="openContract()">
                    {{ appSetting.LG_PTBR.contract }}
                </button>
                <button class="buttonAction" (click)="openComprovant()" *ngIf="dadosUser.urlPaymentVoucher">
                    {{ appSetting.LG_PTBR.paymentComprovant }}
                </button>
                <div>
                    <ng-icon 
                    name="bootstrapLink45deg" 
                    onmouseover="this.style.color='green'" 
                    onmouseout="this.style.color='black'" 
                    style="color: black; font-size: 25px; cursor: pointer;" 
                    (click)="copyLink()"></ng-icon>
                </div>
            </div>     
        </div>
    </div>
</div>