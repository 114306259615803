import { Component } from '@angular/core';
import { SimulationModuleService } from './services/simulation-module.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  
  title = 'finanto-pay';
  iduser: any = localStorage.getItem("userId");
  pf: boolean = true

  constructor(
    public simulation_service: SimulationModuleService,
    ){
    }

  ngOnInit(): void {
    this.simulation_service.getUser(this.iduser).subscribe( (result: any) => {
      this.pf = result.salesPF
      console.log(this.pf)
    })
  }
}

