import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css']
})
export class SnackbarComponent {
    constructor(public snackBar: MatSnackBar) {
    }
  
    success(message: any = null, verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'end', duration: number = 1000): void {
      this.snackBar.open(message, 'Ok', {
        duration: duration,
        panelClass: ['app-snackbar-success'],
        verticalPosition: verticalPosition,
        horizontalPosition: horizontalPosition
      });
    }
  
    error(message: any = null, verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'end'): void {
      this.snackBar.open(message, 'Ok', {
        duration: 4000,
        panelClass: ['app-snackbar-error'],
        verticalPosition: verticalPosition,
        horizontalPosition: horizontalPosition
      });
    }
}

