<div class="trackQAHomelog" *ngIf="homelog">
    AMBIENTE DE HOMOLOGAÇÃO (QA)
</div>
<div class="horizontal-menu" *ngIf="pf == true">
    <div style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
        <div class="horizontal-menu-actions" *ngIf="showLayout">
            <div style="display: flex; align-items: center; flex-direction: row;">
                <div class="button" (click)="showAnother(0)" [ngClass]="{'buttonSelected': proposalsList.typeCode == 0}">Consulta ({{ manualProposalsLenght }})</div>
                <div class="button" (click)="showAnother(1)" [ngClass]="{'buttonSelected': proposalsList.typeCode == 1}">Cliente ({{ customerProposalsLenght }})</div>
                <div class="button" (click)="showAnother(2)" [ngClass]="{'buttonSelected': proposalsList.typeCode == 2}">Oportunidade ({{ opportunityProposalsLenght }})</div>
                <div class="filter">
                </div>
                <div style="display: flex; align-items: center; flex-direction: row; gap: 16px; margin-left: 10px;">
                    <div class="containerSelect">
                        <select [(ngModel)]="valueSearchStatusProposal" (ngModelChange)="searchStatusProposal()"
                            class="filterInput">
                            <option *ngFor="let status of proposalStatusName" [value]="status" class="custom-select-option">
                                {{ status }}
                            </option>
                        </select>
                    </div>
                    <div>
                        
                        <input type="text" (input)="filterProposalsByDocumentNumber($event.target.value)"
                            placeholder="Pesquisar..." [(ngModel)]="kanbanService.search" name="search" id="search"
                            class="filterInput">
                        </div>
                    </div>
                </div>
                <div class="switch-container" style="margin-left: 10px;">
                    <div class="switch-option" (click)="changeLayout(0)" [ngClass]="{'switch-option-selected': layout === 0}"
                    matTooltip="Card">
                    <span class="material-symbols-outlined">
                        grid_view
                    </span>
                </div>
                <div class="switch-option" (click)="changeLayout(1)" [ngClass]="{'switch-option-selected': layout === 1}"
                matTooltip="Tabela">
                <span class="material-symbols-outlined">
                    table_rows
                </span>
            </div>
                <div class="switch-option" (click)="changeLayout(2)" [ngClass]="{'switch-option-selected': layout === 2}"
                matTooltip="Kanban">
                <span class="material-symbols-outlined">
                    view_kanban
                </span>
                </div>
            </div>
        </div>
        <div style="margin-right: 20px;" *ngIf="showLayout">
            <span 
                class="material-symbols-outlined iconReload"  
                (click)="updateProposals()" 
                [ngClass]="{'disabled': loadingButtonProposals === true}"
                [disabled]="loadingButtonProposals === true">
                autorenew
            </span> 
        </div>
    </div>
</div>

<div class="mobile-button-container" [ngClass]="{'consultation-bg': showConsultation, 'filas-bg': !showConsultation}">
    <button *ngIf="isMobile" (click)="toggleView()" class="mobile-toggle-button">
        {{ showConsultation ? 'Ver Filas' : 'Consultar' }}
    </button>
</div>

<div class="containerConsult">
    <div class="allContainer">
        <div class="containerContent">
            <div class="loadingLayoutContainer" *ngIf="showLayout == false">
                <mat-spinner></mat-spinner>
            </div>
            
            
            <div class="ContainerFila" *ngIf="!showAnotherTab && layout == 0 && showLayout && (!isMobile || !showConsultation)">
                <div *ngFor=" let i of proposalsList.listProposals" style="height: fit-content;">
                    <div class="containerAppFila" *ngIf="proposalsList.listProposals.length > 0">
                        <app-fila [_data]="i" [typecode]="proposalsList.typeCode" [pf]="pf" *ngIf="queue"></app-fila>
                    </div>
                </div>
            </div>

            <div class="ContainerTable" *ngIf="layout == 1 && showLayout">
                <div class="tabela-container">
                    <table class="styled-table">
                        <thead>
                            <tr>
                                <th style="border-radius: 8px 0 0 0;">{{ appSetting.LG_PTBR.cpf }}</th>
                                <th>{{ appSetting.LG_PTBR.date }}</th>
                                <th>{{ appSetting.LG_PTBR.phone }}</th>
                                <th>{{ appSetting.LG_PTBR.disponibleValue }}</th>
                                <th>{{ appSetting.LG_PTBR.status }}</th>
                                <th style="border-radius: 0 8px 0 0;">{{ appSetting.LG_PTBR.actions }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of proposalsList.listProposals"
                                (dblclick)="openContractSimulation(item)">
                                <td (click)="copyValue(item.documentNumber)">{{ format.formatCPF(item.documentNumber) }}
                                </td>
                                <td (click)="copyValue(converterData(item.creationDate))">{{
                                    converterData(item.creationDate).length != 23 ? converterData(item.creationDate) :
                                    "carregando..." }}</td>
                                <td (click)="copyPhone(item.phone.area_code, item.phone.number)"> {{ item.phone != null
                                    ? (item.phone.area_code && item.phone.number ? item.phone.area_code + ' ' +
                                    item.phone.number : '-') : '-'}}</td>
                                <td (click)="copyScriptValueSimulation(format.formatValue(item.valueSimulation))">{{
                                    item.status == 'success' ? format.formatValue(item.valueSimulation) : '-' }}</td>
                                <td (click)="copyValue(item.statusFront)" [matTooltip]="item.errorActionClient">
                                    <div style="display: flex; gap: 15px;">
                                        <p style="width: 123px; margin: 0;">
                                            {{ item.statusFront }}
                                        </p>
                                        <span class="material-symbols-outlined"
                                            style="display: inline-block; transform: rotate(-45deg);"
                                            [style.color]="colorTicketForStatus(item.statusFront)">
                                            sell
                                        </span>
                                    </div>

                                </td>
                                <td>
                                    <div style="display: flex; gap: 15px; align-items: center;">
                                        <span mat-icon-button (click)="openMenu(item)">
                                            <mat-icon>archive</mat-icon>
                                        </span>
                                        <span class="material-symbols-outlined" style="font-size: 18px;"
                                            *ngIf="item.status == 'success'" (click)="openChangeStatusFila(item.key)">
                                            sync_alt
                                        </span>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="KanbanContainer" *ngIf="layout ==  2 && showLayout">
                <app-kanban [pf]="pf"></app-kanban>
            </div>

            <div class="openConsultContainer" (click)="desabilitySearchCpf()" style="cursor: pointer;"
                matTooltip="Ocultar / Exibir Consulta">
                <i class="material-symbols-outlined" *ngIf="openCloseDivConsultArrow == true">arrow_forward</i>
                <i class="material-icons" *ngIf="openCloseDivConsultArrow == false">arrow_back</i>
            </div>
            <div *ngIf="!isMobile || showConsultation" [ngClass]="{'containerFgtsConsult': visibilityCpfContainer == true, 'displayNone': visibilityCpfContainer == false}"
                [style.background]="colorSecond" style="z-index: 1;">
                <div class="containerEmoteHelp">
                    <i class="material-icons" style="cursor: pointer;"
                        matTooltip="Instruções para a consulta de Saldo do FGTS" (click)="modalHelp()">help</i>
                </div>
                <div>
                    <div class="containerPutCpf">

                        <h2 style="margin-bottom: 30px; text-align: center;" [style.color]="mainFontColor">{{
                            appSetting.LG_PTBR.fillInTheInformationBelow }}</h2>

                        <mat-form-field>
                            <mat-label> {{ appSetting.LG_PTBR.enterCPF }}</mat-label>
                            <input matInput [(ngModel)]="clientEntities.countryIdentity" id="cpf" #input
                                (input)="formatarCPFOnPressKey($event)" maxlength="14" required />
                            <mat-hint align="end">{{ input.value.length }}/14</mat-hint>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label> {{ appSetting.LG_PTBR.enterPhone }} (opcional)</mat-label>
                            <input matInput [(ngModel)]="clientEntities.phone" #input mask="(00) 00000-0000"
                                maxlength="15" />
                        </mat-form-field>
                    </div>

                    <div *ngIf="clientEntities.countryIdentity" class="containerButton">
                        <button *ngIf="showBotton" mat-flat-button class="button"
                            style="background-color: #43C466; padding: 20px; color: white;" (click)="getfgtsValue()">
                            {{ appSetting.LG_PTBR.consult }}
                        </button>
                    </div>
                    <div class="chat-container">
                        <div *ngIf="ButtonAssistentFinantoPay" class="chat-wrapper">
                            <iframe src="https://app.rosana.io/widget/3CC92796253E11D3106082E3F9DDDD7F/iframe"
                                width="400" height="600" frameborder="0" style="border: none;">
                            </iframe>
                        </div>
                        <button class="buttonChat" (click)="activeButtonAssistentFinantoPay()">
                            <span class="material-symbols-outlined">
                                chat
                            </span>
                        </button>
                    </div>
                    <mat-progress-bar *ngIf="!submit_click" style="margin-top: 30px;"
                        mode="indeterminate"></mat-progress-bar>
                </div>
            </div>
        </div>
    </div>