<div class="trackQAHomelog" *ngIf="homelog">
  AMBIENTE DE HOMOLOGAÇÃO (QA)
</div>

<div class="containerAcompanhamento">
    <div class="headerActionsMonitoring">
      <div class="containerInputs">
          <div class="input-group">
            <div class="custom-search-box">
              <label>{{ appSetting.LG_PTBR.Status }}</label>
              <select id="statusSelect" class="selectStatus" style="width: 225px;" [(ngModel)]="valueSearchStatusProposal" (change)="FindProposal()">
                  <option *ngFor="let status of proposalStatusName" [value]="status.valueEnglish">{{ status.valuePortugues }}</option>
              </select>
          </div>
          </div>
      
          <div class="form-group" style="display: flex; flex-direction: column;">
            <label>{{  appSetting.LG_PTBR.inicialDate }}</label>
            <input 
              type="date" 
              id="datePickerInicial" 
              style="width: 125px;" 
              [(ngModel)]="inicialDateSelected" 
              class="form-control date-input">
          </div>
          
          <div class="form-group" style="display: flex; flex-direction: column;">
            <label>{{  appSetting.LG_PTBR.fianalDate }}</label>
            <input 
              type="date" 
              id="datePickerFinal" 
              style="width: 125px;" 
              [(ngModel)]="finalDateSelected" 
              class="form-control date-input">
          </div>
    
          <div class="search-box">
            <div class="ContainerSearchCpf">
              <label>{{ appSetting.LG_PTBR.cpf }}</label>
              <input type="text" id="cpfInput" style="width: 160px" mask="000.000.000-00" [(ngModel)]="valueSearch">
            </div>
          </div>
    
        <button class="buttonSearch" (click)="FindProposal()" *ngIf="this.loadingProposals == false" title="Buscar">
          <i class="material-icons">search</i>
        </button>
      </div>

      <div>
        <span
          (click)="FindProposal()"
          [ngClass]="{'buttonDisabled': loadingButtonProposals === false}"
          class="material-symbols-outlined iconReload">
          autorenew
        </span>
      </div>
  </div>
  <div class="borderWhiteContainer">
    <div class="grayContent">
     <div class="divTable" *ngIf="!isMobileView">
      <table *ngIf="!loadingProposals">
        <thead>
          <tr style="background: #2E964B;">
            <th>{{ appSetting.LG_PTBR.cpf }}</th>
      
            <th (click)="sortData('name')" style="cursor: pointer;">
              <div style="display: flex; align-items: center; justify-content: center;">
                <span>{{ appSetting.LG_PTBR.name }}</span>
                <span class="material-symbols-outlined" style="font-size: 16px;">
                  {{ sortField === 'name' ? (sortAscending ? 'arrow_drop_up' : 'arrow_drop_down') : 'expand_all' }}
                </span>
              </div>
            </th>
      
            <th>{{ appSetting.LG_PTBR.number }}</th>
      
            <th (click)="sortData('creation_date')" style="cursor: pointer;">
              <div style="display: flex; align-items: center; justify-content: center;">
                <span>{{ appSetting.LG_PTBR.registrationDate }}</span>
                <span class="material-symbols-outlined" style="font-size: 16px;">
                  {{ sortField === 'creation_date' ? (sortAscending ? 'arrow_drop_up' : 'arrow_drop_down') : 'expand_all' }}
                </span>
              </div>
            </th>
      
            <th (click)="sortData('status')" style="cursor: pointer;">
              <div style="display: flex; align-items: center; justify-content: center;">
                <span>{{ appSetting.LG_PTBR.status }}</span>
                <span class="material-symbols-outlined" style="font-size: 16px;">
                  {{ sortField === 'status' ? (sortAscending ? 'arrow_drop_up' : 'arrow_drop_down') : 'expand_all' }}
                </span>
              </div>
            </th>
      
            <th (click)="sortData('modification_date')" style="cursor: pointer;">
              <div style="display: flex; align-items: center; justify-content: center;">
                <span>{{ appSetting.LG_PTBR.eventDate }}</span>
                <span class="material-symbols-outlined" style="font-size: 16px;">
                  {{ sortField === 'modification_date' ? (sortAscending ? 'arrow_drop_up' : 'arrow_drop_down') : 'expand_all' }}
                </span>
              </div>
            </th>
      
            <th>{{ appSetting.LG_PTBR.endorsement }}</th>
      
            <th (click)="sortData('amount_to_receive')" style="cursor: pointer;">
              <div style="display: flex; align-items: center; justify-content: center;">
                <span>{{ appSetting.LG_PTBR.valueReceiveded }}</span>
                <span class="material-symbols-outlined" style="font-size: 16px;">
                  {{ sortField === 'amount_to_receive' ? (sortAscending ? 'arrow_drop_up' : 'arrow_drop_down') : 'expand_all' }}
                </span>
              </div>
            </th>

            <th>{{ appSetting.LG_PTBR.actions }}</th>
          </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let data of list_value">
              <tr style="cursor: pointer;">
                <td (click)="copyValue(data.borrower.individual_document_number)">{{ format.formatCPF(data.borrower.individual_document_number) }}</td>
                <td (click)="openDetalhes(data.key, data.status)">{{ data.borrower.name }}</td>
                <td (click)="copyPhone(data.borrower.phone.area_code, data.borrower.phone.number)" style="min-width: 7rem;">{{ format.formatPhone(data.borrower.phone.area_code, data.borrower.phone.number) }}</td>
                <td (click)="openDetalhes(data.key, data.status)">{{ format.convertDateIsoInDate(data.creation_date) }}</td>
                <td (click)="openDetalhes(data.key, data.status)">{{ proposalStatus.traduzirStatus(data.status) }}</td>
                <td (click)="openDetalhes(data.key, data.status)">{{ format.convertDateIsoInDate(data.event_datetime) }}</td>
                <td (click)="openDetalhes(data.key, data.status)">{{ data.disbursement == true ? 'Sim' : 'Não' }}</td>
                <td (click)="openDetalhes(data.key, data.status)" [style.color]="(data.status !== 'canceled_permanently' &amp;&amp; data.status !== 'canceled') ? 'black' : 'transparent'">{{ format.formatValue(data.amount_to_receive) }}</td>
                <td>
                 <div style="display: flex; justify-content: space-around;">
                   <div *ngIf="data.status == 'waiting_signature'" style="display: flex; align-items: center; justify-content: center;">
                     <ng-icon 
                     name="bootstrapLink45deg"
                     onmouseover="this.style.color='#4ECD72'" 
                     onmouseout="this.style.color='black'" 
                     style="color: black; font-size: 25px;" 
                     (click)="copyLink(data.formalizationResponse.subjects[0].providerUrl, data.formalizationResponse.subjects[0].signUrl)"></ng-icon>
                   </div>
 
                   <i class="material-icons" style="cursor: pointer;" matTooltip="Reapresentaçao"  
                   [style.visibility]="data.status == 'invalid_account' ? 'visible' : 'hidden'" 
                   (click)="reapresentation(data)">menu</i>
                   
                   <i class="material-icons" style="cursor: pointer;" matTooltip="cancelar" 
                   [style.visibility]="data.status == 'disbursed' || data.status == 'failed' || data.status == 'settled' || data.status == 'canceled_permanently' ? 'hidden' : 'visible'" 
                   (click)="handleCancelProposal(data.key)">cancel</i>
                 </div>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="list_value.length > 0">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ format.formatValue(totalAmountToRecive) }} ({{ list_value.length }})</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        
        <div class="loading-spinner" *ngIf="loadingProposals">
          <mat-spinner></mat-spinner>
        </div>
      </div>


      <div class="list-container" *ngIf="isMobileView">
        <ul *ngIf="!loadingProposals">
          <ng-container *ngFor="let data of list_value">
            <li class="list-item">
              <div class="list-content">
                <div (click)="openDetalhes(data.key, data.status)">
                  <div (click)="copyValue(data.borrower.individual_document_number)">
                    <strong>{{ appSetting.LG_PTBR.cpf }}:</strong> {{ format.formatCPF(data.borrower.individual_document_number) }}
                  </div>
                  <div>
                  <strong>{{ appSetting.LG_PTBR.name }}:</strong> {{ data.borrower.name }}
                </div>
                <div (click)="copyPhone(data.borrower.phone.area_code, data.borrower.phone.number)">
                  <strong>{{ appSetting.LG_PTBR.number }}:</strong> {{ format.formatPhone(data.borrower.phone.area_code, data.borrower.phone.number) }}
                </div>
                <div>
                  <strong>{{ appSetting.LG_PTBR.registrationDate }}:</strong> {{ format.convertDateIsoInDate(data.creation_date) }}
                </div>
                <div>
                  <strong>{{ appSetting.LG_PTBR.status }}:</strong> {{ proposalStatus.traduzirStatus(data.status) }}
                </div>
                <div>
                  <strong>{{ appSetting.LG_PTBR.eventDate }}:</strong> {{ format.convertDateIsoInDate(data.event_datetime) }}
                </div>
                <div>
                  <strong>{{ appSetting.LG_PTBR.endorsement }}:</strong> {{ data.disbursement == true ? 'Sim' : 'Não' }}
                </div>
                <div>
                  <strong>{{ appSetting.LG_PTBR.valueReceiveded }}:</strong> {{ format.formatValue(data.amount_to_receive) }}
                </div>
              </div>
                <div class="action-buttons">
                  <button style="background-color: red;" *ngIf="data.status !== 'canceled_permanently' && data.status !== 'disbursed'" (click)="handleCancelProposal(data.key)">
                    Cancelar
                  </button>
                  <button *ngIf="data.status == 'waiting_signature'" (click)="copyLink(data.formalizationResponse.subjects[0].providerUrl, data.formalizationResponse.subjects[0].signUrl)">
                    Assinar
                  </button>
                  <button *ngIf="data.status == 'invalid_account'" (click)="reapresentation(data)">
                    Reapresentar
                  </button>
                </div>
              </div>
            </li>
          </ng-container>
          <li *ngIf="list_value.length > 0" class="total-item">
            Total a Receber: {{ format.formatValue(totalAmountToRecive) }} ({{ list_value.length }} propostas)
          </li>
        </ul>
      
        <div class="loading-spinner" *ngIf="loadingProposals">
          <mat-spinner></mat-spinner>
        </div>
      </div>


    </div>
  </div>
</div>