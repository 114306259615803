<div class="section">
    <div class="divConsulta">
        <div class="star" *ngIf="_data.classificationCode && _data.classificationDescription"  [matTooltip]="_data.classificationDescription">
            <div class="number">{{_data.classificationCode}}</div>
        </div>
        <span (click)="copyValue(document_number)" class="info-value" style="color: white; font-size: 13px; font-weight: bold; margin: 6px;">
            {{ appSetting.LG_PTBR.cpf }}:
            {{ document_number }}
        </span>
            <mat-icon (click)="openMenu()">archive</mat-icon>
    </div>

    <div class="containerInfos" (dblclick)="openContractSimulation()">
        <p style="margin: 5px 0 2px; display: flex; align-items: center;">
            <span class="info-label" [style.color]="mainFontColor">
                {{ appSetting.LG_PTBR.phone }}:
            </span>
            <span class="info-value" *ngIf="phone_true">{{ phoneFormatted }}
                <mat-icon *ngIf="phone_true" onmouseover="this.style.color='green'"
                    onmouseout="this.style.color='black'" (click)="copyValue(phone)"
                    style="font-size: 20px; margin-top: -10px; color: black; margin-bottom: -10px">
                    file_copy
                </mat-icon>
            </span>
            <i class='fab fa-whatsapp' style="font-size: 22px;" *ngIf="phone_true" (click)="openWhatsAppDirect()"></i>
        </p>
        <p style="margin: 0 0 2px; display: flex; gap: 5px;">
            <span class="info-label" [style.color]="mainFontColor">{{ appSetting.LG_PTBR.date }}:</span>
            <span class="info-value">{{ format.convertDateIsoInDate(_data.creationDate) }}
            </span>
        </p>
        <p style="display: flex; margin: 0 0 2px; gap: 3px; align-items: center;">
            <span class="info-label" [style.color]="mainFontColor">{{ appSetting.LG_PTBR.disponibleValue }}:</span>
            <span class="info-value">{{ _data.statusFront !== 'Sem Retorno' && _data.statusFront !== 'Processando Consulta' ? format.formatValue(_data.valueSimulation) : "-" }}</span>
            <mat-icon 
                *ngIf="_data.statusFront !== 'Sem Retorno' && _data.statusFront !== 'Processando Consulta'"                
                onmouseover="this.style.color='green'"
                onmouseout="this.style.color='black'" 
                (click)="copyScriptValueSimulation(format.formatValue(_data.valueSimulation))"
                style="font-size: 20px; margin-top: -10px; margin-bottom: -10px">
                file_copy
            </mat-icon>
        </p>

        <div style="word-wrap: break-word">
            <div style="display: flex; align-items: center; gap: 3px;">
                <span class="info-label" [style.color]="mainFontColor">{{ appSetting.LG_PTBR.status }}:</span>
                <div style="display: flex; gap: 5px; width: 135px;">
                    <span class="info-value" style="display: inline-flex;" 
                        [matTooltip]="this._data.errorActionClient">
                        {{ _data.statusFront }} 
                        <mat-spinner *ngIf="spinner" [diameter]="18"></mat-spinner>
                    </span>
                    <span class="material-symbols-outlined" style="font-size: 18px;" *ngIf="_data.status == 'success'" (click)="openChangeStatusFila(_data.key)">
                        sync_alt
                    </span>
                </div>
                <span class="material-symbols-outlined" style="position: relative; margin-left: 20px;" [ngStyle]="{'color': colorTicket}">
                    sell
                </span>
            </div>
        </div>
    </div>
</div>