import { Component,Input } from '@angular/core';
import { AppSetting } from 'src/app/entities/utils/appsettings';
import { CorService } from '../../services/color.service';
import { SimulationModuleService } from 'src/app/services/simulation-module.service';
import { BanksList } from 'src/app/services/banksList.service';
import { Format } from 'src/app/services/format.service';



@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})


export class ResumeComponent {

  constructor (
    public format: Format,
    private corService: CorService,
    public simulation_service: SimulationModuleService,
    public bankList: BanksList,
    ){
    this.colorPrimary = this.colorPrimary === '' ? '#4ECD72' : this.colorPrimary;
    this.colorSecond = this.colorSecond === '' ? '#FFF' : this.colorSecond;
  }

  public appSetting = new AppSetting()
  installments: string = ''
  totalAmount=0
  principalAmortizationAmountSum= 0
  releasedValue= 0
  preFixedAmountSum= 0
  dataTransform: any
  
  @Input() borrower: any
  @Input() aditionalData: any
  @Input() _data: any
  @Input() disbursementBankAccount: any

  issuing: any = new Date()
  feeAmount = ''
  colorPrimary: string = this.corService.colorPrimary
  colorSecond: string = this.corService.colorSecond
  simulatioinColor: string = this.corService.simulatioinColor
  iduser: any = localStorage.getItem("userId");
  pf: boolean = true
  installmentsValue = []
  bankInformations: any
  showDate: string = ""


  ngOnInit(){
    this.showDate = this.borrower.birthDate
    this.findAllvalue(this._data.resume)
    this.dataTransform = this.borrower.birthDate
    this.feeAmount = this._data.feeAmount
    for (let i of this._data.resume){
      this.totalAmount += i.totalAmount
      this.principalAmortizationAmountSum += i.principalAmortizationAmount;
    }

    this.simulation_service.getUser(this.iduser).subscribe( (result: any) => {
      this.pf = result.salesPF
      console.log(this.pf)
    })

    if(this.borrower.profession == "") {
      this.borrower.profession = "Outros"
   }

   if(this.borrower.email == "") {
     this.borrower.email = "naoinformado@gmail.com"
  }



   this.bankInformations = this.bankList.bancos.find(banco => {
    return banco.valor == this.disbursementBankAccount.bankCode
   })

  }

  findAllvalue(value: any){
    this.installmentsValue = value
    this.installments = value.length
    for (let i of value){
      this.releasedValue += i.duePrincipal;
    }
  }

  StatusMatrimonialtraductor(status: string) {
    switch (status) {
        case "single":
            return "Solteiro";
        case "married":
            return "Casado";
        case "widower":
            return "Viúvo";
        case "divorced":
            return "Divorciado";
        default:
            return status;
    }
  }
}
