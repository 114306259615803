<div class="containerBody">
    <div class="container">
        <div class="circle" id="circle1"><div class="text" id="text1">1</div></div>
        <div class="line"></div>
        <div class="circle" id="circle2"><div class="text" id="text2">2</div></div>
        <div class="line"></div>
        <div class="circle" id="circle3"><div class="text" id="text3">3</div></div>
        <div class="line"></div>
        <div class="circle" id="circle4"><div class="text" id="text4">4</div></div>
    </div>
    <div class="container">
        <div>
            <h2 style="font-size: 16px; margin-bottom: 5px;">Acesso ao Aplicativo do FGTS</h2>
            <p class="textInformation">O cliente deve ter acesso ao aplicativo oficial do FGTS</p>
        </div>
        <div>
            <h2 style="font-size: 16px; margin-bottom: 5px;">Opção por Saque Aniversário</h2>
            <p class="textInformation">Certifique-se de que o cliente ja tenha optado pela modalidade de Saque Aniversário no aplicativo</p>
        </div>
        <div>
            <h2 style="font-size: 16px; margin-bottom: 5px;">Gerar Autorização de Consulta</h2>
            <p class="textInformation">No aplicativo do FGTS, o cliente deve gerar uma autorização de consulta de saldo para o banco "QI Sociedade de Crédito". Essa permissão é indispensável para que o Finanto Pay possa acessar as informações de saldo</p>
        </div>
        <div>
            <h2 style="font-size: 16px; margin-bottom: 5px;">Preenchimento do CPF</h2>
            <p class="textInformation">Insira o CPF do cliente no campo indicado e selecione a opção "Consultar" para proceder com a verificação do saldo</p>
        </div>
    </div>
</div>
