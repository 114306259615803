import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';

bootstrapApplication(AppComponent, {
    providers: [
        // Outros provedores...
        provideEnvironmentNgxMask(),
        // Outros provedores...
    ],
}).catch((err: any) => console.log(err));


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
