<div class="recebimento-component">
    <h2>Informações Bancárias</h2>
    <div class="inputs">



        <div class="form-group">
            <mat-form-field class="app-flex" hideRequiredMarker="true">
                <mat-select [(ngModel)]="bank_name" name="bank_name" placeholder="Seleciona o Banco">
                    <mat-option [value]="-1"></mat-option>
                    <mat-option  *ngFor="let i of bank_list" [value]="i.name">
                        {{i.code}} - {{i.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field class="app-flex" hideRequiredMarker="true">
                <mat-select [(ngModel)]="account_type_select" name="bank_name" placeholder="Seleciona o Tipo de Conta">
                    <mat-option [value]="-1"></mat-option>
                    <mat-option  *ngFor="let i of account_type" [value]="i.name">
                   {{i.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field class="app-flex" hideRequiredMarker="true">
                <input matInput type="text" [(ngModel)]="bank_agency" name="bank_agency" placeholder="agencia">
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field class="app-flex" hideRequiredMarker="true">
                <input matInput type="text" [(ngModel)]="bank_account" name="bank_account" placeholder="Conta">
            </mat-form-field>
        </div>

    </div>
</div>