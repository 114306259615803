import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppSetting } from '../entities/utils/appsettings';
import { CorService } from '../services/color.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  
})
export class HeaderComponent {
  
  constructor(
    public router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
    private corService: CorService

  ) {
    this.colorSecond = this.colorSecond === '' ? '#FFF' : this.colorSecond;
    this.renderer.listen('document', 'click', (event: MouseEvent) => {
      if (this.rotateImage) {
        const menuElement = this.el.nativeElement.querySelector('.image-container');
        if (!menuElement.contains(event.target as Node)) {
          this.rotateImage = false;
        }
      }
    });
    
  }

  public appSetting = new AppSetting()
  rotateImage = false; 

  LogoCompany: string = this.corService.logoURL;

  colorSecond: string = this.corService.colorSecond

    toggleRotation() {
      this.rotateImage = !this.rotateImage;
    }

      consult(){
        this.rotateImage = false;
        return this.router.navigate(['consult']);
      }
      followUp(){
        this.rotateImage = false;
        return this.router.navigate(['monitoring']);
      }

      ReturnMenu(){
        return this.router.navigate(['/']);
      }


}
