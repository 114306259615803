import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AppSetting {
LG_PTBR = {

    invalidLicenseMessage: 'Sua licença expirou! Por favor contate o suporte ou administrador do sistema.',
    id: 'ID',
    copyContract: 'Copiar Link',
    advancedFilters: 'Filtros Avançados',
    clearFilters: 'Limpar Filtros',
    historyClient: 'Histórico do Cliente',
    edit: 'Editar',
    clean: 'Limpar',
    delete: 'Excluir',
    Status: 'Status',
    maxPermittedLeadsPerCN: 'Qtd de Leads trabalhados por CN (dia)',
    deleteAll: 'Excluir todos',
    deleteAllButThis: 'Excluir todos menos este',
    print: 'Imprimir',
    export: 'Exportar',
    confirm: 'Confirmar',
    apply: "Aplicar",
    cancel: 'Cancelar',
    download: 'Download',
    bucketFilter: 'Filtros de Importações',
    reload: 'Recarregar',
    reloadFilter: 'Recarregar Filtro',
    myFilters: 'Meus Filtros',
    replaceCurrentFilter: 'Sobrepor Filtro Atual',
    saveNewFilter: 'Salvar Novo Filtro',
    filterMenu: 'Menu de Filtro',
    selectedFilter: 'Filtro Selecionado',
    loading: 'Carregando...',
    updateStatus: 'Alterar Status',
    simulationDate: 'Data da Simulação',
    register: 'Cadastro',
    retentionFilter: 'Filtro de Retenção',
    log: 'Log',
    fullEdit: 'Edição Completa',
    userName: 'Nome do Usuário',
    lastUpdate: 'Última Atualização',
    basic: 'Básico',
    advanced: 'Avançado',
    document: 'Documento',
    documents: 'Documentos',
    contractOrigins: 'Contratos de Origem',
    files: 'Arquivos',
    settings: 'Configurações',
    dateHour: 'Data/Hora',
    goBack: 'Voltar',
    yes: 'Sim',
    no: 'Não',
    noSave: 'Não Salvar',
    ok: 'OK',
    retry: 'Tentar Novamente',
    abort: 'Abortar',
    Ignore: 'Ignorar',
    restoreDefault: 'Restaurar Padrão',
    save: 'Salvar',
    contact: 'Contato',
    contacts: 'Contatos',
    saveFilter: 'Salvar Filtro',
    enter: 'Entrar',
    integrations: 'Integrações',
    integration: 'Integração',
    selectIntegration: 'Selecione uma Integração',
    exit: 'Sair',
    continue: 'Continuar',
    rectify: 'Corrigir',
    personalData: 'Dados Pessoais',
    customerFiles: 'Arquivos do Cliente',
    noFilesUploaded: 'Nenhum arquivo carregado',
    registeAtTheBank: 'Digitar no Banco',
    record: 'Registro',
    feature: 'Recurso',
    attribute: 'Atributo',
    attributes: 'Atributos',
    securityPrestamist: 'Seguro Prestamista',
    attributeFilter: 'Filtro de Atributo',
    userLevelGroupFilter: 'Filtro de Grupo de Nível de Acesso',
    recordFilter: 'Filtro de Registros',
    attributeTypes: 'Tipos de Attributos',
    selectOrFilterAnAttributeType: 'Selecione ou filtre um tipo de atributo',
    selectOrFilterAnFeatureType: 'Selecione ou filtre um recurso',
    account: 'Conta',
    rule: 'Regra',
    validate: 'Validar',
    rules: 'Regras',
    userProfileFilter: 'Filtro de Perfil de Acesso',
    sendToValidation: 'Enviar para validação',
    searchSubtitle: 'Consulta',
    newCommand: 'Novo',
    logoutUsers: 'Deslogar Usuários',
    logoutUser: 'Deslogar Usuário',
    new: 'Novo',
    newType: 'Novo Tipo',
    newPage: 'Nova Página',
    loanFiles: 'Arquivos do Empréstimo',
    loanSettings: 'Configurações de Empréstimo',
    update: 'Atualizar',
    alter: 'Alterar',
    select: 'Selecionar',
    visualize: 'Visualizar',
    openInNewWindow: 'Abrir em uma nova janela',
    openContract: 'Abrir Contrato',
    disabled: 'Desativado',
    enabled: 'Ativado',
    maturitySettings: 'Configurações de Vencimento',
    saveMappingModel: 'Salvar modelo de mapeamento',
    search: 'Consulta',
    messages: 'Mensagens',
    agency: 'Agência',
    textFilter: 'Filtro de Texto',
    text: 'Texto',
    assine: 'Assinar',
    filter: 'Filtro',
    searchSetting: 'Localizar configuração',
    filters: 'Filtros',
    addFilter: 'Adicionar Filtro',
    addItem: 'Adicionar Item',
    addFiles: 'Adicionar Arquivos',
    order: 'Ordem',
    ordination: 'Ordenação',
    ordinationField: 'Campo de ordenação',
    ordinationType: 'Tipo da ordenação',
    orderBy: 'Ordenação Por',
    ascending: 'Crescente',
    descending: 'Decrescente',
    orderApplyError: 'Ordenação não aplicada. Campos não selecionados.',
    classifyAToZ: 'Classificar de A - Z',
    classifyZtoA: 'Classificar de Z - A',
    changeStatus: 'Alterar Status',
    selectFile: 'Selecionar Arquivo',
    back: 'Voltar',
    backTo: 'Voltar para',
    addLevel: 'Adicionar Nível',
    removeLevel: 'Remover Nível',
    exportMapping: 'Exportar Mapeamento',
    restoreDefaultQuestion: 'Deseja restaurar as configurações padrões?',
    saveEditionsQuestion: 'Deseja salvar as alterações?',
    restoreFactorySettingsQuestion: 'Deseja restaurar as configurações de fábrica?',
    calculateMaturityPaymentsQuestion: 'Deseja recalcular os pagamentos do vencimento?',
    calculateAllPaymentsQuestion: 'Deseja recalcular os pagamentos de todos vencimentos?',
    updateLoanBankStatusQuestion: 'Deseja atualizar o status do empréstimo no banco?',
    syncWithBankQuestion: 'Deseja sincronizar com o banco?',
    processFileQuestion: 'Deseja processar o arquivo?',
    openLeadQuestion: 'Deseja abrir lead?',
    fieldDeleteQuestion: 'Deseja excluir o campo selecionado?',
    sendValidationQuestion: 'Deseja enviar para validação?',
    deleteFilterQuestion: 'Deseja excluir o filtro?',
    createLeadQuestion: 'Deseja criar um Lead?',
    createProposalByLeadQuestion: 'Deseja criar uma proposta?',
    createProposalByRetentionQuestion: 'Deseja criar uma proposta?',
    registerLoanAtTheBankQuestion: 'Deseja digitar o empréstimo no banco?',
    createSelectedProposalsQuestion: 'Deseja criar as propostas selecionadas?',
    createSelectedProposalQuestion: 'Deseja criar a proposta selecionada?',
    deleteAccessLevelQuestion: 'Deseja excluir o nível de acesso?',
    Bankdata: 'Dados Bancários',
    deleteSimulationQuestion: 'Deseja excluir a simulação?',
    logoutAllUsersQuestion: 'Deseja deslogar todos os usuários?',
    logout: 'Deslogar',
    logoutUserQuestion: 'Deseja deslogar o usuário?',
    wantToKeepTheDataQuestion: 'Deseja manter os dados?',
    result: 'Resultado',
    event: 'Evento',
    events: 'Eventos',
    required: 'Obrigatório',
    isRequired: 'é obrigatório',
    creationDate: 'Data de Criação',
    DesembolsoDate: 'Data de Desembolso',
    CarencyPrincipal: 'Carência da Principal',
    CarencyFees: 'Carência de Juros',
    creationUser: 'Usuário de Criação',
    creation: 'Criação',
    creationUserId: 'Id do Usuário de Criação',
    creationUserName: 'Nome do Usuário de Criação',
    changeDate: 'Data de Alteração',
    changeBy: 'Alterado por',
    changeUser: 'Usuário de Alteração',
    change: 'Alteração',
    changeUserId: 'Id do Usuário de Alteração',
    changeUserName: 'Nome do Usuário de Alteração',
    exclusionDate: 'Data de Exclusão',
    exclusionUserId: 'Id do Usuário de Exclusão',
    exclusionUserName: 'Nome do Usuário de Exclusão',
    recordStatusCode: 'Código Status do Registro',
    recordStatusName: 'Nome do Status do Registro',
    versionId: 'ID da Versão do Registro',
    previousId: 'ID da Versão Anterior do Registro',
    versionDate: 'Data da Versão do Registro',
    code: 'Código',
    tableCode: 'Código da Tabela',
    codes: 'Códigos',
    active: 'Ativo',
    description: 'Descrição',
    baseUrl: 'URL Base',
    title: 'Título',
    subtitle: 'Subtítulo',
    whatsapp: 'Whatsapp',
    whatsappButton: 'Botão do Whatsapp',
    themeColor: 'Cor do Tema',
    sideImagePosition: 'Posição da Imagem Lateral',
    name: 'Nome',
    companyName: 'Razão Social',
    companyNickname: 'Nome Fantasia',
    companyStart: 'Data de Abertura',
    type: 'Tipo',
    locality: 'Município',
    selectALocality: 'Selecione um Município',
    state: 'Estado',
    selectAState: 'selecione um Estado',
    section: 'Seção',
    sections: 'Seções',
    types: 'Tipos',
    color: 'Cor',
    status: 'Status',
    statusHistory: 'Histórico de Status',
    statusInTheBank: 'Status no Banco',
    payerType: 'Tipo de Pagador',
    syncWithBank: 'Sincronizar com Banco',
    syncWithBanks: 'Sincronizar com Bancos',
    productionRule: 'Regra de Produção',
    actionTipe: 'Tipo de Ação',
    formulaOnTypes: 'Fórmula sobre os tipos',
    targetFormulaOnTypes: 'Fórmula da Meta sobre os tipos',
    commissionFormulaOnTypes: 'Fórmula da Comissão sobre os tipos',
    creationSuccess: 'Cadastro realizado com sucesso.',
    creationProposalSuccess: 'Proposta cadastrada com sucesso.',
    updateSuccess: 'Atualização realizada com sucesso.',
    cancelSuccess: 'Cancelamento realizado com sucesso.',
    deleteSuccess: 'Exclusão realizada com sucesso.',
    statusUpdateSuccess: 'Status atualizado com sucesso.',
    passwordUpdateSuccess: 'Senha atualizada com sucesso.',
    photoUpdateSuccess: 'Foto alterada com sucesso.',
    number: 'Número',
    payer: 'Pagador',
    documentRg: 'Documento (RG)',
    documentNumber: 'Número do Documento',
    contractNumber: 'Número do Contrato',
    ExternIdentificator: 'Identificador Externo',
    documentIssuingDate: 'Data de Emissão do Documento',
    documentIssuingEntity: 'Orgão Emissor do Documento',
    documentIssuingState: 'Estado Emissor do Documento',
    processSuccess: 'Processo realizado com sucesso.',
    pixTransferType: 'Tipo de transferência pix',
    pixKey: 'Chave pix',
    category: 'Categoria',
    issuingDate: 'Data de Emissão',
    issuing: 'Emissão',
    find: 'Consultar',
    mappings: 'Mapeamentos',
    filtrate: 'Filtrar',
    newBucket: 'Novo Importação',
    bucketSearch: 'Consulta de Importação',
    records: 'Registros',
    groupOf: 'Grupo de',
    noRecords: 'Nenhum Registro',
    payments: 'Pagamentos',
    product: 'Produto',
    products: 'Produtos',
    newProduct: 'Novo Produto',
    age: 'Idade',
    year: 'Ano',
    years: 'Anos',
    month: 'Mês',
    months: 'Meses',
    motherName: 'Nome da Mãe',
    parents: 'Pais',
    day: 'Dia',
    days: 'Dias',
    dates: 'Datas',
    next: 'Próximo',
    previous: 'Anterior',
    conditions: 'Condições',
    options: 'Opções',
    otherOptions: 'Outras Opções',
    countryIdentityNaturalPerson: 'CPF',
    countryIdentityLegalEntity: 'CNPJ',
    customerCountryIdentityNaturalPerson: 'CPF Cliente',
    cpf: 'CPF',
    cnpj: 'CNPJ',
    naturalPerson: 'Pessoa Física',
    recording: 'Registro',
    invalidData: 'Dados inválidos',
    invalidEmail: 'Email inválido',
    invalid: 'Inválido',
    proposal: 'Proposta',
    proposalStatusComplete: 'Status Da Proposta',
    proposalNumber: 'Numero da Proposta',
    ParcelNumber: 'Número de Parcelas',
    proposals: 'Propostas',
    externalCode: 'Código Externo',
    externalId: 'ID Externo',
    display: 'Exibição',
    identity: 'Identidade',
    loadCustomer: 'Carregar Cliente',
    displayField: 'Campo de Exibição',
    updateType: 'Tipo de Atualização',
    identityField: 'Campo de Identidade',
    storeRequired: 'Necessário Selecionar uma Loja',
    dialerStatus: 'Status no Discador',
    dialerBrokerCode: 'Código do Agente',
    dialerUser: 'Login do Usuário',
    dialerPassword: 'Senha do Usuário',
    dialerExtension: 'Ramal do Discador',
    phoneExtension: 'Ramal',
    dialerExtensions: 'Ramais',
    noDialerExtensions: 'Não há ramais listados',
    noDialerPauses: 'Não há pausas listadas',
    dialerQueue: 'Campanha/Fila no Discador',
    dialer: 'Discador',
    dialerBrokerLogin: 'Logar Agente',
    dialerLoginSuccess: 'Agente Logado com Sucesso',
    dialerBrokerPause: 'Pausar Agente',
    dialerPauseSuccess: 'Agente Pausado com Sucesso',
    dialerBrokerPauseOut: 'Sair da Pausa',
    dialerPauseOutSuccess: 'Retornado com Sucesso',
    dialerBrokerLogout: 'Deslogar Agente',
    dialerLogoutSuccess: 'Agente Deslogado com Sucesso',
    dialerCreateBroker: 'Criar Agente',
    dialerCreateBrokerSuccess: 'Agente Criado com Sucesso',
    dialerAuthenticationRequired: 'Autenticação no Discador Necessária',
    recordCode: 'Código do Registro',
    paymentValue: 'Valor do Pagamento',
    valuebirthday: 'Valor Saque Aniversário',
    valueofRate: 'Valor da Taxa',
    tarifesAndTax: 'Tarifas e Taxa',
    nickname: 'Apelido',
    userCode: 'Código do Usuário',
    accessLevelGroup: 'Grupo de Níveis de Acesso',
    typeCode: 'Código do Tipo',
    typeName: 'Nome do Tipo',
    personType: 'Tipo de Pessoa',
    cpfCnpj: 'CPF/CNPJ',
    sex: 'Sexo',
    maritalStatus: 'Estado Civil',
    AccountDigit: 'Digito da Conta',
    weddingDate: 'Data de Casamento',
    educationLevel: 'Grau de Instrução',
    homeCondition: 'Condição de Ocupação do Domicílio',
    homeOccupationDate: 'Data de Ocupação do Domicílio',
    profession: 'Profissão',
    role: 'Cargo',
    admissionDate: 'Data de Admissão',
    grossIncome: 'Renda Bruta',
    netIncome: 'Renda Líquida',
    otherIncome: 'Outras Rendas',
    paymentDay: 'Dia do Pagamento',
    payment: 'Pagamento',
    monthlyBilling: 'Faturamento Mensal',
    annualBilling: 'Faturamento Anual',
    of: 'de',
    employees: 'Funcionários',
    equityValue: 'Valor Patrimonial',
    personTypeCode: 'Código do Tipo de Pessoa',
    personTypeName: 'Nome do Tipo de Pessoa',
    countryIdentity: 'CPF/CNPJ',
    statusCode: 'Código do Status',
    statusName: 'Status da Consulta',
    statusMatrimonial: 'Status Matrimonial',
    statusDate: 'Data do Status',
    statusColor: 'Cor do Status',
    statusNote: 'Observação do Status',
    alert: 'Alerta',
    alertCode: 'Código do Alerta',
    alertName: 'Nome do Alerta',
    alertDate: 'Data do Alerta',
    alertColor: 'Cor do Alerta',
    city: 'Cidade',
    alertNote: 'Observação do Alerta',
    address: 'Endereço',
    addresses: 'Endereços',
    addressZipCode: 'CEP',
    addressStreetHint: '(rua, avenida, praça, etc.)',
    addressStreet: 'Endereço',
    addressNumber: 'Número',
    addressComplement: 'Complemento',
    done: 'Concluido',
    addressDistrict: 'Bairro',
    addressCity: 'Cidade',
    addressState: 'UF',
    addressType: 'Tipo de Endereço',
    phones: 'Telefones',
    emails: 'Emails',
    email: 'E-mail',
    tags: 'Marcadores',
    tagCodes: 'Códigos de Marcadores',
    message: 'Mensagem',
    note: 'Observação',
    CommissionPercentage: 'Porcentagem de Comissão',
    updateSearchAutomatically: 'Atualizar consulta automaticamente',
    facebookPixel: 'Pixel do Facebook',
    selectAll: 'Selecionar todos',
    begin: 'Início',
    end: 'Fim',
    initial: 'Inicial',
    final: 'Final',
    optional: 'Opcional',
    date: 'Data',
    hour: 'Hora',
    size: 'Tamanho',
    updateHistory: 'Histórico de Atualização',
    user: 'Usuário',
    company: 'Empresa',
    companyCode: 'Código da Empresa',
    accountName: 'Nome da Conta',
    accountCode: 'Código da Conta',
    supportCode: 'Código de Suporte.',
    close: 'Fechar',
    otherFilters: 'Outros Filtros',
    others: 'Outros',
    benefitNumber: 'Número do Benefício',
    benefitType: 'Tipo de Benefício',
    benefitValue: 'Valor do Benefício',
    benefit: 'Benefício',
    editBenefit: 'Editar Benefício',
    value: 'Valor',
    values: 'Valores',
    origin: 'Origem',
    add: 'Adicionar',
    inputType: 'Tipo de Entrada',
    loanSearch: 'Consulta de Empréstimo',
    loanFilter: 'Filtros de Empréstimo',
    newLoan: 'Novo Empréstimo',
    loan: 'Empréstimo',
    loans: 'Empréstimos',
    loanId: 'Id do Empréstimo',
    loanCode: 'Código do Empréstimo',
    internalCode: 'Código do Interno',
    internal: 'Interno',
    inss: 'INSS',
    mapFields: 'Mapear Campos',
    fields: 'Campos',
    externalFields: 'Campos Externos',
    totalFields: 'Total de Campos',
    mapped: 'Mapeado',
    notMapped: 'Não Mapeado',
    processFile: 'Processar Arquivo',
    summaryByStatus: 'Resumo por Status',
    updateSummary: 'Atulizar Resumo',
    refreshSimulations: 'Atualizar Simulações',
    recalculateSimulations: 'Recalcular Simulações',
    deleteSimulation: 'Excluir Simulação',
    newSimulation: 'Nova Simulação',
    factoryStandard: 'Padrão de Fábrica',
    restoreFactoryStandard: 'Restaurar Padrão de Fábrica',
    selectProduct: 'Selecionar Produto',
    calculatePayments: 'Calcular Pagamentos',
    step: 'Etapa',
    productExternalCode: 'Código Externo do Produto',
    productName: 'Nome do Produto',
    storeExternalCode: 'Código Externo da Loja',
    storeName: 'Nome da Loja',
    brokerExternalCode: 'Código Externo do Agente',
    brokerName: 'Nome do Agente',
    lenderPersonTypeCode: 'Código do Tipo de Pessoa do Credor',
    lenderPersonTypeName: 'Nome do Tipo de Pessoa do Credor',
    lenderCountryIdentity: 'CPF/CNPJ do Credor',
    issuerCode: 'Código do Emissor',
    issuerExternalCode: 'Código Externo do Emissor',
    issuerPersonTypeCode: 'Código do Tipo de Pessoa do Emissor',
    issuerPersonTypeName: 'Nome do Tipo de Pessoa do Emissor',
    issuerCountryIdentity: 'CPF/CNPJ do Emissor',
    issuerName: 'Nome do Emissor',
    issuerProductCode: 'Código do Produto no Emissor',
    customerCode: 'Código do Cliente',
    customerCodeBegin: 'Código do Cliente - Início',
    customerCodeEnd: 'Código do Cliente - Fim',
    customerExternalCode: 'Código Externo do Cliente',
    customerPersonTypeCode: 'Código do Tipo de Pessoa do Cliente',
    customerPersonTypeName: 'Nome do Tipo de Pessoa do Cliente',
    customerCountryIdentity: 'CPF/CNPJ',
    customerName: 'Nome do Cliente',
    customerBirthDate: 'Data de Nascimento do Cliente',
    customerAge: 'Idade do Cliente',
    originType: 'Tipo de Origem',
    originTypes: 'Tipos de Origem',
    originTypeCode: 'Código do Tipo de Origem',
    originTypeName: 'Nome do Tipo de Origem',
    originCode: 'Código da Origem',
    originExternalCode: 'Código Externo da Origem',
    originName: 'Nome da Origem',
    selectALender: 'Selecione um Credor',
    originalLoanCode: 'Código do Empréstimo Original (Refinanciamento)',
    originalContractNumber: 'Número do Contrato Original (Refinanciamento)',
    statusExternalCode: 'Código Externo do Status (Campo utilizado para integração com outros sistemas)',
    alertExternalCode: 'Código Externo do Alerta',
    contractDate: 'Data do Contrato',
    proposalDate: 'Data da Proposta',
    baseDate: 'Data Base',
    commissionDate: 'Data da Comissão',
    creditDate: 'Data do Crédito',
    customerCreditDate: 'Data do Crédito ao Cliente',
    firstMaturityDate: 'Data do Primeiro Vencimento',
    lastMaturityDate: 'Data do Último Vencimento',
    maturityDate: 'Data do Vencimento',
    simulationValue: 'Valor da Simulação',
    disponibleValue: 'Vlr. Disponível',
    loanValue: 'Valor do Empréstimo',
    loanValues: 'Valor dos Empréstimos',
    debitBalance: 'Saldo Devedor',
    inputValue: 'Valor da Entrada',
    emissionValue: 'Valor da Emissão',
    netValue: 'Valor Líquido',
    selectedSimulations: 'Simulações Selecionadas',
    customerNetValue: 'Valor Líquido ao Cliente',
    baseValue: 'Valor Base',
    creditFeeValue: 'Valor da Taxa de Abertura de Crédito (TAC)',
    costsValue: 'Valor dos Custos da Operação',
    insurancesValue: 'Valor dos Seguros',
    insuranceCode: 'Código do Seguro',
    financedCostsValue: 'Valor dos Custos Financiados',
    unfundedCostsValue: 'Valor dos Custos Não Financiados',
    totalValue: 'Valor Total',
    maturityValue: 'Valor da Parcela',
    totalMaturitiesValue: 'Valor Total das Parcelas',
    maturityInsurancesValue: 'Valor dos Seguros dos Vencimentos',
    amountPaid: 'Valor Pago',
    discountValue: 'Valor do Desconto',
    loanBalance: 'Saldo do Empréstimo',
    loanBalanceDate: 'Data do Saldo do Empréstimo',
    appropriateValue: 'Valor Apropriado',
    percentagePaid: 'Percentual Pago',
    dischargeValue: 'Valor para Quitação',
    latePenaltyValue: 'Valor Multa',
    lateInterestValue: 'Valor dos Juros',
    interestDays: 'Dias de Atraso',
    lastPaymentDate: 'Data do Último Pagamento',
    totalDays: 'Total de Dias da Operação',
    maturitiesPaid: 'Quantidade de Parcelas Pagas',
    rateMonth: 'Taxa Mês',
    rateYear: 'Taxa Ano',
    totalEffectiveCost: 'Custo Efetivo Total (CET) Frequancia de Pagamento',
    totalEffectiveCostMonth: 'Custo Efetivo Total (CET) Mês',
    totalEffectiveCostYear: 'Custo Efetivo Total (CET) Anual',
    iofValue: 'Valor do IOF',
    cpmfValue: 'Valor do CPMF',
    taxValues: 'Valor dos Impostos (-)',
    creditFeeTyp: 'Tipo de Taxa de Abertura de Crédito (TAC)',
    creditFee: 'Taxa de Abertura de Crédito (TAC)',
    paymentBankAccount: 'Conta Bancária de Pagamento',
    creditBankAccount: 'Conta Bancária de Crédito',
    maturityRoundingType: 'Tipo de Arredondamento do Valor da Parcela',
    fileId: 'ID dos Arquivos',
    file: 'Arquivo',
    view: 'Visualizar',
    viewTable: 'Visualizar Tabela',
    armotization: "Armotização",
    autoMapping: 'Mapeamento Automático',
    clearMapping: 'Limpar Mapeamento',
    importMapping: 'Importar Mapeamento',
    showAll: 'Visualizar Todos',
    hideAll: 'Esconder Todos',
    hide: 'Esconder',
    showOnlyMapped: 'Visualizar Apenas os Mapeados',
    showOnlyUnmapped: 'Visualizar Apenas os Não Mapeados',
    showMapped: 'Visualizar Mapeados',
    showUnmapped: 'Visualizar Não Mapeados',
    invoiceNumber: 'Número do Documento Fiscal',
    invoiceKey: 'Chave do Documento Fiscal',
    installment: 'Parcela',
    installments: 'Parcelas',
    grossValue: 'Valor Bruto',
    contract: 'Contrato',
    contractOfOrigin: 'Contrato de Origem',
    anticipation: 'Antecipação',
    anticipations: 'Antecipações',
    customerCredit: 'Crédito ao Cliente',
    firstMaturity: 'Primeiro Vencimento',
    lastMaturity: 'Último Vencimento',
    lastPayment: 'Último Pagamento',
    originSearch: 'Consulta de Origens',
    originFilter: 'Filtros do Origens',
    originTypeFilter: 'Filtros de Tipos de Origens',
    newOrigin: 'Nova Origem',
    origins: 'Origens',
    originId: 'Id da Origem',
    maturitySearch: 'Consulta de Vencimentos',
    maturityFilter: 'Filtros de Vencimento',
    newMaturity: 'Novo Vencimento',
    maturity: 'Vencimento',
    maturities: 'Vencimentos',
    recalculate: 'Recalcular',
    calculate: 'Calcular',
    maturityId: 'ID do Vencimento',
    maturityCode: 'Código do Vencimento',
    parentType: 'Tipo de Pai',
    parentTypeCode: 'Código do Tipo de Pai',
    parentTypeName: 'Nome do Tipo de Pai',
    parentCode: 'Código do Pai',
    parentId: 'ID do Pai',
    stepCode: 'Código da Etapa',
    stepName: 'Nome da Etapa',
    productCode: 'Código do Produto',
    productTypeCode: 'Código do Tipo de Produto',
    productTypeExternalCode: 'Código Externo do Tipo de Produto',
    productTypeName: 'Nome do Tipo de Produto',
    operationType: 'Tipo de Operação',
    operationTypeCode: 'Código do Tipo de Operação',
    paymentCode: 'Código do Pagamento',
    operationTypeExternalCode: 'Código Externo do Tipo de Operação',
    operationTypeName: 'Nome do Tipo de Operação',
    productGroup: 'Grupo de Produtos',
    productGroupCode: 'Código do Grupo de Produtos',
    productGroupExternalCode: 'Código Externo do Grupo de Produtos',
    productGroupName: 'Nome do Grupo de Produtos',
    productSubgroup: 'Subgrupo de Produtos',
    productSubgroupCode: 'Código do Subgrupo de Produtos',
    productSubgroupExternalCode: 'Código Externo do Subgrupo de Produtos',
    productSubgroupName: 'Nome do Subgrupo de Produtos',
    amortisationType: 'Sistema de Amortização',
    amortisationTypeCode: 'Código do Sistema de Amortização',
    amortisationTypeName: 'Nome do Sistema de Amortização',
    paymentStatusCode: 'Código do Status de Pagamento',
    paymentStatusExternalCode: 'Código Externo do Status',
    paymentStatusName: 'Nome do Status de Pagamento',
    paymentStatusDate: 'Data do Status de Pagamento',
    paymentStatusColor: 'Cor do Status de Pagamento',
    paymentStatusNote: 'Observação do Status de Pagamento',
    parentContractNumber: 'Número do Contrato Pai',
    invoiceCode: 'Código do Título',
    invoiceIssueDate: 'Data e hora de emissão do Documento Fiscal',
    invoiceValue: 'Valor da Nota Fiscal',
    originalValue: 'Valor Original',
    insuranceValue: 'Valor dos Seguros',
    amortisationValue: 'Valor da Amortização',
    maturityBalance: 'Saldo do Vencimento',
    currentBalance: 'Saldo Atual',
    chargeBalance: 'Saldo de Cobrança',
    maturityBalanceDate: 'Data do Saldo do Vencimento',
    dischargeValueDate: 'Data do Valor para Quitação do Vencimento',
    term: 'Prazo',
    rate: 'Taxa',
    period: 'Período',
    workingPeriod: 'Periodo Trabalhado',
    paymentComprovant: 'Comprovante de Pagamento',
    defaultTerm: 'Prazo Padrão',
    defaultRate: 'Taxa Padrão',
    rateValue: 'Valor da Taxa da Frequancia de Pagamento',
    paymentFrequencyValue: 'Valor da Frequancia de Pagamento em Dias',
    daysToDue: 'Dias para o Vencimento',
    daysToDischarge: 'Dias para Quitação do Vencimento',
    graceDays: 'Dias de Carência',
    graceDaysList: 'Lista de Dias de Carência',
    termList: 'Lista de Prazos',
    rateList: 'Lista de Taxas',
    iofFunded: 'IOF Financiado',
    iof: 'IOF',
    iofDaily: 'IOF Diário',
    iofDays: 'Dias de Cálculo do IOF',
    issueDate: 'Data e hora de emissão do Vencimento',
    closingDate: 'Data de Fechamento',
    billingIssueDate: 'Data de Emissão da Cobrança',
    billingQuantity: 'Quantidade de Cobranças Enviadas',
    billingStatusCode: 'Código do Status da Cobrança',
    billingStatusName: 'Nome do Status da Cobrança',
    billingStatusDate: 'Data do Status da Cobrança',
    dueDate: 'Data de Vencimento',
    paymentDate: 'Data do Pagamento',
    latePenaltyType: 'Tipo de Multa por Atraso no Pagamento',
    latePenalty: 'Multa por Atraso no Pagamento',
    lateInterestType: 'Tipo de Juros por Atraso no Pagamento',
    indexFinancialPosFixed: 'Indexador Financeiro Pós-Fixado',
    lateInterest: 'Juros por Atraso no Pagamento',
    paymentMarginType: 'Tipo de Margem para Pagamento',
    paymentMargin: 'Margem para Pagamento',
    paymentMethod: 'Forma de Pagamento',
    operationNature: 'Natureza da Operação',
    fileQuantities: 'Quantidade de Arquivos',
    loanProductSearch: 'Consulta de Produtos',
    loanProductFilter: 'Filtros de Produto de Empréstimo',
    loanProduct: 'Produto de Empréstimo',
    productRules: 'Regras do Produto',
    simulationRules: 'Regras de Bancos',
    otherSettings: 'Outras Configurações',
    loanProducts: 'Produtos de Emprétimo',
    productId: 'ID do Produto',
    productType: 'Tipo de Produto',
    agreement: 'Convênio',
    operation: 'Operação',
    lender: 'Banco/Credor',
    termRule: 'Regra de Prazo',
    rateRule: 'Regra de Taxa',
    hierarchy: 'Hierarquia',
    lenderCode: 'Código do Banco ou Credor',
    lenderExternalCode: 'Código Externo do Banco ou Credor',
    lenderName: 'Banco/Credor',
    lenderProductCode: 'Código do Produto no Banco ou Credor',
    minAge: 'Idade Mínima',
    maxAge: 'Idade Máxima',
    minLoanValue: 'Valor Mínimo do Empréstimo',
    maxLoanValue: 'Valor Máximo do Empréstimo',
    minInstallmentValue: 'Valor Mínimo da Parcela',
    maxInstallmentValue: 'Valor Máximo da Parcela',
    minNetValue: 'Valor Mínimo do Troco ao Cliente',
    maxNetValue: 'Valor Máximo do Troco ao Cliente',
    minTerm: 'Prazo Mínimo',
    maxTerm: 'Prazo Máximo',
    minRate: 'Taxa Mínima',
    maxRate: 'Taxa Máxima',
    minLoanBalance: 'Valor Mínimo do Saldo Devedor',
    maxLoanBalance: 'Valor Máximo do Saldo Devedor',
    index: 'Índice',
    onlyBestIndex: 'Exibir apenas se for o melhor índice',
    simulateOnlyBestIndex: 'Simular apenas se for o melhor índice',
    simulate: 'Simular',
    installmentPaidLimit: 'Tipo de Limite de Parcelas Pagas',
    installmentPaidLimitValue: 'Limite de Parcelas pagas',
    factor: 'Fator',
    multiplier: 'Multiplicador',
    divisor: 'Divisor',
    simulationType: 'Tipo de Simulação',
    simulation: 'Simulação',
    simulations: 'Simulações',
    simulationTab: 'Aba Simulação',
    newRetention: 'Nova Retenção',
    retentionType: 'Tipo de Retenção',
    retentionCode: 'Código da Retenção',
    paymentFrequency: 'Frequancia de Pagamento',
    dueDay: 'Dia de Vencimento',
    creditType: 'Tipo de Crédito ao Cliente',
    creditTypeCode: 'Código do Tipo de Crédito ao Cliente',
    creditTypeExternalCode: 'Código Externo do Tipo de Crédito ao Cliente',
    creditTypeName: 'Nome do Tipo de Crédito ao Cliente',
    cpmf: 'CPMF',
    costs: 'Custos Operacionais',
    insurance: 'Seguro',
    insurances: 'Seguros',
    calculateWhenToSave: 'Recalcular quando salvar',
    availableStatusType: 'Tipo de Status Disponível',
    availableStatus: 'Status Disponível',
    firstStatus: 'Primeiro Status do Empréstimo',
    nextProductCode: 'Próximo Código do Produto',
    externalCodeGeneration: 'Geração de Código Externo',
    nextExternalCode: 'Próximo Código Externo',
    externalCodeRepeated: 'Repetição de Código Externo',
    productTypeExternalCodeOption: 'Opções de Código Externo do Tipo de Produto',
    operationTypeExternalCodeOption: 'Opções de Código Externo do Tipo de Operação',
    productGroupOption: 'Opções de Grupo de Produtos',
    productGroupExternalCodeOption: 'Opções de Código Externo do Grupo de Produtos',
    productSubgroupOption: 'Opções de Subgrupo de Produtos',
    productSubgroupExternalCodeOption: 'Opções de Código Externo do Subgrupo de Produtos',
    descriptionOption: 'Opções de Descrição do Produto',
    lenderOption: 'Banco ou Credor',
    lenderExternalCodeOption: 'Opções de Código Externo do Banco ou Credor',
    lenderProductCodeOption: 'Opções de Código do Produto no Banco ou Credor',
    statusExternalCodeOption: 'Opções de Código Externo do Status',
    termOption: 'Opções de Prazo',
    rateOption: 'Opções de Taxa Mês',
    colorOption: 'Opções de Cor',
    noteOption: 'Opções de Observação',
    parentOption: 'Opções de Produto Pai',
    graceDaysOption: 'Opções de Dias de Carência para a Primeira Parcela',
    minAgeOption: 'Opções de Idade Mínima',
    maxAgeOption: 'Opções de Idade Máxima',
    minLoanValueOption: 'Opções de Valor Mínimo do Empréstimo',
    maxLoanValueOption: 'Opções de Valor Máximo do Empréstimo',
    minInstallmentValueOption: 'Opções de Valor Mínimo da Parcela',
    maxInstallmentValueOption: 'Opções de Valor Máximo da Parcela',
    minNetValueOption: 'Opções de Valor Mínimo do Troco ao Cliente',
    maxNetValueOption: 'Opções de Valor Máximo do Troco ao Cliente',
    minTermOption: 'Opções de Prazo Mínimo',
    maxTermOption: 'Opções de Prazo Máximo',
    minRateOption: 'Opções de Taxa Mínima',
    maxRateOption: 'Opções de Taxa Máxima',
    minLoanBalanceOption: 'Opções de Valor Mínimo do Saldo do Empréstimo',
    maxLoanBalanceOption: 'Opções de Valor Máximo do Saldo do Empréstimo',
    indexOption: 'Opções de Índice',
    onlyBestIndexOption: 'Opções de Exibir apenas se for o melhor índice',
    installmentPaidLimitOption: 'Opções de Tipo de Limite de Parcelas Pagas',
    factorOption: 'Opções de Fator',
    multiplierOption: 'Opções de Multiplicador',
    divisorOption: 'Opções de Divisor',
    simulationTypeOption: 'Opções de Tipo de Simulação',
    paymentMethodOption: 'Opções de Forma de Pagamento',
    paymentMethodExternalCodeOption: 'Opções de Código Externo da Forma de Pagamento',
    paymentFrequencyOption: 'Opções de Frequancia de Pagamento',
    dueDayOption: 'Opções de Dia de Vencimento',
    creditTypeOption: 'Opções de Tipo de Crédito ao Cliente',
    creditTypeExternalCodeOption: 'Opções de Código Externo do Tipo de Crédito ao Cliente',
    amortisationTypeOption: 'Opções de Código do Sistema de Amortização Padrão',
    iofFundedOption: 'Opções de IOF Financiado',
    iofOption: 'Opções de IOF',
    iofDailyOption: 'Opções de IOF Diário',
    cpmfOption: 'Opções de CPMF',
    costsOption: 'Opções de Custos Operacionais',
    insurancesOption: 'Opções de Seguros',
    calculateWhenToSaveOption: 'Opções de Recalcular quando salvar',
    availableStatusTypeOption: 'Opções de Código Padrão do Tipo de Status do Empréstimo Disponível',
    latePenaltyTypeOption: 'Opções de Código do Tipo de Multa por Atraso no Pagamento',
    lateInterestTypeOption: 'Opções de Código do Tipo de Juros por Atraso no Pagamento',
    paymentMarginTypeOption: 'Opções de Código do Tipo de Margem para Pagamento (Define uma sobra mínima para considerar o empréstimo totalmente pago)',
    lenderSearch: 'Consulta de Credores',
    lenderFilter: 'Filtros de Credor',
    lenders: 'Credores',
    lenderId: 'Id do Credor',
    leadSettings: 'Configurações de Lead',
    leadInssRequest: 'Solicitação de Lead de INSS',
    fatbanLead: 'Fatban - Lead',
    leadInssRequestSearch: 'Consulta de Solicitaões de Lead de INSS',
    leadInssRequestFilter: 'Filtros de Solicitação de Lead de INSS',
    newLeadInssRequest: 'Nova Solicitação',
    leadInssRequests: 'Solicitações de Lead de INSS',
    leadInssRequestCancelQuestion: 'Deseja cancelar a Solicitaões de Lead de INSS',
    requestId: 'Id da Solicitação',
    requestCode: 'Código da Solicitação',
    personCount: 'Quantidade de Pessoas',
    benefitCount: 'Quantidade de Benefícios',
    purchaseQuantity: 'Quantidade Comprada',
    purchase: 'Comprado',
    unitaryCost: 'Custo Unitário',
    unitaryValue: 'Valor Unitário',
    totalCost: 'Custo Unitário',
    processingDate: 'Data de Processamento',
    expirationDate: 'Data de Validade',
    issuingEntity: 'Orgão Emissor',
    selectAIssuingEntity: 'Selecione um Orgão Emissor',
    issuingState: 'UF de Emissão',
    issuingCountry: 'País de Emissão',
    securityCode: 'Código de Segurança',
    processingTimeInSeconds: 'Tempo de Processamento em segundos',
    processingTimeInHours: 'Tempo de Processamento em horas',
    sync: 'Sincronizar',
    consignableType: 'Tipo de Consignável',
    hasCard: 'Possui Cartão RMC',
    cardStatus: 'Status do Cartão',
    minContracts: 'Quantidade mínima de contratos',
    maxContracts: 'Quantidade máxima de contratos',
    minPhones: 'Quantidade mínima de telefones',
    maxPhones: 'Quantidade máxima de telefones',
    minInstallmentsRemaining: 'Quantidade mínima de Parcelas Restantes',
    maxInstallmentsRemaining: 'Quantidade máxima de Parcelas Restantes',
    minInstallmentsPaid: 'Quantidade mínima de Parcelas Pagas',
    maxInstallmentsPaid: 'Quantidade máxima de Parcelas Pagas',
    minInstallmentsPaidPercent: 'Porcentagem mínima de Parcelas Pagas',
    maxInstallmentsPaidPercent: 'Porcentagem máxima de Parcelas Pagas',
    minContractValue: 'Valor mínimo do contrato',
    maxContractValue: 'Valor máximo do contrato',
    minDebitBalance: 'Valor mínimo do Saldo Devedor',
    maxDebitBalance: 'Valor máximo do Saldo Devedor',
    minBenefitValue: 'Valor mínimo do benefício',
    maxBenefitValue: 'Valor máximo do benefício',
    minMarginValue: 'Valor mínimo da margem livre',
    maxMarginValue: 'Valor máximo da margem livre',
    minCardMarginValue: 'Valor mínimo da margem do cartão',
    maxCardMarginValue: 'Valor máximo da margem do cartão',
    minBenefitStart: 'Data mínima do Início do Benefício',
    maxBenefitStart: 'Data máxima do Início do Benefício',
    minContractStart: 'Data mínima do Início do Contrato',
    maxContractStart: 'Data máxima do Início do Contrato',
    minContractEnd: 'Data mínima do Fim do Contrato',
    maxContractEnd: 'Data máxima do Fim do Contrato',
    benefitBanks: 'Bancos do benefício',
    contractBanks: 'Bancos do contrato',
    states: 'UFs',
    cities: 'Cidades',
    ddds: 'DDDs',
    benefitTypes: 'Tipos de benefícios',
    benefitTypesExclusion: 'Tipos de benefícios a serem excluidos',
    issuer: 'Emissor',
    issuerSearch: 'Consulta de Emissor',
    issuerFilter: 'Filtros de Emissor',
    newIssuer: 'Novo Emissor',
    issuers: 'Emissores',
    issuerId: 'Id do Emissor',
    loanTypes: 'Tipos de Empréstimo',
    brokerId: 'Id do Agente',
    newBroker: 'Novo Agente',
    brokerCode: 'Código do Agente',
    brokers: 'Agentes',
    totalBrokers: 'Total do Brokers',
    brokerFilter: 'Filtro do Agente',
    brokerSearch: 'Agentes',
    brokerStatus: 'Status do Agentes',
    broker: 'Agente',
    brokerType: 'Tipo de Agente',
    brokerGroup: 'Grupo de Agente',
    newFilter: 'Novo Filtro',
    storeId: 'Id da Loja',
    newStore: 'Nova Loja',
    storeCode: 'Código da Loja',
    stores: 'Lojas',
    totalStores: 'Total de Stores',
    storeFilter: 'Filtro da Loja',
    storeSearch: 'Lojas',
    storeStatus: 'Status da Lojas',
    store: 'Loja',
    parentBroker: 'Agente Superior',
    storeType: 'Tipo de Loja',
    dealer: 'Revenda',
    dealerActiveDescription: 'Conta habilitada para revender o sistema',
    typeExternalCode: 'Código Externo do Tipo de Agente',
    birthDate: 'Data de Nascimento',
    birthDateNiver: 'Data de Aniversário',
    manualSimulation: 'Simulação Manual',
    inssSimulation: 'Simulação de INSS',
    userLicense: 'Licença de Usuário',
    userLicenseRequired: 'Requer licença de usuário',
    companyOpeningDate: 'Data de Abertura da Empresa',
    site: 'Site',
    customer: 'Cliente',
    customerSearch: 'Consulta de Cliente',
    customerFilter: 'Filtros de Cliente',
    newCustomer: 'Novo Cliente',
    productSearch: 'Consulta de Produto',
    customers: 'Clientes',
    customerId: 'Id do Cliente',
    campaign: 'Campanha',
    campaignSearch: 'Consulta de Campanha',
    campaignFilter: 'Filtros de Campanha',
    newCampaign: 'Nova Campanha',
    campaigns: 'Campanhas',
    campaignId: 'Id do Campanha',
    campaignCode: 'Código do Campanha',
    bucketType: 'Tipo de Importação',
    dataBaseType: 'Tipo de Base de Dados',
    bucketTypeCode: 'Código do Tipo de Importação',
    bucketTypeName: 'Nome do Tipo de Importação',
    bucket: 'Importação',
    buckets: 'Importações',
    bucketId: 'Id do Importação',
    bucketCode: 'Código do Importação',
    bucketName: 'Nome do Importação',
    selectBucket: 'Selecione um Importação',
    users: 'Usuários',
    allUsers: 'Todos os Usuários',
    userIds: 'IDs dos Usuários',
    allProducts: 'Todos os Produtos',
    productIds: 'IDs dos Produtos',
    expiration: 'Validade',
    start: 'Início',
    newLead: 'Novo Lead',
    leadCode: 'Código do Lead',
    leads: 'Leads',
    totalLeads: 'Total de Leads',
    leadFilter: 'Filtro de Lead',
    leadSearch: 'Leads',
    leadStatus: 'Status do Leads',
    lead: 'Lead',
    leadType: 'Tipo de Lead',
    assetCode: 'Código do Ativo',
    assets: 'Ativos',
    total: 'Total',
    totalAssets: 'Total de Ativos',
    assetFilter: 'Filtro de Ativo',
    assetSearch: 'Ativos',
    assetStatus: 'Status do Ativo',
    asset: 'Ativo',
    assetType: 'Tipo de Ativo',
    newAsset: 'Novo Ativo',
    copyAsset: 'Copiar Ativo',
    createProposal: 'Cadastrar Proposta',
    createProposals: 'Cadastrar Propostas',
    createProposalQuestion: 'Deseja cadastrar a proposta selecionada?',
    createProposalsQuestion: 'Deseja cadastrar as propostas selecionadas?',
    worked: 'Trabalhado',
    workedPercent: 'Trabalhado (%)',
    statusSearch: 'Consulta de Status',
    statusFilter: 'Filtros de Status',
    newStatus: 'Novo Status',
    statusId: 'ID do Status',
    nextStatusType: 'Tipo de Próximo Status',
    userProfileType: 'Tipo de Perfil de Acesso',
    userProfileToNotify: 'Tipo de Perfil a notificar',
    nextStatusTypeCode: 'Código do Tipo de Próximo Status',
    nextStatusTypeName: 'Nome do Tipo de Próximo Status',
    nextStatusCodes: 'Códigos dos Próximos Status',
    nextStatus: 'Próximos Status',
    actions: 'Ações',
    noteRequired: 'Observação obrigatória',
    notifyBusinessConsultant: 'Notificar Consultor de Negócio ?',
    reasonRequired: 'Motivo obrigatório',
    cpc: 'Informar CPC',
    cpcStatusChange: 'Qual telefone localizou o cliente ?',
    deadline: 'Limitar quantia de Leads',
    deadlineDays: 'Quantidade de Dias',
    deadlineLeads: 'Quantidade de Leads',
    loanOrigin: 'Origem do Contrato',
    loanOriginSearch: 'Consulta de Origem do Contrato',
    loanOriginFilter: 'Filtros de Origem do Contrato',
    newLoanOrigin: 'Nova Origem',
    loanOrigins: 'Origens do Contrato',
    accessProfile: 'Perfil de Acesso',
    accessProfiles: 'Perfis de Acesso',
    userSearch: 'Consulta de Usuários',
    userFilter: 'Filtros de Usuário',
    newUser: 'Novo Usuário',
    profileCode: 'Código do Perfil',
    profileName: 'Nome do Perfil',
    userType: 'Tipo de Usuário',
    userTypeCode: 'Código do Tipo de Usuário',
    userTypeName: 'Nome do Tipo de Usuário',
    firstName: 'Primeiro Nome',
    lastName: 'Último Nome',
    accessKey: 'Chave de Acesso',
    password: 'Senha',
    resetPassword: 'Redefinir Senha',
    securityStamp: 'Código de Segurança para comfimações',
    emailConfirmed: 'Confirmação de Email',
    emailFactorEnabled: 'Abilitar segundo Fatores de Proteção por Email',
    passwordLengthMessage: 'Senha deve ter no mínimo 4 caracteres',
    matchPassword: 'A senha nova não pode ser igual a senha anterior',
    requestPasswordChangeAtNextAccess: 'Solicitar alteração de senha no próximo acesso',
    phone: 'Telefone',
    income: 'Renda',
    phoneConfirmed: 'Confirmação de Telefone',
    phoneFactorEnabled: 'Abilitar segundo Fatores de Proteção por Telefone',
    showInvalidPhones: 'Exibir Telefones Inválidos',
    lockoutDate: 'Impedir de Entrar até a Data (Muitas Tentativas sem sucesso)',
    lockoutEnabled: 'Habilitar Bloqueio por tentativas de login sem sucesso',
    accessFailed: 'Quantidade de tentativas de acesso sem sucesso',
    accessCount: 'Quantidade de Acessos',
    access: 'Acesso',
    authorizations: 'Autorizações',
    lastAccess: 'Último Acesso',
    sessionId: 'ID da Última Sessão',
    signOut: 'Sair do Sistema',
    userPhoto: 'Foto do Usuário',
    accountAccess: 'Contas de Acesso',
    storeAccess: 'Lojas de Acesso',
    brokerAccess: 'Agentes de Acesso',
    languageId: 'Id do Idioma',
    languageCode: 'Código do Idioma',
    languageName: 'Nome do Idioma',
    selected: 'Selecionado',
    selecteds: 'Selecionados',
    repository: 'Importação',
    repositories: 'Importações',
    startDate: 'Data Início',
    openingDate: 'Data de Abertura',
    endDate: 'Data Fim',
    location: 'Localização',
    selectRegion: 'Selecione a região',
    selectRegions: 'Selecione as regiões',
    citiesOrDistrictsSelecteds: 'Cidades ou municípios selecionados',
    enterCityOrDistrictName: 'Informe o nome da cidade ou município',
    requests: 'Solicitações',
    request: 'Solicitação',
    create: 'Criar',
    pageNotFound: 'Página não encontrada.',
    pageNotFoundMessage: 'Desculpe, mas a página que você tentou acessar não foi encontrada.',
    accessDenied: 'Acesso negado!',
    accessDeniedMessage: 'Desculpe, mas você não tem permissão para visualizar esta página.',
    myCampaigns: 'Prospecção',
    optProspection: 'Oportunidade Prospecção',
    entityRecord: 'Registro',
    newLender: 'Novo Credor',
    statusTitle: 'Status',
    loanStatus: 'Status de Empréstimo',
    smsConfig: 'Configuração de SMS',
    configuration: 'Configuração',
    sendSaturday: 'Enviar no Sábado',
    sendSunday: 'Enviar no Domingo',
    sendHoliday: 'Enviar no feriado',
    startSending: 'Iniciar Envios',
    stopSending: 'Pausar Envios',
    hours: 'Horas',
    daysQuantity: 'Quantidades por dia',
    hoursQuantity: 'Quantidades por hora',
    sendAPI: 'API de envio',
    sendTime: 'Hora do Envio',
    sendSchedule: 'Programação de Envio',
    utmCampaign: 'Campanha UTM',
    utmContent: 'Conteúdo UTM',
    utmMedium: 'UTM Medium',
    utmSource: 'Fonte UTM',
    utmTerm: 'Termo UTM',
    profilePicture: 'Foto de Perfil',
    toWork: 'Trabalhar',
    accessType: 'Tipo de Acesso',
    newLevel: 'Novo Nível',
    newGroup: 'Novo Grupo',
    newItem: 'Novo Item',
    group: 'Grupo',
    groups: 'Grupos',
    rename: 'Renomear',
    scope: 'Escopo',
    statusFiles: 'Status de Arquivos',
    item: 'Item',
    items: 'Itens',
    login: 'Login',
    userProfile: 'Perfil de Acesso',
    association: 'Associação',
    createAssociationMargin: 'Criar margem de Associação?',
    associationMarginValue: 'Valor da Margem de Associação',
    editAssociationMargin: 'Editar Margem de Associação',
    deleteAssociationMargin: 'Excluir Margem de Associação',
    otherInformations: 'Outras Informações',
    authToken: 'Token de Autenticação',
    authTokens: 'Tokens de Autenticação',
    copy: 'Copiar',
    token: 'Token',
    tokens: 'Tokens',
    tokenCode: 'Código do Token',
    ipAddress: 'Endereço de IP',
    host: 'Host',
    noRecordsFound: 'Nenhum registro encontrado',
    setting: 'Configuração',
    deleteQuestion: 'Deseja excluir?',
    deleteManyQuestion: 'Deseja excluir os itens selecionados?',
    queueCancellationQuestion: 'Deseja cancelar a fila?',
    deleteSelecteds: 'Excluir Selecionados',
    retention: 'Retenção',
    fgts: 'FGTS',
    siape: 'SIAPE',
    retentions: 'Retenções',
    entity: 'Entidade',
    entities: 'Entidades',
    key: 'Chave',
    label: 'Legenda',
    filterType: 'Tipo de Filtro',
    displayFieldKey: 'Chave do Campo de Visualização',
    displayFieldName: 'Nome do Campo de Visualização',
    action: 'Ação',
    actionKey: 'Chave da Ação',
    actionSuccess: 'Ação de Sucesso',
    actionError: 'Ação de Erro',
    notification: 'Notificação',
    notifications: 'Notificações',
    sentNotifications: 'Notificações enviadas',
    openMessage: 'Abrir mensagem',
    sent: "Enviada",
    clearNotifications: 'Limpar notificações',
    open: "Abrir",
    setVisualized: 'Marcar como lidas',
    pageNotification: 'Notificação de Página',
    duration: 'Duração',
    displayType: 'Tipo de Exibição',
    verticalPosition: 'Posição Vertical',
    horizontalPosition: 'Posição Horizontal',
    domain: 'Domínio',
    appVersion: 'Versão da Aplicação',
    slug: 'Slug',
    url: 'URL',
    generatedUrl: 'URL Gerado',
    urlBuilder: 'Criador de URL',
    page: 'Página',
    pageProperties: 'Propriedades da Página',
    tokenType: 'Tipo de Token',
    maxAccess: 'Quantidade Máxima de Acesso',
    groupCode: 'Código do Grupo',
    subgroup: 'Subgrupo',
    groupFilter: 'Filtro de Grupo',
    birthDateBegin: 'Data de Nascimento - Início',
    birthDateEnd: 'Data de Nascimento - Fim',
    userProfiles: 'Perfis de Acesso',
    serverVersion: 'Versão do Backend',
    clientVersion: 'Versão do Frontend',
    blockNextStatus: 'Status que não permite obter um próximo lead',
    nextLeadCode: 'Próximo Código do Lead',
    initialStep: 'Etapa Inicial do Empréstimo',
    nextLoanCode: 'Próximo Código do Empréstimo',
    contractNumberGeneration: 'Geração de Número do Contrato',
    nextContractNumber: 'Próximo Número do Contrato',
    contractNumberRepeated: 'Repetição de Número do Contrato',
    maturityCodeGeneration: 'Geração de Código do Vencimento',
    nextMaturityCode: 'Próximo Código do Vencimento',
    maturityCodeRepeated: 'Repetição de Código do Vencimento',
    maturityContractNumberGeneration: 'Geração de Número do Contrato do Vencimento',
    nextMaturityContractNumber: 'Próximo Número do Contrato do Vencimento',
    maturityContractNumberRepeated: 'Repetição de Número do Contrato do Vencimento',
    defaultOrigin: 'Origem Padrão',
    initialStatus: 'Status Inicial',
    whenProposalRegisterStatus: 'Status do Lead quando for cadastrado uma proposta',
    workMultiple: 'Trabalhar vários ao mesmo tempo',
    defaultProduct: 'Produto Padrão',
    paymentMethodExternalCode: 'Código Externo da Forma de Pagamento',
    defaultPaymentMethod: 'Forma de Pagamento Padrão',
    defaultPaymentFrequency: 'Frequancia de Pagamento Padrão',
    defaultPaymentFrequencyValue: 'Valor da Frequancia de Pagamento Padrão em Dias',
    defaultCreditType: 'Tipo de Crédito ao Cliente Padrão',
    nextPaymentCode: 'Próximo Código de Pagamento',
    calculatePaymentType: ' Tipo de Cálculo de Pagamentos',
    nextRetentionCode: 'Código da Próxima Retenção',
    bank: 'Banco',
    selectABank: 'Selecione um Banco',
    branch: 'Agência',
    bankAccount: 'Conta Bancária',
    bankAccounts: 'Contas Bancárias',
    queryDate: 'Data da Consulta',
    queryUser: 'Usuário da Consulta',
    consignable: 'Consignável',
    person: 'Pessoa',
    people: 'Pessoas',
    inclusionDate: 'Data da Inclusão',
    newQuery: 'Nova Consulta',
    queryHistory: 'Histórico de Consulta',
    searchInssQuery: 'Consultas de INSS',
    searchQueue: 'Fila de Consultas',
    searchInssQueue: 'Fila de Consultas de INSS',
    addQueue: 'Adicionar na Fila',
    queryInssFilter: 'Filtro de Consultas de INSS',
    queryType: 'Tipo de Consulta',
    enterCountryIdentity: 'Informe um CPF',
    enterBenefit: 'Informe um benefício',
    benefitTypeCode: 'Código do Tipo de Benefício',
    benefitTypeName: 'Nome do Tipo de Benefício',
    benefitStart: 'Data de Início do Benefício',
    addMargin: 'Adicionar Margem',
    editMarginValue: 'Editar Valor da Margem',
    editMargin: 'Editar Margem',
    deleteMargin: 'Excluir Margem',
    marginValue: 'Valor da Margem',
    proposalValue: 'Valor Da Proposta',
    addCardMargin: 'Adicionar Margem de Cartão',
    editCardMarginValue: 'Editar Valor da Margem de Cartão',
    editCardMargin: 'Editar Margem de Cartão',
    deleteCardMargin: 'Excluir Margem de Cartão',
    cardMarginValue: 'Valor da Margem de Cartão',
    cardRCCMarginValue: 'Valor da Margem de Cartão RCC',
    addContract: 'Adicionar Contrato',
    editContract: 'Editar Contrato',
    deleteContract: 'Excluir Contrato',
    infoDate: 'Data da Informação',
    licenseId: 'ID da Licença',
    queryId: 'ID da Consulta',
    query: 'Consulta',
    searchNaturalPersonQuery: 'Consultas de Pessoa Física',
    queryNaturalPersonFilter: 'Filtro de Consultas de Pessoa Física',
    searchDebitBalanceQuery: 'Consultas de Saldo Devedor',
    historyDebitBalanceQuery: 'Histórico de Saldo Devedor',
    licenses: 'Licenças',
    licenseFilter: 'Filtros de Licença',
    licenseCode: 'Código da Licença',
    debitType: 'Tipo de Cobrança',
    quantity: 'Quantidade',
    price: 'Preço',
    balance: 'Saldo',
    anticipationValue: 'Valor da Antecipação',
    anticipatedValue: 'Valor Antecipado',
    anticipationDays: 'Dias Antecipados',
    balanceDate: 'Data do Saldo',
    licenseDate: 'Data da Licença',
    licenseType: 'Tipo de Licença',
    validityDays: 'Dias de Validade',
    validity: 'Validade',
    remainingDays: 'Dias Restante',
    validUntil: 'Valido Até',
    dailyLimit: 'Limite Diário',
    limitUsed: 'Limite Utilizado',
    linkedUsers: 'Usuários Vinculados',
    none: 'Nenhum',
    dailyLimitUsed: 'Limite Diário Utilizado',
    lastUsed: 'Última Utilização',
    associationType: 'Tipo de Associação',
    assignatureType: 'Tipo de Assinatura',
    registerInCETIP: "Registro na CETIP",
    quantityUsers: 'Quantidade de Usuários',
    setUsers: 'Definir Usuários',
    authTokenFilter: 'Filtro de Token',
    mapping: 'Mapeamento',
    model: 'Modelo',
    models: 'Modelos',
    fieldsMapping: 'Mapeamento de Campos',
    fieldType: 'Tipo de Campo',
    decimalPoint: 'Ponto Decimal',
    dataType: 'Tipo de Dado',
    fieldName: 'Nome do Campo',
    fieldValue: 'Valor do Campo',
    field: 'Campo',
    goUp: 'Subir',
    goDown: 'Descer',
    moveUp: 'Mover para Cima',
    moveDown: 'Mover para Baixo',
    remove: 'Remover',
    inputFormat: 'Formato de Entrada',
    outputFormat: 'Formato de Saída',
    defaultValue: 'Valor Padrão',
    table: 'Tabela',
    mappingCode: 'Código do Mapeamento',
    mappingName: 'Nome do Mapeamento',
    newRecord: 'Novo Registro',
    numbersByStatus: 'Números por Status',
    syncWithInss: 'Sincronizar',
    bot: 'Robô',
    bots: 'Robôs',
    botFilter: 'Fitro de Robô',
    messageTemplate: 'Modelo de Mensagem',
    messageTemplates: 'Modelos de Mensagem',
    template: 'Modelo',
    templates: 'Modelos',
    newTemplate: 'Novo Modelo',
    subject: 'Assunto',
    features: 'Recursos',
    exportToDialer: 'Exportar para Discador',
    deleteFromDialer: 'Excluir do Discador',
    sendToDialer: 'Enviar para Discador',
    exports: 'Exportações',
    layout: 'Layout',
    openAs: 'Abrir como',
    phonePriority: 'Prioridade dos Telefones',
    phoneType: 'Tipo de Telefone',
    phoneStatus: 'Status do Telefone',
    help: 'Ajuda',
    about: 'Sobre',
    sms: 'Gestão de SMS',
    sendSms: 'Enviar SMS',
    rework: 'Retrabalhar',
    updateBalance: 'Atualizar Saldo',
    updateFgtsBalance: 'Atualizar Saldo do FGTS',
    updateFgtsBalanceQuestion: 'Confirmar Atualização do Saldo do FGTS?',
    recipient: 'Destinatário',
    recipients: 'Destinatários',
    writeMessage: 'Escrever Mensagem',
    messageCode: 'Código da Mensagem',
    batchCode: 'Código do Lote',
    batch: 'Lote',
    confirmationSuccess: 'Confirmação realizada com sucesso.',
    cancellationSuccess: 'Cancelamento realizado com sucesso.',
    batchName: 'Nome do Lote',
    quantityMessages: 'Quantidade de Mensagens',
    readed: 'Lida',
    sendMessageQuestion: 'Confirmar envio das mensagens?',
    from: 'de',
    to: 'para',
    sender: 'Remetente',
    validation: 'Validação',
    validations: 'Validações',
    validationType: 'Tipo de Validação',
    keyValue: 'Chave Valor',
    params: 'Parâmetros',
    urlParams: 'Parâmetros de URL',
    benefits: 'Benefícios',
    loanEligible: 'Elegível para empréstimo?',
    spouseCountryIdentity: 'CPF do Cônjuge',
    spouseName: 'Nome do Cônjuge',
    fatherName: 'Nome do Pai',
    naturalness: 'Naturalidade',
    naturalnessState: 'Estado da Naturalidade',
    nationality: 'Nacionalidade',
    countryOrigin: 'País de Origem',
    selectACountry: 'Selecione um País',
    bankCode: 'Código do Banco',
    bankName: 'Nome do Banco',
    branchCode: 'Código da Agência',
    accountNumber: 'Número da Conta',
    branchNumber: 'Número da Agência',
    accountType: 'Tipo de Conta',
    holderName: 'Nome do Titular',
    send: 'Enviar',
    brazilian: 'Brasileiro (a)',
    googleAnalytics: 'Google Analytics',
    validPhone: 'Telefone Válido',
    invalidPhone: 'Telefone Inválido',
    notDisturb: 'Não Perturbe',
    isNotThePerson: 'Não é a Pessoa',
    cpcPhone: 'Telefone CPC',
    removeCPC: 'Remover CPC',
    addNewPhone: 'Adicionar novo Telefone',
    accountFilter: 'Filtro de Conta',
    accountId: 'ID da Conta',
    accounts: 'Contas',
    newAccount: 'Nova Conta',
    accountFiles: 'Arquivos da Conta',
    integrationFilter: 'Filtro de Integração',
    integrationId: 'ID da Integração',
    newIntegration: 'Nova Integração',
    integrationFiles: 'Arquivos da Integração',
    integrationCode: 'Código da Integração',
    buy: 'Comprar',
    buylead: 'Comprar Lead',
    leadQuantity: 'Quantidade de Leads',
    purchaseAmount: 'Quantidade da Compra',
    property: 'Propriedade',
    properties: 'Propriedades',
    loanBankStatus: 'Status do Empréstimo no Banco',
    progress: 'Progresso',
    head: 'Cabeça',
    body: 'Corpo',
    footer: 'Rodapé',
    pageProgress: 'Progresso da Página',
    pageHead: 'Cabeça da Página',
    pageBody: 'Corpo da Página',
    pageFooter: 'Rodapé da Página',
    queue: 'Fila',
    queues: 'Filas',
    queueType: 'Tipo da Fila',
    queueAction: 'Ação da Fila',
    queueStatus: 'Status da Fila',
    processed: 'Processados',
    connect: 'Conectar',
    disconnect: 'Desconectar',
    enrichment: 'Enriquecimento',
    selectAnIntegration: 'Selecione uma integração',
    selectTheStatuses: 'Selecione os Status',
    accessFilters: 'Filtros de Acesso',
    contractCode: 'Código do Contrato',
    contractStart: 'Início do Contrato',
    discountStart: 'Início do Desconto',
    discountEnd: 'Fim do Desconto',
    inclusion: 'Inclusão',
    exclusion: 'Exclusão',
    contractValue: 'Valor do Contrato',
    installmentValue: 'Valor da Parcela',
    installmentsPaid: 'Parcelas Pagas',
    installmentsPaidPercentage: 'Parcelas Pagas (%)',
    installmentsRemaining: 'Parcelas Restantes',
    domains: 'Domínios',
    domainAddressExample: 'Exemplos: dominio.com.br, dominio.net',
    verify: 'Verificar',
    verificationCode: 'Código de Verificação',
    validatingYourDomain: 'Validando seu domínio',
    confirmMyDomain: 'Confirmar meu domínio',
    themes: 'Temas',
    logo: 'Logotipo',
    sideLogo: 'Logotipo Lateral',
    sideImage: 'Imagem Lateral',
    darkMode: 'Modo Escuro',
    lightMode: 'Modo Claro',
    newMessage: 'Nova Mensagem',
    messageTitle: 'Título da Mensagem',
    messageBody: 'Corpo da Mensagem',
    messageVariables: 'Variáveis de Mensagem',
    variables: '{{nome}}: Nome do Cliente<br> {{valor}}: Valor do Empréstimo',
    appTitle: 'Título da Aplicação',
    appSlogan: 'Slogan da Aplicação',
    appTheme: 'Tema',
    appThemeMode: 'Modo do Tema (light, dark)',
    appDomain: 'Domínio da Aplicação',
    appIconId: 'ID do Ícone da Aplicação',
    appIcon: 'Ícone da Aplicação',
    appLogoId: 'ID do Logo da Aplicação',
    appLogo: 'Logo da Aplicação',
    appLogoDarkId: 'ID do Logo da Aplicação Modo Dark',
    appLogoDark: 'Logo da Aplicação Modo Dark',
    appLogoLink: 'Link do Logo da Aplicação',
    appLogoLinkTarget: 'Alvo do Link do Logo da Aplicação',
    signInLogoId: 'ID do Logo de Login',
    signInLogo: 'Logo de Login',
    signInLogoDarkId: 'ID do Logo de Login Modo Dark',
    signInLogoDark: 'Logo de Login Modo Dark',
    signInLogoLink: 'Link do Logo de Login',
    signInLogoLinkTarget: 'Alvo do Link do Logo de Login',
    signUpTheme: 'Tema do Cadastro de Conta',
    signUpThemeMode: 'Modo do Tema do Cadastro de Conta (light, dark)',
    signUpDomain: 'Domínio do Cadastro de Conta',
    signUpLogoId: 'ID do Logo de Cadastro de Conta',
    signUpLogo: 'Logo de Cadastro de Conta',
    signUpLogoDarkId: 'ID do Logo de Cadastro de Conta Modo Dark',
    signUpLogoDark: 'Logo de Cadastro de Conta Modo Dark',
    signUpLogoLink: 'Link do Logo de Cadastro de Conta',
    signUpLogoLinkTarget: 'Alvo do Link do Logo de Cadastro de Conta',
    signUpPhoneValidate: 'Validar Telefone no Cadastro de Conta',
    signUpPhoneValidationTitle: 'Título da Mensagem de Validação de Telefone no Cadastro de Conta',
    signUpEmailValidate: 'Validar E-mail no Cadastro de Conta',
    signUpEmailValidationTitle: 'Título da Mensagem de Validação de E-mail no Cadastro de Conta',
    signUpFinishRedirectLink: 'Link de redirecionamento ao término do cadastro',
    language: 'Idioma',
    billingAddresses: 'Endereços de Cobrança',
    billingPhones: 'Telefones de Cobrança',
    billingEmails: 'Emails de Cobrança',
    billingUsers: 'Usuários de Cobrança',
    adminUsers: 'Usuários de Administração',
    exportType: 'Tipo de Exportação',
    newLicense: 'Nova Licença',
    newDealerLicense: 'Nova Licença de Revendedor',
    dealerLicense: 'Licença de Revendedor',
    license: 'Licença',
    validityType: 'Tipo de Validação',
    userLevelGroup: 'Grupo de Níveis de Acesso do Usuário',
    userLevelGroups: 'Grupos de Níveis de Acesso do Usuário',
    userLevels: 'Níveis de Acesso do Usuário',
    userLevel: 'Nível de Acesso do Usuário',
    userLevelFilters: 'Filtros de Níveis de Acesso',
    accessLevels: 'Níveis de Acesso',
    accessLevel: 'Nível de Acesso',
    accessLevelType: 'Tipo de Nível de Acesso',
    keepLicenseUsers: 'Manter usuários da licença',
    userId: 'ID do Usuário',
    unitaryPrice: 'Preço Unitário',
    saleType: 'Tipo de Venda',
    subdomain: 'Subdomínio',
    formLoanFgts: 'Formulário de Empréstimo FGTS',
    newForm: 'Novo Formulário',
    forms: 'Formulários',
    newFormLoanFgts: 'Novo Formulário de Empréstimo do FGTS',
    editFormLoanFgts: 'Editar Formulário de Empréstimo do FGTS',
    deleteFormLoanFgts: 'Excluir Formulário de Empréstimo do FGTS',
    deleteFormLoanFgtsSubtitle: 'Deseja excluir o Formulário de Empréstimo do FGTS?',
    formId: 'Id do Formulário',
    formCode: 'Código do Formulário',
    companyId: 'Id da Empresa',
    leadTypeCode: 'Código do Tipo de Lead',
    leadTypeName: 'Nome do Tipo de Lead',
    promoterId: 'Id da Promotora',
    promoterCode: 'Código da Promotora',
    promoterName: 'Nome da Promotora',
    campaignName: 'Nome da Campanha',
    enableSimulation: 'Habilitar Simulação',
    simulationTimeStart: 'Hora Início para Simulação',
    simulationTimeEnd: 'Hora Limite para Simulação',
    simulationIntegrationId: 'Id do Integração para Simulação',
    simulationIntegrationCode: 'Código do Integração para Simulação',
    simulationIntegrationName: 'Descrição da Integração para Simulação',
    proposalDescription: 'Descrição Da Proposta',
    startStatusCode: 'Código do Status Inicial do Lead',
    startStatusName: 'Nome do Status Inicial do Lead',
    startStatusDate: 'Data do Status Inicial do Lead',
    startStatusColor: 'Cor do Status Inicial do Lead',
    startStatusNote: 'Observação do Status Inicial do Lead',
    successStatusCode: 'Código do Status Final do Lead',
    successStatusName: 'Nome do Status Final do Lead',
    successStatusDate: 'Data do Status Final do Lead',
    successStatusColor: 'Cor do Status Final do Lead',
    successStatusNote: 'Observação do Status Final do Lead',
    steps: 'Etapas',
    emailValidation: 'Validação de E-mail',
    phoneValidation: 'Validação de Telefones',
    nameForm: 'Formulário de Nome',
    inputMethod: 'Método de Cadastro',
    tag: 'Marcação',
    transaction: 'Transação',
    transactions: 'Transações',
    transactionDate: 'Data da Transação',
    trackingData: 'Dados de Rastreio',
    aditionalData: 'Dados Adicionais',
    statusGroup: 'Grupo de Status',
    reason: 'Motivo',
    reasons: 'Motivos',
    parent: 'Pai',
    bi: 'BI',
    biSyncQuestion: 'Deseja Atualizar os Dados de BI',
    biSync: 'Sincronizar BI',
    tagFilter: 'Filtro de Marcadores',
    street: "Rua",
    neighborhood: "Bairro",
    licenseTransactionIssuerNotDefinitionMessage: 'Se não for definido um emissor o sistema usará a conta da revenda',
    licenseTransactionPayerNotDefinitionMessage: 'Se não for definido um pagador o sistema usará a conta para qual a licença foi criada',
    schedule: 'Agenda',
    scheduling: 'Agendamento',
    schedules: 'Agendamentos',
    scheduleFilter: 'Filtro de Agendamentos',
    newSchedule: 'Novo Agendamento',
    entityName: 'Agendamento',
    filterTitle: 'Filtros do Agendamento',
    searchTitle: 'Agendamentos',
    editSchedule: 'Editar Agendamento',
    deleteSchedule: 'Excluir Agendamento',
    deleteScheduleSubtitle: 'Deseja excluir o Agendamento?',
    scheduleId: 'Id do Agendamento',
    scheduleCode: 'Código do Agendamento',
    parentName: 'Nome do Pai',
    frequencyType: 'Tipo de Frequência',
    frequencyInterval: 'Intervalo da Frequência',
    frequencyDailyType: 'Tipo de Frequência Diária',
    frequencyDailyInterval: 'Intervalo da Frequência Diária',
    frequencyRelativeInterval: 'Intervalo da Frequência Relativa',
    frequencyRecurrenceFactor: 'Fator de Recorrência de Frequência',
    startTime: 'Hora de Início',
    endTime: 'Hora de Fim',
    allDay: 'Dia inteiro',
    notify: 'Notificar',
    summary: 'Resumo',
    nextRun: 'Próxima Execução',
    lastRun: 'Última Execução',
    runStatusCode: 'Código do Status Execução',
    runStatusName: 'Nome do Status Execução',
    runStatusDate: 'Data do Status Execução',
    runStatusColor: 'Cor do Status Execução',
    runStatusNote: 'Observação do Status Execução',
    notificationType: 'Tipo de Notificação',
    notificationIntervalType: 'Tipo de Intervalo da Notificação',
    notificationInterval: 'Intervalo da Notificação',
    pages: 'Páginas',
    style: 'Estilo',
    viewPage: 'Visualizar Página',
    deleteUrlTitle: 'Exluir URL',
    deleteUrlQuestion: 'Deseja excluir a URL?',
    newUrl: 'Nova URL',
    urls: "URLs",
    openUrl: 'Abrir URL',
    setTemplate: 'Aplicar Modelo',
    integrationType: 'Tipo de Integração',
    commission: 'Comissão',
    commissions: 'Comissões',
    rangeType: 'Tipo de Intervalo',
    ruleId: 'ID da Regra',
    groupId: 'ID do Grupo',
    groupName: 'Nome do Grupo',
    formula: 'Fórmula',
    formulaValue: 'Valor da Fórmula',
    commissionRule: 'Regra de Comissão',
    commissionGroup: 'Grupo de Comissão',
    commissionGroups: 'Grupos de Comissão',
    commissionValue: 'Valor da Comissão',
    loanTarget: 'Meta de Emprétimo',
    loanTargets: 'Metas de Emprétimo',
    target: 'Meta',
    targets: 'Metas',
    newTarget: 'Nova Meta',
    targetFormula: 'Fórmula da Meta',
    targetFormulaValue: 'Valor da Fórmula da Meta',
    targetValue: 'Valor da Meta',
    commissionFormula: 'Fórmula da Comissão',
    dateField: 'Campo de Data',
    startValue: 'Valor Inicial',
    endValue: 'Valor Final',
    interval: 'Intervalo',
    intervals: 'Intervalos',
    calcTarget: 'Calcular Meta',
    baseCalc: 'Base de Cálculo',
    targetFilter: 'Filtro de Meta',
    doneValue: 'Valor Realizado',
    donePercent: 'Porcentagem Realizado',
    projectedValue: 'Valor Projetado',
    projectedPercent: 'Porcentagem Projetada',
    commissionFilter: 'Filtro de Comissão',
    loanStatusDate: 'Data de Status do Contrato',
    citizenCalculator: "Calculadora do Cidadão",
    citizenCalculatorDescription: 'Simule o financiamento com prestações fixas',
    monthNumber: 'Número de meses',
    partialNumber: 'Número de Parcelas',
    citizenCalculatorRate: 'Taxa de juros mensal',
    FeesTax: 'Taxa de Juros',
    citizenCalculatorInstallments: 'Valor da prestação',
    citizenCalculatorBalance: 'Valor financiado',
    commissionCalculationPeriods: 'Período de cálculo da comissão',
    commissionCalculationStatus: 'Status para cálculo da comissão',
    commissionClosingStatus: 'Status para fechamento da comissão',
    commissionWhenCalculateRules: 'Quando deve ser calculada a comissão',
    commissionCalculationRules: 'Regras para cálculo da comissão',
    commissionClosingRules: 'Regras para fechamento da comissão',
    commissionDateField: 'Campo de Data da Comissão',
    workingMethod: 'Método de Trabalho',
    distributionType: 'Tipo de Distribuição',
    limitPerLot: 'Limite por Lote',
    limitPerUser: 'Limite por Usuário',
    distributeCampaign: 'Distribuir Campanha',
    distributeLeads: 'Distribuir Leads',
    processing: 'Processando',
    noLeadsFound: 'Nenhum Lead encontrado',
    successfullyCreatedLeads: 'Leads criados com sucesso',
    campaignInList: 'Campanha em Lista',
    backToList: 'Voltar para Lista',
    blackListFilter: 'Filtro de Lista Negra',
    blackList: 'Lista Negra',
    blackLists: 'Listas Negras',
    moreFilters: 'Mais Filtros',
    lessFilters: 'Menos Filtros',
    editFilters: 'Editar Filtros',
    cancelEdit: 'Cancelar Edição',
    moreFields: 'Mais Campos',
    lessFields: 'Menos Campos',
    activate: 'Ativar',
    disable: 'Desativar',
    cleanAll: 'Limpar Tudo',
    exportations: 'Exportações',
    saveAs: 'Salvar como',
    saveFilterAs: 'Salvar Filtro como',
    editFilter: 'Editar Filtro',
    toFilter: 'Filtrar',
    mappingFilter: 'Filtro de Mapeamento',
    reprocessFileQuestion: 'Deseja reprocessar o arquivo?',
    showReworked: 'Mostrar Retrabalhados',
    queueCreationSuccess: 'Fila cadastrada com sucesso.',
    queuesCreationSuccess: 'Filas cadastradas com sucesso.',
    dialerClickToCall: 'Efetuar Chamada',
    dialerAutoPauseMessage: 'Agente Entrou em Auto Pausa',
    dialerAutoPaused: 'Auto Pausa',
    addressNumberInvalid: 'Número de Endereço Inválido',
    inputTypeCode: 'Código do Método de Cadastro',
    inputTypeName: 'Método de Cadastro',
    simulateInBank: 'Simular no Banco',
    fillData: 'Preencha os Dados',
    simulationParam: 'Parâmetro de Simulação',
    addSimulation: 'Adicionar Simulação',
    editSimulation: 'Editar Simulação',
    endorsementDate: 'Data de Averbação',
    endorsement: 'Averbado',
    reprocess: 'Reprocessar',
    fileDownload: 'Baixar Arquivo',
    typeOperaiton: 'Tipo/Operação',
    termRate: 'Prazo/Taxa',
    lenderProductCodeShort: 'Cód. Produto no Banco',
    benefitBranchState: 'UF da Agência do Benefício',
    benefitBranchCity: 'Cidade da Agência do Benefício',
    benefitBranchDistrict: 'Bairro da Agência do Benefício',
    birth: 'Nascimento',
    syncCampaign: 'Sincronizar Campanha',
    syncCampaigns: 'Sincronizar Campanhas',
    openProposal: 'Abrir Proposta',
    workedPercentBegin: '% Trabalhado Inicial',
    workedPercentEnd: '% Trabalhado Final',
    finished: 'Finalizado',
    finishedPercent: 'Finalizado (%)',
    finishedPercentBegin: '% Finalizado Inicial',
    finishedPercentEnd: '% Finalizado Final',
    unselected: 'Não Selecionado',
    renameFields: 'Renomear campos',
    deleteSelected: 'Excluir Selecionados',
    brazilAid: 'Auxílio Brasil',
    fieldMapping: 'Mapeamento de Campos',
    exportationMappingFilter: 'Filtro de Mapeamento de Exportação',
    exportationMapping: 'Mapeamento de Exportação',
    exportationMappings: 'Mapeamento de Exportações',
    mappingFields: 'Campos de Mapeamento',
    viewSelected: 'Visualizar selecionados',
    viewUnselected: 'Visualizar não selecionados',
    viewAll: 'Visualizar todos',
    exportFilter: 'Filtro de Exportação',
    fileType: 'Tipo de Arquivo',
    fileDelimiter: 'Delimitador do Arquivo',
    filterId: 'ID do Filtro',
    mappingId: 'ID do Mapeamento',
    extension: 'Extenção do Arquivo',
    recordCount: 'Quantidade de Registros',
    count: 'Quantidade',
    quizFilter: 'Filtro de Questionário',
    quiz: 'Questionário',
    quizzes: 'Questionários',
    modelId: 'ID do Modelo',
    questions: 'Perguntas',
    numberOfQuestions: 'Quantidade de Perguntas',
    answered: 'Quantidade de Perguntas Respondidas',
    hits: 'Quantidade de Acertos',
    hitsPercent: 'Porcentagm de Acertos',
    publicId: 'Id Público do Questionário',
  
    hasRMCCard: 'Possui Cartão RMC',
    hasRCCCard: 'Possui Cartão RCC',
    marginRMC: 'Margem Cartão RMC',
    marginRCC: 'Margem Cartão RCC',
  
    preQualify: 'Pré-Qualificados',
    oportunityType: 'Pre Qualificado',
  
    //contractNumber:'Contrato',
  
    proposalStatus: 'Status',
    numberOfInstallments: 'Parcelas',
    numberOfOverdueInstallments: 'Parcelas Vencidas',
    numberOfOpenInstallments: 'Parcelas Pendentes',
    numberOfDueInstallments: 'Parcelas Pagas',
    renegotiationEligibility: 'Permite Refin',
    haveLegalRepresentative: 'Possui Representante Legal',
    statetExclusion: 'Estado a Serem Excluídos',
    maxDocumentIssuanceDate: 'Data Máxima de Emissão do Documento',
    zipCodeExclusion: `CEPs a serem excluidos`,
    totalMaturitiesCount: 'Quantidade Total das Parcelas',
  
    acceptable: 'Dentro do tempo',
    warning: 'Atenção',
    critical: 'Crítico',
  
    criticality: 'Criticidade',
    time: 'Tempo',
    colorScale: 'Escala de cor',
  
    customerPortfolio: 'Carteira',
    customerPortfolioType: 'Tipo de Carteira',
    customerPortfolioFilter: 'Filtro de Carteira',
    customerPortfolioCode: 'Código da Carteira',
    newCustomerPortfolio: 'Nova Carteira',
  
    campaignType: 'Tipo de Campanha',
  
    amountReleased: 'Valor Liberado',
    details: 'Detalhes',
    generateNewCapturedLink: 'Gerar Novo Link de Captura',
    updateNotAllowed: '* Usuário sem permissão para alterar',
    withdrawQuery: "Consulta Saque",
    queryBMG: "Consulta BMG",
    bankBMG: "BMG",
    errorWithdrawQueryBMG: "Erro ao tentar realizar consulta BMG",
    requiredCPF: "É necessário informar um CPF para consultar.",  
    addAdditionalWithdrawal: 'Adicionar Saque Complementar',
    additionalWithdrawal: 'Saque Complementar',
    additionalWithdrawalValue: 'Valor do saque complementar',
    valueLimitCard: 'Limite do Cartão',
    available: 'Disponível',
    valueAvailableCard: 'Saque Disponível',
    cardLimitValue: 'Limite do Cartão',
    editAdditionalWithdrawal: 'Editar Saque Complementar',
    deleteAdditionalWithdrawal: 'Excluir Saque Complementar',
    consultWithdrawal: 'Consultar Saque',
  
    dynamic: 'Dinâmico',
    dynamicSimulation: 'Simulação dinâmica',
    olosDispositionTitle: 'Tabulação',
    olosDispositionLabel: 'Selecione a tabulação',
    olosDispositionReasonLabel: 'Selecione a pausa',
    olosDispositionScheduleDate: 'Data de Agendamento',
    olosDispositionScheduleHour: 'hora do Agendamento',
    olosDispositionSchedulePhone: 'Telefone',
    olosDispositionScheduleSpecificAgent: 'Agente Privado',
    responseDate: 'Hora Retorno',
  
    ignoreCustomerPortfolio: 'Ignorar Carteira',
    fatbanLeadConfiguration: 'Configurações Fatban - Lead',
    viewActiveCampaigns: 'Exibir Campanhas Ativas',
    loanFieldsRequiredConfiguration: 'Configuração de campos obrigatórios',
    attachDocument: 'Anexar Documento',
    fixed: 'Fixo',
    drivingLicense: 'Categoria se for CNH',
    historyEventsTypeRefin: 'Histórico/Eventos REFIN',
    benefitCode: 'Código do Benefício',
    documentType: 'Tipo de Documento',
  
    benefitCard: 'Cartão benefício',
    benefitCardValue: 'Valor do Cartão benefício',
    addBenefitCard: 'Adicionar cartão benefício',
    erro: 'Erro',
    anErrorOccurredInTheApplication: 'Ocorreu um erro na aplicação.',
    returnToHomePage: 'Voltar à página inicial',
    fillInTheInformationBelow: ' Informe o CPF para consultar o saldo FGTS',
    enterCPF: 'Digite o CPF',
    enterPhone: 'Digite o Telefone',

    submitRegistration: 'Enviar Cadastro',
    consult: 'Consultar',

    numberPhone: 'Número de telefone',
    fees: 'Juros',
    Rgcnh: 'RG/CNH',
    proposts: 'Propostas',
    registrationDate: 'Data de Cadastro',
    eventDate: 'Data da última alteração',
    enterYourCPFToSearch: 'Digite o cpf para pesquisar',
    enterYourCPF: 'Digite o cpf',
    SelectStatus: 'Selecione o status',
    problemDescription: " Descrição do Problema",
    clientActions: "Ação do Cliente",
    consultValues: "Consulte os valores",
    valueToBeConsulted: "Valor a ser Consultado",
    valueToBeSimulated: "Valor a ser simulado",
    registerCad: "cadastrar",
    inicialDate: "Data Inicial",
    fianalDate: "Data Final",
    KeyPixType: "Tipo de Chave Pix",
    KeyPix: "Chave Pix",
    availableValueforSimulation: "Valor Disponivel para Simulação",
    DCDU: "DC/DU",
    totalValueFinanced: "Valor Financiado",
    balanceAvailable: "Saldo Disponivel",
    menu: "Menu",
    consultFgts: 'Consultar FGTS',
    sideDishes: "Acompanhamento",
    FinantoPay: "Finanto Pay",
    AmountToBeReceived: "Valor a ser Recebido",
    TotalSimulatedValue: "Total Simulado",
    valueReceived: "Valor a Receber",
    valueReceiveded: "Valor Recebido",
    birthdayLoot: 'Saque Aniversário',
    confirmation: "Confirmação",
    youRightWantTheDelete: "Você tem certeza que deseja excluir essa proposta?",
    youRightWantTheArchiveThis: "Você tem certeza que deseja arquivar essa consulta?",
    selectDate :"Reapresentação",
    personInformations: "Informações Pessoais"
  };
  
}