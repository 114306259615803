import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SimulationModuleService } from 'src/app/services/simulation-module.service';
import { AppSetting } from 'src/app/entities/utils/appsettings';
import { Location } from '@angular/common'
import { ProposalsList } from 'src/app/services/proposalsList.service';
import { KanbanService } from '../kanban/kanban.service';

@Component({
  selector: 'app-archive-component',
  templateUrl: './archive-component.component.html',
  styleUrls: ['./archive-component.component.css']
})
export class ArchiveComponentComponent {
  public appSetting = new AppSetting()

  constructor(
    public simulation_service: SimulationModuleService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ArchiveComponentComponent>,
    private location: Location,
    private proposalsList: ProposalsList,
    public kanbanService: KanbanService,
    @Inject(MAT_DIALOG_DATA) public data: any,

    ) {}
    
  userId: string | null = localStorage.getItem("userId")

  buttonArchived: boolean = true

  handleCancelProposal() {
    this.buttonArchived = false
    this.simulation_service.ArchivedAvailableBalance(this.data.key, this.userId).subscribe( (scc:any) => {
      this.buttonArchived = true
      console.log(scc)
      this.reloadProposals()
    },err => {
      this.buttonArchived = true
      console.log(err)
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  async reloadProposals() {
    await this.proposalsList.reloadProposals(this.userId, this.data.typecode)
    this.proposalsList.listProposalsOriginal = await this.simulation_service.FindAvaibleBalance(this.userId, this.data.typecode).toPromise();
    this.kanbanService.filterProposalForStatus();
    this.dialogRef.close()
  }
}
